import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Alert, Stack } from '@mui/material';

const isAuth = () => {
  var chapaint = JSON.parse(localStorage.getItem('setor'));
  var chapadto = chapaint.setor;
  if (localStorage.getItem('jwttoken') !== null && chapadto === 'TI') {
    return true;
  } else {
    return false;
  }
};

const PrivateRouteMaster = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/usuarionaotempermissao',
              state: {
                error: 'Usuario Não pode ser Logado !',
              },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRouteMaster;