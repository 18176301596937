import React, { useState, useEffect } from 'react';

import Footer from '../../../common/template/footer';
import Header from '../../../common/template/header';
import SideBar from '../../../common/template/sideBar';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Spinner from '../../../common/template/Spinner';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import axiosInstance from '../../../config/api';
import './certificado.css';
import { CircularProgress, Dialog, LinearProgress } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

function LinearIndeterminate() {
    return (
        <Box sx={{ width: '100%' }}>
            <h1>Loading...</h1>
            <LinearProgress />
        </Box>
    );
}

export default function Certificado(props) {
    const [certificado, setCertificado] = React.useState([]);
    const [alertstate, setAlertstate] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [alertConcluido, setAlertConcluido] = useState([]);
    const [loading, setloading] = useState(false);
    const [completed, setcompleted] = useState(false);
    const [certificadostatus, setcertificadostatus] = useState(false);

    async function alterCertificado(id) {
        window.location.href = '/certificadoedit/edit-certificado/' + id;
    }

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setTimeout(() => {
            setloading(true);

            setTimeout(() => {
                setcompleted(true);
            }, 200);
        });
        async function listaCertificado() {
            const response = await axiosInstance.get("/api/v1/empresa")
                .then(resp => {
                    if (resp.data.length === 0) {
                        setcertificadostatus(true);
                    } else {
                        setCertificado(resp.data);

                    }


                })
                .catch(error => {
                    setcertificadostatus(true);
                })

        }
        listaCertificado();

    }, []);

    async function novocadastro() {
        window.location.href = '/certificadoedit';
    }
    function voltar() {
        window.location.href = '/home';
    }
    const rootRef = React.useRef < HTMLDivElement > (null);
    const deletarCertificado = async (id) => {
        const response = await axiosInstance.delete('/api/v1/empresa/' + id)
            .then(resp => {
                handleClose();
                setTimeout(() => { setAlertConcluido(false); }, 1000);
                setTimeout(() => { setAlertConcluido(true); }, 7500);
                setTimeout(() => { window.location.href = '/certificado'; }, 2000);

            })
            .catch(error => {
                handleClose();
                setTimeout(() => { setAlertstate(false); }, 2000);
                setTimeout(() => { setAlertstate(true); }, 5000);
            });

    }
    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <div className="card-header">

                    <section className="content-header">
                        <h1><i className="fa fa-certificate fa-1x cor-padrao"></i> Certificado
                            <small></small>
                        </h1>
                        <ol className="breadcrumb">
                            <li><i className="fa fa-home"></i> Portal JCR</li>
                            <li><i className="fa fa-wrench"></i> Configuração</li>
                            <li className="active"><i className="fa fa-certificate"></i> Certificado</li>
                        </ol>
                    </section>



                </div>

                <div>

                    <br></br>
                    <div className='content'>
                        <div className="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger "><div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" className="ui-panel-content ui-widget-content">
                            <div className="box-header with-border ">
                                <div className="form-horizontal">
                                    {!alertstate &&
                                        <Stack
                                            sx={{ width: '100%' }} spacing={2}>
                                            <Alert severity="error">Não é possível deletar um Certificado !</Alert>
                                        </Stack>
                                    }


                                    {!alertConcluido &&
                                        <Stack
                                            sx={{ width: '100%' }} spacing={2}>
                                            <Alert severity="success">Cadastro Deletado com Sucesso !</Alert>
                                        </Stack>
                                    }
                                    <br></br><br></br>
                                    <div class="col-sm-2 col-md-6">
                                        <button type="button" class="btn btn-block btn-danger btn-lg"
                                            onClick={() => voltar()}>
                                            <i class="fa fa-chevron-left"></i> VOLTAR</button>
                                    </div>
                                    <div class="col-sm-2 col-md-6">
                                        <button type="button" class="btn btn-block btn-success btn-lg"
                                            onClick={() => novocadastro()}>
                                            <i class="fa fa-plus"></i> NOVO</button>

                                    </div>

                                    <br></br><br></br><br></br>

                                    <div class="card-body ">
                                        {certificadostatus === true ?
                                            <div class="card-body ">
                                                <div className="box-header with-border ui-panel ui-widget ui-widget-content ui-corner-all box box-danger ">
                                                    <h4 style={{ paddingLeft: '35%' }} >Nenhum Certificado Localizado!</h4>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <div className="box-header with-border ui-panel ui-widget ui-widget-content ui-corner-all box box-danger ">
                                                    <Table className="table table-striped table-bordered table-resizable" data-toggle="tooltip" stickyHeader aria-label="sticky table">


                                                        <TableHead >


                                                            <TableRow >
                                                                <TableCell sx={{ fontWeight: 'bold' }}>ID</TableCell>
                                                                <TableCell sx={{ fontWeight: 'bold' }}>Cnpj</TableCell>
                                                                <TableCell sx={{ fontWeight: 'bold' }}>Razao Social</TableCell>
                                                                <TableCell sx={{ fontWeight: 'bold' }}>UF</TableCell>
                                                                <TableCell sx={{ fontWeight: 'bold' }}>Ambiente da Coleta</TableCell>
                                                                <TableCell sx={{ fontWeight: 'bold' }}>Certificado</TableCell>
                                                                <TableCell sx={{ fontWeight: 'bold' }}>Nsu</TableCell>
                                                                <TableCell sx={{ fontWeight: 'bold' }}>Editar</TableCell>
                                                                <TableCell sx={{ fontWeight: 'bold' }}>Excluir</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {certificado.map((row) => (
                                                                <TableRow
                                                                    key={row.numero}
                                                                    sx={{ border: 100, width: 100 }}
                                                                >
                                                                    <TableCell>{row.id}</TableCell>
                                                                    <TableCell>{row.cpfcnpj}</TableCell>
                                                                    <TableCell>{row.razaosocial}</TableCell>
                                                                    <TableCell>{row.uf}</TableCell>
                                                                    <TableCell>{row.ambienteenum}</TableCell>
                                                                    <TableCell>{row.certificado}</TableCell>
                                                                    <TableCell>{row.nsu}</TableCell>
                                                                    <TableCell component="th" scope="row">
                                                                        <a>
                                                                            <img src="https://www.freeiconspng.com/uploads/edit-icon-orange-pencil-0.png"
                                                                                width="20" height="20" onClick={() => alterCertificado(row.id)}>
                                                                            </img>
                                                                        </a></TableCell>
                                                                    <TableCell component="th" scope="row">
                                                                        <a>
                                                                            <img src="https://cdn-icons-png.flaticon.com/512/216/216658.png"
                                                                                onClick={() => deletarCertificado(row.id)}
                                                                                width="20" height="20">
                                                                            </img>
                                                                        </a></TableCell>

                                                                </TableRow>
                                                            ))}

                                                        </TableBody>


                                                    </Table>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>


    )
}