
import React from 'react';
import Footer from '../../common/template/footer'
import Header from '../../common/template/header'
import SideBar from '../../common/template/sideBar'


export default function Permissao() {

   
    return (
        <div className="wrapper">
            <Header />
            <SideBar />

            <div className='content-wrapper'>
                <div className="card-header">

                    <section className="content-header">
                        <h1><i className="fa fa-plus-square fa-1x cor-padrao"></i> Falha na Permissão
                            <small></small>
                        </h1>
                       
                    </section>



                </div>
                <div className='content'>
                    <div className="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger "><div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" className="ui-panel-content ui-widget-content">
                        <div className="box-header with-border ">
                            <div className="form-horizontal">




                                <div className='card-body'>
                                    <div className='alert alert-danger alert-dismissible'>
                                        <i className="fa fas fa-ban fa-lg"><p style={{ marginLeft: '2rem'}}>Seu usuário não Possui Permissão para esse Modulo</p> </i> 

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    </div>
                    <Footer />
                </div>
            </div>
         </div>
    )

}