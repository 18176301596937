import React, { useState } from 'react';
import MenuItem from './menuItem';
import './css/menuTree.css';

export default function MenuTree(props) {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
    if (typeof props.onClick === 'function') {
      props.onClick();
    }
  };

  const shouldShowLabel = () => {
    if (props.label === 'Conf Chamado' || props.label === 'Configuração') {
      return true;
    }
    // Substitua a lógica abaixo pela condição específica que você deseja
    const isSpecificMode =
      document.body.classList.contains('skin-blue') &&
      document.body.classList.contains('fixed') &&
      document.body.classList.contains('sidebar-mini') &&
      document.body.classList.contains('sidebar-collapse');

    return !isSpecificMode;
  };

  const shouldShowArrow = !props.hideArrow;

  const menuStyle = {
    marginLeft: '2px',
    ...props.styles
  };

  return (
    <li className={`treeview ${expanded ? 'menu-open' : ''}`} style={menuStyle}>
      <a href="#" onClick={handleToggle}>
        <i className={`fa fa-${props.icon} ${shouldShowArrow ? 'menu-tree' : ''}`}></i>
        <span>{props.label}</span>
        {shouldShowArrow && (
          <i className={`fa ${expanded ? 'fa-angle-down' : 'fa-angle-left'} pull-right`}></i>
        )}
      </a>
      <ul className="treeview-menu" style={{ display: expanded ? 'block' : 'none' }}>
        {React.Children.map(props.children, child => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { iconSize: props.iconSize });
          }
          return child;
        })}
      </ul>
    </li>
  );
}
