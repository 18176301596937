import React, { useState, useEffect } from 'react';
import './fogotpassowrd.css';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Success from '../../common/template/img/success.png'
import { Dialog, Modal, Stack } from '@mui/material';


import backgroundImage from '../../common/template/img/real-estate-1.jpg';
import logoImage from '../../common/template/img/logo-jcr.png';

const initialCountdown = (() => {
    const storedCountdown = localStorage.getItem('countdown');
    return storedCountdown ? parseInt(storedCountdown, 10) : 60 * 60; // 60 minutos em segundos
})();

export default function ForgotPassword(props) {

    const handleClose = () => {
        setconcluido(false);
    };


    const [concluido, setconcluido] = useState(false);
    const [validacao, setValidacao] = useState(() => {
        const storedValidacao = localStorage.getItem('validacao');
        return storedValidacao ? JSON.parse(storedValidacao) : false;
    });

    const [senhainvalida, setSenhaInvalida] = useState(() => {
        const storedSenhainvalida = localStorage.getItem('senhainvalida');
        return storedSenhainvalida ? JSON.parse(storedSenhainvalida) : false;
    });

    const [email, setEmail] = useState('');

    const [startTime, setStartTime] = useState(() => {
        const storedStartTime = localStorage.getItem('startTime');
        return storedStartTime ? parseInt(storedStartTime, 10) : new Date().getTime() / 1000; // Em segundos
    });

    const [countdown, setCountdown] = useState(() => {
        const storedCountdown = localStorage.getItem('countdown');
        return storedCountdown ? parseInt(storedCountdown, 10) : 60 * 60; // 60 minutos em segundos
    });


    const [token, setToken] = useState('');
    const [crudSuccess, setCrudSuccess] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = new Date().getTime() / 1000; // Em segundos
            const elapsedTime = currentTime - startTime;

            setCountdown(prevCountdown => {
                const newCountdown = Math.max(prevCountdown - elapsedTime, 0);

                if (newCountdown <= 0) {
                    setValidacao(false);
                    setCountdown(0);
                    clearInterval(interval);
                    localStorage.clear();
                } else {
                    localStorage.setItem('countdown', newCountdown);
                }

                return newCountdown;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [startTime]);

    useEffect(() => {
        localStorage.setItem('startTime', new Date().getTime() / 1000);
    }, []);

    useEffect(() => {
        localStorage.setItem('validacao', JSON.stringify(validacao));
    }, [validacao]);

    useEffect(() => {
        localStorage.setItem('senhainvalida', JSON.stringify(senhainvalida));
    }, [senhainvalida]);

    const minutes = Math.floor(countdown / 60);
    const seconds = Math.floor(countdown % 60); // Ajuste para mostrar apenas os segundos inteiros

    async function forgotsenha() {
        if (email === 'suporte@grupojcr.com.br') {
            setValidacao(true);

           

        } else {
            setValidacao(false);
            setSenhaInvalida(true);

          

            setTimeout(() => {
                setSenhaInvalida(false);
            }, 5000);
        }
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '52%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#ffffff',
        border: '2px solid #ffffff',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,


    };


    async function validarToken() {
        if (token === '200') {
            setconcluido(true);
            localStorage.clear();
        } else {
            localStorage.clear();
            window.location.href = '/forgotPassword';
            return; 
        }
    
        
        window.location.href = '/';
    }

    const displayMinutes = String(minutes).padStart(2, '0');
    const displaySeconds = String(seconds).padStart(2, '0');

    

    return (
        <div>
            {validacao === false ? (
                <div style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', textAlign: 'center', maxWidth: '80%', width: '50%' }}>
                        <div style={{ marginBottom: '20px' }}>
                            <img src={logoImage} alt="Logo" style={{ width: '100px', height: '100px' }} />
                        </div>
                        <h2>Resetar Senha</h2>
                        <Box component="form" noValidate sx={{ mt: 2 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                style={{ backgroundColor: 'black', color: 'white' }}
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={forgotsenha}
                            >
                                PRÓXIMO
                            </Button>
                            {senhainvalida && <div style={{ color: 'red' }}>Sua senha é inválida.</div>}
                        </Box>
                    </div>
                </div>
            ) : (
                <div>
                    <div style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', textAlign: 'center', maxWidth: '80%', width: '50%' }}>
                            <div style={{ marginBottom: '20px' }}>
                                <img src={logoImage} alt="Logo" style={{ width: '100px', height: '100px' }} />
                            </div>
                            <h2>Resetar Senha</h2>
                            <Box component="form" noValidate sx={{ mt: 2 }}>
                                <div className="timer">
                                    <span>{`${displayMinutes === '60' ? '01:00' : `00:${displayMinutes}`}`}</span>
                                </div>

                                <div>

                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="token"
                                        label="Token de Acesso"
                                        name="token"
                                        autoComplete="Token"
                                        autoFocus
                                        onChange={(e) => setToken(e.target.value)}
                                    />

                                    <Button
                                        type="submit"
                                        fullWidth
                                        style={{ color: 'white' }}
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                        onClick={validarToken}
                                    >
                                        VALIDAR TOKEN
                                    </Button>
                                </div>
                            </Box>

                            {concluido &&
                                <div>
                                    <Modal
                                        open={concluido}
                                        onClose={handleClose}
                                        aria-labelledby="parent-modal-title"
                                        aria-describedby="parent-modal-description"
                                    >
                                        <Box sx={{ ...style, width: 500 }}>
                                            <h2 ><img
                                                src={Success}
                                                width="30"
                                                height="30" >
                                            </img> Senha Alterada com Sucesso </h2>
                                        </Box>
                                    </Modal>

                                </div>

                            }

                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}