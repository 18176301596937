import { Dialog, Modal, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Footer from '../../common/template/footer'
import Header from '../../common/template/header'
import SideBar from '../../common/template/sideBar'
import axiosInstance from '../../config/api';
import CloseIcon from '../../common/template/img/closebutton.png'

import PdfIMG from '../../common/template/img/pdf.png';
import { Box } from '@mui/system';
import Spinner from '../../common/template/Spinner';

export default function Holerite() {
    var coligadaint = JSON.parse(localStorage.getItem('coligada'));
    const [completed, setcompleted] = useState(false);
    var coligadadto = coligadaint.coligada;
    var chapaint = JSON.parse(localStorage.getItem('chapa'));
    var chapadto = chapaint.coligada;
    const [HoleriteState, setHoleriteState] = useState([]);
    const [holeritemodal, setholeritemodal] = React.useState(false);
    const [loading, setloading] = useState(false);
    const handleOpenHolerite = () => {
        setholeritemodal(true);
    };
    const handleCloseHolerite = () => {
        setholeritemodal(false);
    };
    const stylealert = {
        position: 'absolute',
        top: '50%',
        left: '52%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#ffffff',
        border: '2px solid #ffffff',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,


        h2: {
            fontWeight: "bold",
        },
        h3: {
            fontWeight: "bold",
            marginLeft: '%'
        }
    };
    useEffect(() => {

        async function loadingHolerite() {
            setloading(true);
            const response = await axiosInstance.get(`RM/obterlistaholerite/${chapadto}/${coligadadto}`)
                .then(resp => {
                    if (resp.data.length === 0) {
                        setHoleriteState(true);

                    } else {
                        setHoleriteState(resp.data);
                        setloading(false);
                    }
                }).catch(error => {
                })
        }
        loadingHolerite();
    }, []);
    async function gerarPdf(coligada, chapa, ano, mes, periodo) {
        setloading(true);
        const response = await axiosInstance.get(`RM/downloadxml/` + coligada + `/` + chapa + `/` + ano + `/` + mes + `/` + periodo, { responseType: 'blob' })
            .then(resp => {
                if (resp && resp.data) {
                    const file = new Blob([resp.data], { type: 'application/pdf;charset=UTF-8', });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    setloading(false);
                }
            }).catch(error => {
                setholeritemodal(true);
                setloading(false);
            })
    }
    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <div className="card-header">
                    <section className="content-header">
                        <h1><i className="fa fa-plus-square fa-1x cor-padrao"></i> Holerite
                            <small></small>
                        </h1>
                        <ol className="breadcrumb">
                            <li><i className="fa fa-home"></i> Portal JCR</li>
                            <li><i className="fa fa-wrench"></i> Recursos Humanos</li>
                            <li className="active"><i className="fa fa-ticket"></i> Holerite</li>
                        </ol>
                    </section>
                </div>
                <div className='content'>
                    <div className="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger "><div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" className="ui-panel-content ui-widget-content">
                        <div className="box-header with-border ">
                            <div className="form-horizontal">



                                {HoleriteState === true ?
                                    <div className='card-body'>
                                        <div className='alert alert-danger alert-dismissible'>
                                            <i className="fa fas fa-ban fa-lg"> Seu usuário não Possui Holerites.</i>

                                        </div>
                                    </div>
                                    :
                                    <div>



                                        <Table className="table " stickyHeader aria-label="sticky table">

                                            <TableHead >


                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: 'bold' }} align="center">Ano</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold' }} align="center">Mês</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold' }} align="center">Tipo</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold' }} align="center">Visualizar</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <>
                                                    {loading ? (
                                                        <Dialog open={true} aria-describedby="alert-dialog-slide-description">
                                                            <div className="pos-center">
                                                                <Spinner />
                                                            </div>
                                                        </Dialog>
                                                    ) : (
                                                        <Dialog open={false} aria-describedby="alert-dialog-slide-description">
                                                            <div className="pos-center">
                                                                
                                                            </div>
                                                        </Dialog>
                                                    )}
                                                </>
                                                {HoleriteState.map((row) => (
                                                    <TableRow
                                                        key={row.periodo}
                                                        sx={{ border: 100, width: 10 }}
                                                    >
                                                        <TableCell align="center">{row.ano}</TableCell>
                                                        <TableCell align="center">{row.mes}</TableCell>
                                                        <TableCell align="center">{row.periodo}</TableCell>
                                                        <TableCell align="center"><img src={PdfIMG}
                                                            onClick={() => {
                                                                setcompleted(false);
                                                                gerarPdf(coligadadto, chapadto, row.ano, row.mescod, row.periodocod);
                                                            }}
                                                            width="30" height="30" style={{ marginLeft: '1%', cursor: 'pointer' }}
                                                        >
                                                        </img></TableCell>
                                                    </TableRow>

                                                ))}


                                            </TableBody>
                                        </Table>
                                    </div>
                                }

                            </div>
                        </div>
                        <Modal
                            open={holeritemodal}
                            onClose={handleCloseHolerite}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                        >
                            <Box sx={{ ...stylealert, width: 460 }}>
                                <img
                                    style={{ maxWidth: '100%', marginLeft: '100%', cursor: 'pointer' }}
                                    src={CloseIcon}
                                    onClick={handleCloseHolerite}
                                    width="30" height="30" >
                                </img>
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgZMQcf2G2xvLEiPcBsebw91AWmnntEZlZ6g&usqp=CAU"
                                    width="90" height="80"
                                    style={{ marginLeft: '40%' }}
                                />
                                <h2 id="parent-modal-title" className="font">Erro (500)</h2>
                                <h3>"Validar Ficha Financeira no RM"</h3>


                            </Box>
                        </Modal>
                    </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )

}