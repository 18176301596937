import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import MenuItem from './menuItem';
import MenuTree from './menuTree';

import './css/menuTree.css';
import { Divider } from '@material-ui/core';

export default function Menu(props) {

    /** <Divider style={{ backgroundColor: 'white' }} />
                            <MenuTree onClick={handleHeaderToggle} icon="bars fa-lg" hideArrow></MenuTree>
    
                            <Divider style={{ backgroundColor: 'white' }} /> */

    var newInt = JSON.parse(localStorage.getItem('idsetor'));
    var setor = newInt.idsetor;

    const [headerOpen, setHeaderOpen] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 768 });

    const handleHeaderToggle = () => {
        setHeaderOpen(!headerOpen);
    };

    useEffect(() => {
        const body = document.body;
        if (isMobile) {
            if (headerOpen) {
                body.classList.add('sidebar-open');
                body.classList.remove('sidebar-collapse');
            } else {
                body.classList.remove('sidebar-open');
                body.classList.add('sidebar-collapse');
            }
        } else {
            if (headerOpen) {
                body.classList.remove('sidebar-collapse');
            } else {
                body.classList.add('sidebar-collapse');
            }
        }
    }, [headerOpen, isMobile]);

    return (
        <div className={`main-header ${isMobile ? '' : headerOpen ? '' : 'skin-blue fixed sidebar-mini sidebar-open'}`}>
            <aside className="main-sidebar">
                <section className="sidebar">
                    <ul className='sidebar-menu tree '>


                        <br />
                        <MenuItem path='/home' label=' Home' icon='home fa-lg' />
                        <MenuItem path='/meuperfil' label=' Meu Perfil' icon='user fa-lg' />

                        <br />
                        <MenuTree label='Documento de Entrada' icon='files-o fa-lg'>
                            <MenuItem path='/documentoentradanfse' label='NFS-e' icon='file' />
                            <MenuItem path='/documentoentradanfe' label=' NF-e' icon='file-archive-o' />
                        </MenuTree>
                        <MenuTree label=' Recursos Humanos' icon='users fa-lg'>
                            <MenuItem path='/holeritepagamento' label=' Holerite' icon='envelope' />
                        </MenuTree>
                        <MenuTree label=' Sistema de Chamado' icon='wrench fa-lg'>
                            <MenuItem path='/paineldechamados' label=' Painel de Chamados' icon='reorder' />
                            <MenuItem path='/novochamado' label=' Novo Chamado' icon='ticket' />
                            <MenuItem path='/meuschamados' label=' Meus Chamados' icon='newspaper-o' />
                            <MenuItem path='/relatoriochamados' label=' Relatório de Chamados' icon='list-ul' />
                            <MenuTree label='Configuração' icon='gear'>
                                <MenuItem path='/categoriachamadodswid=1912311' label=' Categoria Chamado' icon='list-alt' />
                                <MenuItem path='/slachamadodswid=9747' label=' (SLA) Chamado' icon='clock-o' />
                            </MenuTree>
                        </MenuTree>
                        <MenuTree label=' Power Bi' icon='pie-chart fa-lg'>
                            <MenuItem path='/relatorios' label=' Orçamento' icon='line-chart' />
                        </MenuTree>
                        <MenuTree label=' Historico Road Map' icon='sitemap fa-lg'>
                            <MenuItem path='/roadmapti' label=' Road Map TI 2022' icon='sitemap' />
                            <MenuItem path='/roadmapti2023' label=' Road Map TI 2023' icon='sitemap' />
                        </MenuTree>

                        {setor === 1 && (
                            <MenuTree label="Gestão de Projeto" icon="area-chart">
                                <MenuItem path='/gerencimanetoprojeto' label='Painel de Gerenciamento' icon='reorder' />
                                <MenuItem path='/novoprojeto' label='Novo Projeto' icon='plus' />
                                <MenuItem path='/relatorioprojeto' label='Relatorio Projeto' icon='list-ul' />
                            </MenuTree>
                        )}
                        <MenuTree label="Publicação" icon="newspaper-o">
                            <MenuItem path='/novapublicacao' label='Nova Publicação' icon='plus' />
                        </MenuTree>
                        {setor === 1 && (
                            <MenuTree label=' Configurações' icon='gears fa-lg'>
                                <MenuItem path='/cadastrouser' label=' Usuário' icon='user' />
                                <MenuItem path='/certificado' color='red' label=' Certificado' icon='certificate' />
                            </MenuTree>
                        )}
                        <br></br>
                        <MenuItem path='/' label=' Sair' icon='sign-out fa-lg' />
                    </ul>
                </section>
            </aside>
        </div>
    );
}
