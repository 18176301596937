import { Divider, Modal, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Footer from '../../../../common/template/footer';
import Header from '../../../../common/template/header';
import SideBar from '../../../../common/template/sideBar';
import axiosInstance from '../../../../config/api';

import AlterarIMG from '../../../../common/template/img/lapis.png';
import LixeiraIcon from '../../../../common/template/img/lixeira.png';
import Box from '@mui/material/Box';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};




export default function CategoriaChamado() {

    var newInt = JSON.parse(localStorage.getItem('idsetor'));
    var setor = newInt.idsetor; 
    const [categoriachamado, setcategoriachamado] = useState([]);
    const [categoriachamadodados, setcategoriachamadodados] = useState('');
    const [completed, setcompleted] = useState(false);

    const [open, setOpen] = React.useState(false);


    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };



    useEffect(() => {
        setTimeout(() => {


            setTimeout(() => {
                setcompleted(true);
            }, 200);
        });
        async function loadCategoriachamado(id) {

            const response = await axiosInstance.get(`/categoriachamado/${setor}`);
            setcategoriachamado(response.data);

          

        }

        loadCategoriachamado();
    }, []);
    async function alterarsubcategoriachamado(id) {
        window.location.href = '/categoriachamadodswid=1912311/' + id;
    }

    const deletarCategoria = async (id) => {
        const response = await axiosInstance.delete('/categoriachamado/' + id)
            .then(resp => {
                window.location.href = '/categoriachamadodswid=1912311/';
                setOpen(false);
            })
            .catch(error => {

                setOpen(true);
            })

    }

    async function novocadastro() {
        window.location.href = '/categoriachamadodswid/novacategoria';
    }

    async function voltar() {
        window.location.href = '/home';
    }

    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <div>
                    <div class="card-header">

                        <section class="content-header">
                            <h1><i class="fa fa-list-alt fa-1x cor-padrao"></i> Categoria Chamado
                                <small></small>
                            </h1>
                            <ol class="breadcrumb">
                                <li><i class="fa fa-home"></i> Portal JCR</li>
                                <li><i class="fa fa-wrench"></i> Sistema de Chamado</li>
                                <li class="active"><i class="fa fa-list-alt"></i> Categoria Chamado</li>
                            </ol>
                        </section>



                    </div>
                    <div className='content'>
                        <div class="card">
                        </div>
                        <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger"><div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" class="ui-panel-content ui-widget-content">
                            <div class="box-header with-border">
                                <div class="form-horizontal">
                                    <div className='content'>


                                        <div class="box-body">
                                            <h4>Categoria Chamado</h4>
                                            <Divider />
                                            <br></br>
                                            <div class="col-sm-2 col-md-6">
                                                <button type="button" class="btn btn-block btn-danger btn-lg" onClick={() => voltar()}
                                                >
                                                    <i class="fa fa-chevron-left"></i> VOLTAR</button>
                                            </div>
                                            <div class="col-sm-2 col-md-6">
                                                <button type="button" class="btn btn-block btn-success btn-lg"
                                                    onClick={() => novocadastro()}
                                                >
                                                    <i class="fa fa-plus" >
                                                    </i> NOVO</button>
                                                <br></br>
                                            </div>
                                            <div class="card-body ">

                                                <Table className="table table-striped table-bordered table-resizable" data-toggle="tooltip" stickyHeader aria-label="sticky table">

                                                    <TableHead >


                                                        <TableRow>

                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">Identificação</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">Categoria Chamado</TableCell>

                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">Alterar</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">Excluir</TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {categoriachamado.map((row) => (
                                                            <TableRow
                                                                key={row.numero}
                                                                sx={{ border: 100, width: 100 }}
                                                            >
                                                                <TableCell align="center">{row.id}</TableCell>
                                                                <TableCell align="center">{row.nomecategoria}</TableCell>

                                                                <TableCell align="center">
                                                                    <img src={AlterarIMG}

                                                                        width="20" height="20" style={{ marginLeft: '1%' }}
                                                                        onClick={() => alterarsubcategoriachamado(row.id)}>
                                                                    </img></TableCell>
                                                                <TableCell align="center">
                                                                    <img src={LixeiraIcon}
                                                                        onClick={() => deletarCategoria(row.id)}
                                                                        width="20" height="20" style={{ marginLeft: '1%' }}>
                                                                    </img></TableCell>
                                                            </TableRow>

                                                        ))}
                                                    </TableBody>
                                                </Table>
                                                <Modal
                                                    open={open}
                                                    onClose={handleClose}
                                                    aria-labelledby="parent-modal-title"
                                                    aria-describedby="parent-modal-description"
                                                >
                                                    <Box sx={{ ...style, width: 400 }}>
                                                        <h2 id="parent-modal-title">Erro (500)</h2>
                                                        <p id="parent-modal-description">
                                                            <h3>Não é Possivel deletar uma Categoria pois possui vinculo com uma subcategoria</h3>
                                                            <p>Exclua as subcategoria relacionadas a Categoria !</p>
                                                            <button type="button" class="btn btn-block btn-danger btn-lg" onClick={handleClose}>Voltar</button>

                                                        </p>

                                                    </Box>
                                                </Modal>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}