import React, { useState, useEffect } from 'react';
import ContentHeader from '../../common/template/contentHeader';
import Header from '../../common/template/header';
import SideBar from '../../common/template/sideBar';
import Footer from '../../common/template/footer';

import './home.css';
import axiosInstance from '../../config/api';
import { height } from '@mui/system';

export default function Home(props) {
    const usuario = localStorage.getItem('nomecomplemento');
    const usuarioLogado = JSON.parse(usuario);

    const [dados, setDados] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get("/publicacaopost/listartodos");
                const decodedData = response.data.content.map(item => ({
                    ...item,
                    decodedAnexo: atob(item.conteudoAnexo)
                }));
                setDados(decodedData);
            } catch (error) {
                console.error("Erro ao buscar dados da API:", error);
            }
        };

        fetchData();
    }, []);
    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper' style={{ paddingLeft: '10px' }}>
                <div className="box-body">
                    <ContentHeader title={`Portal Cloud Seja bem-vindo ` + usuarioLogado.nomecomplemento} style={{ marginBottom: '20px' }} />

                    <div className='centralizarx'>
                        {dados.length === 0 ? (
                            <div className='box' style={{ height: '50vh' }}>
                                <div className='row' style={{ marginTop: '50px', marginLeft: '40%' }}>
                                    <p style={{ fontFamily: 'bold' }}> Nenhuma Postagem Registrada!</p>
                                </div>
                            </div>


                        ) : (
                            <div>
                                <div className='ui-panel ui-widget ui-widget-content ui-corner-all box box-danger'>
                                    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                                        {dados.map((item, index) => (
                                            <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                                <img className="d-block w-100 custom-image" src={`data:image/png;base64,${item.conteudoAnexo}`} alt={`Slide ${index + 1}`} />
                                            </div>
                                        ))}
                                    </div>

                                    <div className="carousel-controls-container">
                                        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                            <span className="carousel-control-prev-icon cor1" aria-hidden="true"></span>
                                            <span className="sr-only">Anterior</span>
                                        </a>
                                        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                            <span className="carousel-control-next-icon cor1" aria-hidden="true"></span>
                                            <span className="sr-only">Próximo</span>
                                        </a>
                                    </div>


                                </div>

                            </div>
                        )}
                    </div>

                </div>
            </div>
            <Footer />
        </div >
    );
}
