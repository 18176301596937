
const FILE_TYPES = {
    PDF: 'application/pdf;charset=UTF-8',
    XML: 'text/xml',
    PLAIN_TEXT: 'text/plain',
    ZIP: 'application/zip;charset=UTF-8',
    JPEG: 'image/jpeg',
    PNG: 'image/png',
    MP4: 'video/mp4',
    AVI: 'video/x-msvideo',
    MKV: 'video/x-matroska',
    WMV: 'video/x-ms-wmv',
    DOC: 'application/msword',
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    CSV: 'text/csv',
    XLS: 'application/vnd.ms-excel',
    TXT: 'text/plain',
    DWG: 'application/acad',
    ICO: 'image/vnd.microsoft.icon',
    PPT: 'application/vnd.ms-powerpoint',
    PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
};

export default FILE_TYPES ;
