import React, { useEffect, useCallback, useMemo, useRef, useState } from 'react';

//import $ from 'jquery';
import Footer from '../../common/template/footer';
import Header from '../../common/template/header';
import SideBar from '../../common/template/sideBar';
import './nfselayoutscroll.css';
import "./teste.scss";
import axiosInstance from '../../config/api';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/styles';
import { Autocomplete, Dialog, Divider, Select, Stack, TablePagination, TextField } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Spinner from '../../common/template/Spinner';


const useStyles = makeStyles((theme) => ({
    tamanhograndebody: {

        display: 'block',
        TextOverFlow: 'ellipsis',
    },
    fonte: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        paddingLeft: '1%',
        color: '#545151',
        fontSize: '15px',
    },
    loader: {
        border: '16px solid #f3f3f3',
        bordertop: '16px solid #3498db',
        borderRadius: '50%',
        width: '130px',
        height: '130px',
        animation: 'spin 2s linear infinite'
    },

}));

export default function NfseDocumentoEntrada(props) {

    const [DisabledNextButton, setDisabledNextButton] = React.useState(false);
    const [buscar, setBuscar] = React.useState('');
    const [nfse, setNfse] = React.useState([]);
    const [pagenumber, setpageNumber] = React.useState('');
    const [QuantidadeNfseCuritiba, setQuantidadeNfseCuritba] = React.useState([]);
    const [QuantidadeNfseBarueri, setQuantidadeNfseBarueri] = React.useState([]);
    const [QuantidadeNfsePontal, setQauntidadeNfsePontal] = React.useState([]);
    const [ComboColigadasFornecedor, setComboColigadasFornecedor] = React.useState([]);
    const [ComboFornecedores, setComboFornecedores] = React.useState([]);
    const [Fornecedores, setFornecedores] = React.useState([]);
    const [codigoverificacao, setCodigoVerificao] = React.useState([]);
    const [Coligada, setColigada] = React.useState([]);
    const [DataInicial, setDataInicial] = React.useState('');
    const [DataFinal, setDataFinal] = React.useState('');
    const [ComboListaColeta, setComboListaColeta] = React.useState([]);
    const classes = useStyles();
    const [totalPaginas, setTotalpages] = React.useState('');
    const [SituacaoNfe, setSituacaoNfe] = React.useState(false);

    const [quantidadenfecount, setquantidadenfecount] = React.useState([]);
    const [pageNumberT, setpageNumberT] = React.useState([]);
    const [pageSize, setpageSize] = React.useState([]);

    const [totalElements, setTotalElements] = useState(0);
    const [loading, setloading] = useState(false);
    const [completed, setcompleted] = useState(false);

    const handleChangePage = (event, newPage) => {
        setpageNumber(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setpageNumber(pagenumber++);
    };

    const locale = 'pt-br'

    async function loadingId(codigoverificacao) {
        window.location.href = "/nfse/" + codigoverificacao;
    }

    useEffect(() => {
        setTimeout(() => {
            setloading(true);

            setTimeout(() => {
                setcompleted(true);
            }, 300);
        });
        async function loadNfse() {
            try {
                if (buscar !== null) {
                    const response = await axiosInstance.get(`/consultanfse/paginado?page=${pagenumber}`);

                    console.log(response.data)
                    setNfse(response.data.content);
                    setpageNumber(response.data.number)
                    setTotalpages(response.data.totalPages);
                    setTotalElements(response.data.totalElements);
                    setpageNumberT(response.data.number);
                    setpageSize(response.data.pageable.pageSize);

                    
                    if(response.data.totalElements === 0 ){
                        setSituacaoNfe(true);
                    }else{
                        setSituacaoNfe(false);
                    }


                    if (response.data.pageable.offset === 0) {
                        setquantidadenfecount(response.data.pageable.pageSize);
                    } else {
                        setquantidadenfecount(response.data.pageable.offset);
                    }

                }
            } catch (error) {
                if (error.response && error.response.status === 500) {
                    alert('500')
                }

            }
        }
        loadNfse();
        async function totalNfseCuritiba() {
            const response = await axiosInstance.get("/consultanfse/totalnotasCuritiba")
                .then(resp => {
                    setQuantidadeNfseCuritba(resp.data);
                })
                .catch(error => {

                    setTimeout(() => {
                        setloading(true);
                        window.location.href = '/';
                    }, 3000);
                })


        }
        totalNfseCuritiba();
        async function totalNfseBarueri() {
            const response = await axiosInstance.get('/consultanfse/totalnotasBarueri');
            setQuantidadeNfseBarueri(response.data);
        }
        totalNfseBarueri();
        async function totalNfsePontal() {
            const response = await axiosInstance.get('/consultanfse/totalnotasPontal');
            setQauntidadeNfsePontal(response.data);
        }
        totalNfsePontal();
        async function nomefantasiacoligada() {
            const response = await axiosInstance.get('/coligadas');
            setComboColigadasFornecedor(response.data);
        }


        nomefantasiacoligada();
        async function prestadorservico() {
            const response = await axiosInstance.get('/fcfonfse/');
            setComboFornecedores(response.data);
        }
        prestadorservico();
        async function cidadecoleta() {
            const response = await axiosInstance.get('consultanfse/listacoleta');
            setComboListaColeta(response.data);
        }
        cidadecoleta();
    }, []);





    const gerarPDF = async (codigoverificacao, cidadeprestador) => {
        try {
            const response = await axiosInstance.get(`/consultanfse/gerarpdfcuritiba/${codigoverificacao}/${cidadeprestador}`, { responseType: 'blob' });

            if (response && response.data) {
                const file = new Blob([response.data], { type: 'application/pdf;charset=UTF-8' });
                const fileURL = URL.createObjectURL(file);

                window.open(fileURL);
            }
        } catch (error) {

            alert('Ocorreu um erro ao gerar o PDF. Por favor, tente novamente mais tarde.');
        }
    }
    const gerarXML = async (codigoVerificacao) => {
        const response = await axiosInstance.get(`/consultanfse/gerarxmlcuritiba/` + codigoVerificacao, { responseType: 'xml;charset=UTF-8;' });

        if (response && response.data) {


            const file = new Blob([response.data.split('&').join('&amp;')], { type: 'text/xml;charset=UTF-8;', });



            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }
    }

    async function handleBuscar() {
        const response = await axiosInstance.get(`/consultanfse/paginado?page=${pagenumber}&cnpjtomador=${Coligada}&nomefantasia=${Fornecedores}&codigoverificacao=${codigoverificacao}&datainicial=${DataInicial !== '' ? `${DataInicial}T00:00` : ''}&datafinal=${DataFinal !== '' ? `${DataFinal}T23:59` : ''}`);
        setNfse(response.data.content);
        setTotalElements(response.data.totalElements);
        setquantidadenfecount(response.data.pageable.offset);
        setpageNumber(0); // Reinicia a página para a primeira página

        console.log(response.data.content.length)
        if (response.data.content.length < 20) {
            setDisabledNextButton(true);
        } else {
            setDisabledNextButton(false);
        }
    }

    function TablePaginationActions() {
        const theme = useTheme();
        const { count, rowsPerPage } = props;

        // Adicione esta linha para calcular o número de página exibido para o usuário
        const displayPageNumber = pagenumber + 1;

        async function handleBackButtonClick() {
            try {
                const newPage = pagenumber - 1;
                const response = await axiosInstance.get(`/consultanfse/paginado?page=${newPage}&cnpjtomador=${Coligada}&nomefantasia=${Fornecedores}&codigoverificacao=${codigoverificacao}&datainicial=${DataInicial !== '' ? `${DataInicial}T00:00` : ''}&datafinal=${DataFinal !== '' ? `${DataFinal}T23:59` : ''}`);
                setNfse(response.data.content);
                console.log(response.data)
                setTotalElements(response.data.totalElements);
                setquantidadenfecount(response.data.pageable.offset);
                setpageNumber(newPage);
                if (response.data.content.length < 20) {
                    setDisabledNextButton(true);
                } else {
                    setDisabledNextButton(false);
                }
            } catch (error) {
                // Tratamento de erro
            }
        }

        async function handleNextButtonClick() {
            try {
                const newPage = pagenumber + 1;
                const response = await axiosInstance.get(`/consultanfse/paginado?page=${newPage}&cnpjtomador=${Coligada}&nomefantasia=${Fornecedores}&codigoverificacao=${codigoverificacao}&datainicial=${DataInicial !== '' ? `${DataInicial}T00:00` : ''}&datafinal=${DataFinal !== '' ? `${DataFinal}T23:59` : ''}`);
                setNfse(response.data.content);
                console.log(response.data)
                setTotalElements(response.data.totalElements);
                setquantidadenfecount(response.data.pageable.offset);
                setpageNumber(newPage);
                if (response.data.content.length < 20) {
                    setDisabledNextButton(true);
                } else {
                    setDisabledNextButton(false);
                }
            } catch (error) {
                // Tratamento de erro
            }
        }

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={pagenumber === 0}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>

                {/* Substitua {pagenumber} por {displayPageNumber} para exibir o número de página para o usuário */}
                <IconButton>{displayPageNumber}</IconButton>

                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={pagenumber >= Math.ceil(count / rowsPerPage) || DisabledNextButton === true}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
            </Box>
        );
    }





    async function handleRefresh() {
        window.location.href = "/documentoentradanfse"
    }

    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <div>
                    <div class="card-header">
                        <section class="content-header">
                            <h1><i class="fa fa-plus-square fa-1x cor-padrao"></i> NFS-e
                                <small></small>
                            </h1>
                            <ol class="breadcrumb">
                                <li><i class="fa fa-home"></i> Portal JCR</li>
                                <li><i class="fa fa-book"></i> Documento de Entrada</li>
                                <li class="active"><i class="fa fa-file"></i> NFS-e</li>

                            </ol>
                        </section>
                    </div>
                </div>
                <div class="">

                    <div className='content'>
                        <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger ">

                            <div class="card-body card ">
                                <div className='scrollBox'>
                                    <div class="col-md-3 col-sm-6 col-12">
                                        <div className="small-box bg-info " style={{ color: '#ffffff' }}>
                                            <div className="inner">
                                                <h4>Total: {QuantidadeNfseCuritiba}</h4>
                                                <p>Prefeitura Curitiba</p>
                                            </div>
                                            <div className="icon">
                                                <i className="ion ion-bag"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-6 col-12">
                                        <div className="small-box " style={{ background: '#347474', color: '#ffffff' }}>
                                            <div className="inner">
                                                <h4>Total: {QuantidadeNfseBarueri}</h4>
                                                <p>Prefeitura Barueri</p>
                                            </div>
                                            <div className="icon">
                                                <i className="ion ion-bag"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-3 col-sm-6 col-12">
                                        <div className="small-box " style={{ background: '#d9b650', color: '#ffffff' }}>
                                            <div className="inner">
                                                <h4>Total: {QuantidadeNfsePontal}</h4>
                                                <p>Pontal do Paraná</p>
                                            </div>
                                            <div className="icon">
                                                <i className="ion ion-bag"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-6 col-12">
                                        <div className="small-box " style={{ background: '#dddddd', color: '#ffffff' }}>
                                            <div className="inner">
                                                <h4>Total:</h4>
                                                <p>São Paulo</p>
                                            </div>
                                            <div className="icon">
                                                <i className="ion ion-bag"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-6 col-12">
                                        <div className="small-box " style={{ background: '#dddddd', color: '#ffffff' }}>
                                            <div className="inner">
                                                <h4>Total:</h4>
                                                <p>Prefeitura Ponta Grossa</p>
                                            </div>
                                            <div className="icon">
                                                <i className="ion ion-bag"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="">

                                    <h4 className="col-sm-6 col-md-2 control-label" sx={{ fontWeight: 'bold' }}>Filtros </h4>
                                </div>


                                <div class="box-footer">
                                </div>

                                <div class="form-horizontal">

                                    <div class="">


                                        <div class="form-group">

                                            <div class="col-sm-6 col-md-3 control-label">
                                                <label for="selectPrioridade">Tomador de Serviço: </label>
                                            </div>
                                            <div class="col-sm-2">

                                                <select selected="selected" class="form-control select2 select2-hidden-accessible" multiple=""
                                                    style={{ height: 35 }} onChange={(ev) => setColigada(ev.target.value)}>
                                                    <option value="">Selecione</option>
                                                    {ComboColigadasFornecedor.map((row) => (

                                                        <option value={row.cnpj}>{row.nomefantasia}</option>

                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="col-sm-6 col-md-3 control-label">
                                                <label for="selectPrioridade">Prestador de Serviço: </label>
                                            </div>
                                            <div class="col-sm-3">
                                                <input id="chamadoForm:titulo"
                                                    type="text"
                                                    value={Fornecedores}
                                                    onChange={(ev) => setFornecedores(ev.target.value)}
                                                    name="chamadoForm:titulo" class="form-control" maxlength="100">
                                                </input>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="col-sm-6 col-md-3 control-label">
                                                <label for="selectPrioridade">Codigo Verificação: </label>
                                            </div>
                                            <div class="col-sm-3">
                                                <input id="chamadoForm:titulo2"
                                                    type="text"
                                                    value={codigoverificacao}
                                                    onChange={(ev) => setCodigoVerificao(ev.target.value)}
                                                    name="chamadoForm:titulo2" class="form-control" maxlength="100">
                                                </input>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="col-sm-3 col-md-3 control-label">
                                                <label for="selectPrioridade">Período: </label>
                                            </div>
                                            <div class="form-group">
                                                <div class="col-sm-3 col-md-3 control-label">
                                                    <Stack spacing={8}>
                                                        <input type="date"
                                                            value={DataInicial}
                                                            onChange={(ev) => setDataInicial(ev.target.value)}
                                                            min="01/01/2022"
                                                            max="01/01/2030"></input>
                                                    </Stack></div>
                                                <div class="">
                                                    <div class="col-sm-3 col-md-3 control-label">
                                                        <Stack spacing={1}>
                                                            <input type="date"
                                                                value={DataFinal}
                                                                onChange={(ev) => setDataFinal(ev.target.value)}

                                                                min="01/01/2022"
                                                                max="01/01/2030"></input>

                                                        </Stack>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </div>

                                </div>
                                <div class="box-footer">
                                    <button type="button" class="btn btn-primary col-xs-12 col-sm-5" style={{ height: 35 }} onClick={() => handleRefresh()}>
                                        <i class="fa-solid fa-magnifying-glass" ></i>Limpar Filtro</button>
                                    <div class="col-sm-1"></div>
                                    <button type="button" class="btn btn-success col-xs-12 col-sm-6" style={{ height: 35 }} onClick={() => handleBuscar()}>
                                        <i class="fa-solid fa-magnifying-glass" ></i>Buscar</button>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div className='content'>
                    <div class=" ui-panel ui-widget ui-widget-content ui-corner-all box box-danger ">
                        <div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" class="ui-panel-content ui-widget-content">
                            <div class="box-header with-border ">
                                <div class="form-horizontal">
                                    {!completed ? (
                                        <>
                                            {!loading ? (
                                                <Dialog
                                                    open={true}

                                                    aria-describedby="alert-dialog-slide-description"

                                                >
                                                    <div className="pos-center">
                                                        <Spinner />
                                                    </div>
                                                </Dialog>
                                            ) : (
                                                <Dialog
                                                    open={true}

                                                    aria-describedby="alert-dialog-slide-description"

                                                >
                                                    <div className="pos-center">
                                                        <Spinner />
                                                    </div>
                                                </Dialog>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <div className=''>

                                                <div class="table table-striped table-bordered table-resizable box-body card scrollverticalmente tabelaNfe ">

                                                    {SituacaoNfe === true ?
                                                        <div>
                                                            <h4 style={{ paddingLeft: '35%' }} >Nenhum Documento Localizado!</h4>
                                                        </div>
                                                        :
                                                        <div>
                                                            <Table className="table " stickyHeader aria-label="sticky table">

                                                                <TableHead >

                                                                    <TableRow >

                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Gerar PDF</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Gerar XML</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Modelo</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Codigo Verificação</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Número</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Codigo Produto</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Cnpj/Cpf - Prestador</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Nome Fantasia - Prestador</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Cidade Origem</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Cnpj/Cpf - Tomador</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Nome Fantasia - Tomador</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Cidade Destino</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Data Emissão</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Valor Nota</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Alíquota</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Base Cálculo</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Total Pis</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Total Cofins</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Total Irrf</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Total Inss</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Total Csllrf</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Total IssRetido</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Total Credito</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Iss Retido</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Valores Serviços</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Valores Deduções</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Outras Retenções</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>

                                                                    {nfse.map((row) => (

                                                                        <TableRow
                                                                            key={row.numero}
                                                                            sx={{ border: 100, width: 100 }}
                                                                        >

                                                                            <Tooltip title={"Gerar PDF: " + row.codigoverificacao}><TableCell component="th" scope="row">
                                                                                <a>
                                                                                    <img src="https://indaiatuba.sp.gov.br/_vs2021/icones/ico_nfse.png" width="30" height="30"
                                                                                        onClick={() => gerarPDF(row.codigoverificacao, row.cidadeprestador)}
                                                                                    >
                                                                                    </img>
                                                                                </a>
                                                                            </TableCell></Tooltip>
                                                                            <Tooltip title={"Gerar XML: " + row.codigoverificacao}><TableCell component="th" scope="row">
                                                                                <a>
                                                                                    <img src="https://cdn.iconscout.com/icon/free/png-256/xml-file-2330558-1950399.png"
                                                                                        width="30" height="30" onClick={() => gerarXML(row.codigoverificacao)}></img>
                                                                                </a>
                                                                            </TableCell></Tooltip>

                                                                            <Tooltip title={row.tiponota}><TableCell>{row.tiponota}</TableCell></Tooltip>
                                                                            <Tooltip title={row.codigoverificacao}><TableCell >{row.codigoverificacao}</TableCell></Tooltip>
                                                                            <Tooltip title={row.numero}><TableCell >{row.numero}</TableCell></Tooltip>
                                                                            <Tooltip title={row.itemlistaservico}><TableCell >{row.itemlistaservico}</TableCell></Tooltip>
                                                                            <Tooltip


                                                                                title={!row.cancelada == null || row.cancelada == '' || row.cancelada == ' ' ? (
                                                                                    <div>
                                                                                        {row.cancelada}
                                                                                    </div>
                                                                                ) : (
                                                                                    <div>
                                                                                        {row.cancelada}
                                                                                    </div>
                                                                                )}>

                                                                                <TableCell >{row.cancelada}
                                                                                </TableCell></Tooltip>
                                                                            <TableCell size="small" component="th" scope="row">{row.cnpjprestador}</TableCell>
                                                                            <TableCell sx={{ width: '100%' }} size="small" component="th" scope="row">{row.nomefantasia}</TableCell>
                                                                            <TableCell size="small" component="th" scope="row">{row.cidadeorigem}</TableCell>
                                                                            <TableCell size="small" component="th" scope="row">{row.cnpjdestinatario}</TableCell>
                                                                            <TableCell size="small" component="th" scope="row">{row.razaosocialtomador}</TableCell>
                                                                            <TableCell size="small" component="th" scope="row">{row.cidadeprestador}</TableCell>
                                                                            <TableCell size="small" component="th" scope="row" className={classes.dataemissao}>{row.dataemissao}</TableCell>
                                                                            <TableCell size="small" component="th" scope="row">
                                                                                {row.valordanota !== null && row.valordanota !== undefined
                                                                                    ? row.valordanota.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
                                                                                    : ""}
                                                                            </TableCell>
                                                                            <TableCell size="small" component="th" scope="row">
                                                                                {row.aliquota !== null && row.aliquota !== undefined
                                                                                    ? row.aliquota.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
                                                                                    : ""}
                                                                            </TableCell>
                                                                            <TableCell size="small" component="th" scope="row">
                                                                                {row.basecalculo !== null && row.basecalculo !== undefined
                                                                                    ? row.basecalculo.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
                                                                                    : ""}
                                                                            </TableCell>
                                                                            <TableCell size="small" component="th" scope="row">
                                                                                {row.totalpis !== null && row.totalpis !== undefined
                                                                                    ? row.totalpis.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
                                                                                    : ""}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {row.totalcofins !== null && row.totalcofins !== undefined
                                                                                    ? row.totalcofins.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
                                                                                    : ""}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {row.totalir !== null && row.totalir !== undefined
                                                                                    ? row.totalir.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
                                                                                    : ""}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {row.totalinss !== null && row.totalinss !== undefined
                                                                                    ? row.totalinss.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
                                                                                    : ""}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {row.totalcsll !== null && row.totalcsll !== undefined
                                                                                    ? row.totalcsll.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
                                                                                    : ""}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {row.totalissretido !== null && row.totalissretido !== undefined
                                                                                    ? row.totalissretido.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
                                                                                    : ""}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {row.valorcredito !== null && row.valorcredito !== undefined
                                                                                    ? row.valorcredito.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
                                                                                    : ""}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {row.issretido !== null && row.issretido !== undefined
                                                                                    ? row.issretido.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
                                                                                    : ""}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {row.valorservicos !== null && row.valorservicos !== undefined
                                                                                    ? row.valorservicos.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
                                                                                    : ""}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {row.valordeducoes !== null && row.valordeducoes !== undefined
                                                                                    ? row.valordeducoes.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
                                                                                    : ""}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {row.outrasretencoes !== null && row.outrasretencoes !== undefined
                                                                                    ? row.outrasretencoes.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
                                                                                    : ""}
                                                                            </TableCell>

                                                                        </TableRow>

                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </div>
                                                    }
                                                    <TableRow>

                                                    </TableRow>

                                                </div>

                                            </div>
                                            <div class="cardmov">
                                                <TablePagination
                                                    colSpan={3}
                                                    component="div"
                                                    rowsPerPageOptions={[20]}
                                                    //count={nfse.length}
                                                    count={quantidadenfecount}
                                                    rowsPerPage={nfse.length}
                                                    page={pagenumber}
                                                    ActionsComponent={TablePaginationActions}

                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    labelDisplayedRows={({ from, to, count }) => (
                                                        <>
                                                            <p><strong>Total de notas do período:       {totalElements}</strong></p>
                                                            <div class="custom-label">
                                                                <p><strong>Total de registros por página:        20</strong></p>
                                                            </div>
                                                        </>
                                                    )}

                                                />

                                            </div>

                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
            <Footer />
        </div >
    )
}