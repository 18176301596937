import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const isAuth = () => {
  if (localStorage.getItem('jwttoken') !== null) {
    return true;
  }
  return false;
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!isAuth()) {
          return <Redirect to="/" />;
        }
        return <Component {...props} />;
      }}
    />
  );
}

export default PrivateRoute;
