import React from 'react';
import './menuitem.css';

export default function MenuItem(props) {
  const isChildOfMenuTree = props.parentType === 'MenuTree';
  const iconClass = `fa fa-${props.icon} ${props.iconSize ? `fa-${props.iconSize}` : ''}`;

  const menuStyle = {
    marginLeft: props.menuStyle ? props.menuStyle : '2px',
    width: props.menuWidth // Adicione a propriedade menuWidth como largura do MenuItem
  };

  const handleClick = () => {
    if (!props.disabled && props.onClick) {
      props.onClick();
    }
  };

  return (
    <li className={`menu-item ${props.disabled ? 'disabled' : ''}`}>
      <a
        href={props.disabled ? undefined : props.path}
        className={`ui-link ui-widget ${props.disabled ? 'disabled' : ''}`}
        style={menuStyle}
        onClick={handleClick}
      >
        <i className={iconClass}></i>
        {!isChildOfMenuTree && props.label && <span className="menu-label">{props.label}</span>}
        {props.disabled && <div className="disabled-overlay"></div>}
      </a>
    </li>
  );
}
