
import { Card, Modal, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../../../../common/template/footer';
import Header from '../../../../common/template/header';
import SideBar from '../../../../common/template/sideBar';
import Spinner from '../../../../common/template/Spinner';
import axiosInstance from '../../../../config/api';


export default function SlChamado() {
    const { id } = useParams();
    const [temposla, settemposla] = useState('');
    const [identificacao, setidentificacao] = useState('');
    const [prioridade, setprioridade] = useState('');
    const [subcategoriadto, setsubcategoriadto] = useState('');
    const [subcategoriadto2, setsubcategoriadto2] = useState('');
    const [loading, setloading] = useState(false);
    const [subcategoria, setsubcategoria] = useState([]);
    const [categoriadto, setCategoriaChamadodto] = useState('');




    var newInt2 = JSON.parse(localStorage.getItem('idsetor'));
    var setorid = newInt2.idsetor;


    var newInt2 = JSON.parse(localStorage.getItem('setor'));
    var setor = newInt2.setor;


    useEffect(() => {
        async function listasla() {
            const response = await axiosInstance.get("/temposla/" + id)
            setidentificacao(response.data.id);
            settemposla(response.data.temposla);
            setprioridade(response.data.prioridade);
            setCategoriaChamadodto(response.data.categoria);
            setsubcategoriadto2(response.data.subcategoria);

        }
        listasla();

        async function loadingSubcategoria() {

            const response = await axiosInstance.get(`/categoriachamado/${setorid}`)
            setsubcategoria(response.data)
        }
        loadingSubcategoria();

    }, []);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '52%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#ffffff',
        border: '2px solid #ffffff',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,

    };


    const stylealert = {
        position: 'absolute',
        top: '50%',
        left: '52%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#ffffff',
        border: '2px solid #ffffff',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,

    };



    const [open, setOpen] = React.useState(false);

    const [validcaoalerta, setvalidcaoalert] = React.useState(false);

    const handleOpenAlerta = () => {
        setvalidcaoalert(true);
    };
    const handleCloseAlerta = () => {
        setvalidcaoalert(false);
        setOpen(false);
    };



    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    async function voltar() {
        window.location.href = '/slachamadodswid=9747'
    }


    async function cadastroSlaNotifica() {
        handleClose(false);
        setloading(true);
        window.location.href = "/slachamadodswid=9747"
    }
    async function erro() {
        handleClose(false);
        setvalidcaoalert(true);
    }

    async function novocadastro() {
        handleClose(false);

        const crudinsertuser = {
            temposla: temposla,
            categoria: categoriadto,
            subcategoria: subcategoriadto2,
            prioridade: prioridade,
            grupousuario: { id: setorid },
        }

        const response = await axiosInstance.put('/temposla/' + id, crudinsertuser)

            .then(resp => {
                cadastroSlaNotifica();
            }).catch(error => {
                erro();
            });

    }


    const loadingcss = {
        position: 'absolute',
        top: '50%',
        left: '52%'
    }

    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <div>
                    <div class="card-header">

                        <section class="content-header">
                            <h1><i class="fa fa-plus fa-1x cor-padrao"></i> Cadastro do Sla Chamado
                                <small></small>
                            </h1>
                            <ol class="breadcrumb">
                                <li><i class="fa fa-home"></i> Portal JCR</li>
                                <li><i class="fa fa-wrench"></i> Sistema de Chamado</li>
                                <li class="disabled"><i class="fa fa-list-ul" ></i> Configuração do Chamado</li>
                                <li class="active"><i class="fa fa-clock-o"></i> (Sla) Chamado</li>
                                <li class="active"><i class="fa fa-plus"></i> Cadastro do Sla Chamado</li>
                            </ol>
                        </section>
                    </div>
                    <div class="content">
                        <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger">
                            <div id="manutencaoContratoFaturamentoForm:painel_content" class="ui-panel-content ui-widget-content">
                                <div class="box-header with-border">
                                    {loading === true ? (
                                        <div className="">
                                            <Modal
                                                open={loading}
                                                aria-labelledby="parent-modal-title"
                                                aria-describedby="parent-modal-description"
                                            >
                                                <Box sx={{ ...loadingcss, width: 400 }}>
                                                    <Spinner />
                                                </Box>
                                            </Modal>
                                        </div>
                                    ) : (
                                        <div>

                                        </div>
                                    )}
                                    <div class="card-body ">
                                        <label>ID*: </label>
                                        <input type="text"
                                            disabled="disabled"
                                            value={identificacao}
                                            class="form-control"
                                            id="id"
                                        ></input>
                                        <label>Setor*: </label>
                                        <input type="text"
                                            disabled="disabled"
                                            value={setor}
                                            class="form-control"
                                            id="setor"
                                        ></input>
                                        <label>TempoSla*: </label>
                                        <input id="hora-cons" type="time" name="hora-cons" step="2"
                                            onChange={e => settemposla(e.target.value)}
                                            value={temposla}
                                            class="form-control"

                                        ></input>
                                        <label>Prioridade do Chamado*: </label>
                                        <select style={{ height: '35px' }}
                                            id="ambienteenum"
                                            value={prioridade}
                                            class="form-control"
                                            onChange={e => setprioridade(e.target.value)}>
                                            <option>Selecione*</option>
                                            <option value="ALTA">ALTA</option>
                                            <option value="MEDIA">MÉDIA</option>
                                            <option value="BAIXA">BAIXA</option>
                                        </select>

                                        <label>SubCategoria</label>
                                        <select style={{ height: '35px' }}
                                            class="form-control"
                                            disabled="disabled"
                                            value={subcategoriadto2}


                                        >{subcategoria.map((e, key) => {
                                            return (
                                                <optgroup key={key} label={e.nomecategoria} >
                                                    {subcategoria.filter(ea => e.id === ea.id)

                                                        .map(option =>
                                                            option.subcategoria.map((option2, i) => {

                                                                return (
                                                                    <option key={i} value={option2.nomesubcategoria}

                                                                    >

                                                                        {option2.nomesubcategoria}
                                                                    </option>

                                                                )
                                                            }))}

                                                </optgroup>
                                            )
                                        })}




                                        </select>
                                        <br></br><br></br>
                                        <div class="col-sm-2 col-md-6 cantodireito">
                                            <button type="button" class="btn btn-block btn-danger btn-lg"
                                                onClick={voltar}>
                                                <i class="fa fa-chevron-left"></i> VOLTAR</button>
                                        </div>
                                        <div class="col-sm-2 col-md-6 cantodireito2">
                                            <button type="button" class="btn btn-block btn-primary btn-lg"
                                                onClick={handleOpen}>
                                                <i class="fa fa-save"></i> SALVAR</button>
                                            <br></br>  <br></br>
                                        </div>

                                        <Modal
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="parent-modal-title"
                                            aria-describedby="parent-modal-description"
                                        >
                                            <Box sx={{ ...style, width: 400 }}>
                                                <h2 id="parent-modal-title">Você Realmente Deseja Alterar esse Cadastro do Sla?</h2>
                                                <p id="parent-modal-description">
                                                    <button type="button" class="btn btn-block btn-danger btn-lg" onClick={handleClose}>Não</button>
                                                    <button type="button" class="btn btn-block btn-primary btn-lg" onClick={() => novocadastro(id)}>Sim</button>
                                                </p>

                                            </Box>
                                        </Modal>


                                        <Modal
                                            open={validcaoalerta}
                                            onClose={handleCloseAlerta}
                                            aria-labelledby="parent-modal-title"
                                            aria-describedby="parent-modal-description"
                                        >
                                            <Box sx={{ ...stylealert, width: 460 }}>
                                                <h2 id="parent-modal-title">Alerta(500) Sla já cadastrado!</h2>
                                                <p id="parent-modal-description">
                                                    <button type="button" class="btn btn-block btn-danger btn-lg" onClick={handleCloseAlerta}>Fechar</button>

                                                </p>

                                            </Box>
                                        </Modal>




                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}