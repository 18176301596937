import React from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import PrivateRoute from './auth';
import Login from "./pages/login/login";
import Home from './pages/home/home'
import Nfse from './pages/doc/nfsedocumentoentrada';
import Nfe from './pages/doc/nfedocumentoentrada';
import NfeCadastro from './pages/doc/nfedocumentoentradaCadastro';
import Usuario from './pages/config/usuario/usuario';
import UsuarioNew from './pages/config/usuario/usuarionew';
import Coligada from './pages/coligada/coligada';
import Certificado from './pages/config/certificado/certificado';
import CertificadoEdit from './pages/config/certificado/certificadoedit';
import CertificadoCadastroEdit from './pages/config/certificado/certificadocadastroedit';
import Relatorios from './pages/relatorios/relatorios';

import SlChamado from './pages/sistemadechamado/configuraçõeschamado/slachamado/slachamado';
import SlChamadoId from './pages/sistemadechamado/configuraçõeschamado/slachamado/slachamadoid';

import CategoriaChamadoId from './pages/sistemadechamado/configuraçõeschamado/categoriachamado/categoriachamadoid';
import CategoriaChamado from './pages/sistemadechamado/configuraçõeschamado/categoriachamado/categoriachamado';
import PainelChamado from './pages/sistemadechamado/paineldechamados/painelchamados';
import NovoChamado from './pages/sistemadechamado/novochamado/novochamado';
import PainelChamadoIDAtribuir from './pages/sistemadechamado/paineldechamados/painelchamadoid.Id';
import MicrosftLists from './pages/microsftLists/microsoftlists';
import CategoriaChamadoNova from './pages/sistemadechamado/configuraçõeschamado/categoriachamado/categoriachamadonew';
import MeusChamados from './pages/sistemadechamado/meuschamados/meuschamado';
import RelatoriosChamados from './pages/sistemadechamado/relatoriodechamados/relatoriochamados';
import SlChamadoCadastro from './pages/sistemadechamado/configuraçõeschamado/slachamado/slachamadocadastro';
import UsuarioNewEdit from './pages/config/usuario/usuarioedit';
import CategoriaChamadoEdit from './pages/sistemadechamado/configuraçõeschamado/categoriachamado/categoriachamadoedit';

import RoadMap2022 from './pages/roadmap/roadmap2022';
import RoadMap2023 from './pages/roadmap/roadmap2023';
import HoleritePagamento from './pages/holerite/holerite';
import PrivateRouteMaster from './authmaster';
import Permissao from './pages/naopossuipermissao/permissao';
import MeuPerfil from './pages/config/meuperfil/meuperfil';
import LoginInvalido from './pages/login/logininvalido';

import NfeSefaz from './pages/config/nfesefaz/nfesefaz';
import Header from './common/template/header';

import GerenciaProjeto from './pages/gerenciamentoprojeto/gerenciaprojeto/gerenciaprojeto';
import NovoProjeto from './pages/gerenciamentoprojeto/novogerenciamento/novogerenciamento';
import GerenciamentoID from './pages/gerenciamentoprojeto/gerenciarid/gerenciamentoid';
import RelatoriosProjeto from './pages/gerenciamentoprojeto/relatorioprojeto/relatorioprojeto';

import ForgotPassowrd from './pages/forgotpassword/forgotpassword';
import GerenciaProjetoView from './pages/gerenciamentoprojeto/gerenciaprojeto/gerencimaneotview';
import NovaPublicacao from './pages/publicacao/novapublicacao/NovaPublicacao';

//<PrivateRoute exact path="/microsft/lists/entradadenotas" component={MicrosftLists}/>
// <Route component={ForgotPassowrd} path="/forgotPassword" exact />

const Routes = () => (

    <Router>
        <Switch>

            <Route component={Login} path="/" exact />

           
            
            <Route component={LoginInvalido} path="/?" exact />
            <Route path="/header" component={Header} />

            <PrivateRoute exact path="/home" component={Home} />
            <PrivateRoute exact path="/meuperfil" component={MeuPerfil} />
            <PrivateRoute exact path="/documentoentradanfse" component={Nfse} />
          
            
            <PrivateRoute exact path='/documentoentradanfe' component={Nfe} />
          

           
            <PrivateRoute exact path='/documentoentradanfe/:chave' component={NfeCadastro} />


        /** Microsft*/
            <PrivateRoute exact path="/relatorios" component={Relatorios} />


            <PrivateRouteMaster exact path='/slachamadodswid=9747' component={SlChamado} />
            <PrivateRouteMaster exact path='/slachamadodswid=9747/novocadastro' component={SlChamadoCadastro} />
            <PrivateRouteMaster exact path='/slachamadodswid=9747/:id' component={SlChamadoId} />

            <PrivateRoute exact path='/categoriachamadodswid=1912311/:id' component={CategoriaChamadoId} />
            <PrivateRoute exact path='/categoriachamadodswid=1912311' component={CategoriaChamado} />
            <PrivateRoute exact path='/categoriachamadodswid=19123112/:id' component={CategoriaChamadoEdit} />
            <PrivateRoute exact path='/categoriachamadodswid/novacategoria' component={CategoriaChamadoNova} />


            <PrivateRoute exact path="/novochamado" component={NovoChamado} />
            <PrivateRoute exact path="/paineldechamados" component={PainelChamado} />
            <PrivateRoute exact path="/meuschamados" component={MeusChamados} />
            <PrivateRoute exact path="/paineldechamados/:id" component={PainelChamadoIDAtribuir} />
            <PrivateRoute exact path="/relatoriochamados" component={RelatoriosChamados} />

            <PrivateRoute exact path="/novapublicacao" component={NovaPublicacao} />

        /** Historico de Versão do Road Map */

            <PrivateRoute exact path="/roadmapti" component={RoadMap2022} />
            <PrivateRoute exact path="/roadmapti2023" component={RoadMap2023} />


        /**RM */


            /** RECURSOS HUMANOS */
            <PrivateRoute exact path="/holeritepagamento" component={HoleritePagamento} />


            <PrivateRoute exact path="/usuarionaotempermissao" component={Permissao} />

        /** CONFIGURAÇÃO */

            <PrivateRouteMaster exact path="/cadastrouser" component={Usuario} />
            <PrivateRouteMaster exact path="/cadastrouser/create-new" component={UsuarioNew} />
            <PrivateRouteMaster exact path="/cadastrouser/:id" component={UsuarioNewEdit} />
            <PrivateRouteMaster exact path='/gerencimanetoprojeto' component={GerenciaProjeto}/>
            <PrivateRouteMaster exact path='/gerencimanetoprojeto/:id' component={GerenciamentoID}/>
            <PrivateRouteMaster exact path='/gerencimanetoprojetoview' component={GerenciaProjetoView}/>
            <PrivateRouteMaster exact path='/relatorioprojeto' component={RelatoriosProjeto} />
            <PrivateRouteMaster exact path="/novoprojeto" component={NovoProjeto}/>
           


            <PrivateRouteMaster exact path="/cadastrocoligada" component={Coligada} />
            <PrivateRouteMaster exact path="/certificado" component={Certificado} />
            <PrivateRouteMaster exact path="/certificadoedit" component={CertificadoEdit} />
            <PrivateRouteMaster exact path="/certificadoedit/edit-certificado/:id" component={CertificadoCadastroEdit} />
            <PrivateRouteMaster exac path="/nfeconfig" component={NfeSefaz} />
           

            <Route render={() => <Redirect to="/" />} />
        </Switch>
    </Router>

);

export default Routes;