import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../../../../common/template/footer';
import Header from '../../../../common/template/header';
import SideBar from '../../../../common/template/sideBar';
import Spinner from '../../../../common/template/Spinner';
import axiosInstance from '../../../../config/api';

import './slachamado.css';


export default function SlChamadoCadastro() {
    const [subcategoriadto, setsubcategoriadto] = useState('');
    const [subcategoria, setsubcategoria] = useState([]);
    const [categoriadto, setCategoriaChamadodto] = useState('');
    const [loading, setLoading] = useState('');
    const [temposla, settemposla] = useState('');
    const [prioridade, setprioridade] = useState('');
    var newInt2 = JSON.parse(localStorage.getItem('idsetor'));
    var setorid = newInt2.idsetor;

    var newInt2 = JSON.parse(localStorage.getItem('setor'));
    var setor = newInt2.setor;

    async function voltar() {
        window.location.href = '/slachamadodswid=9747'
    }

    useEffect(() => {
        async function loadingSubcategoria() {

            const response = await axiosInstance.get(`/categoriachamado/${setorid}`)
            setsubcategoria(response.data)
        }
        loadingSubcategoria();
    }, []);

    const [validcaoalerta, setvalidcaoalert] = React.useState(false);
    const [validcaoSucess, setValidacaoSucess] = React.useState(false);

    const handleOpenAlerta = () => {
        setvalidcaoalert(true);
    };
    const handleCloseAlerta = () => {
        setvalidcaoalert(false);

    };



    const handleOpenSucess = () => {
        setValidacaoSucess(true);
    };
    const handleCloseSucess = () => {
        setValidacaoSucess(false);

    };


    const stylealert = {
        position: 'absolute',
        top: '50%',
        left: '52%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#ffffff',
        border: '2px solid #ffffff',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,


        h2: {
            fontWeight: "bold",
        }

    };

    const stylealertbold = {
        fontWeight: "bold",
    }

    async function loadingcadastro() {

        window.location.href = "/slachamadodswid=9747"

    }
    const loadingcss = {
        position: 'absolute',
        top: '50%',
        left: '52%'
    }


    async function novocadastro() {

        setLoading(true);

        const crudinsertuser = {
            temposla: temposla,
            categoria: categoriadto,
            subcategoria: subcategoriadto,
            prioridade: prioridade,
            grupousuario: { id: setorid },
        }

        const response = await axiosInstance.post('/temposla', crudinsertuser)

            .then(resp => {

                loadingcadastro();
            }).catch(error => {
                if (error !== 500 || error == 500) {
                    setvalidcaoalert(true);
                }
            });

    }

    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <div>
                    <div class="card-header">
                        <h3 class="card-title"> Cadastro Sla Chamado

                        </h3>

                    </div>
                    <br></br>
                    <ol class="breadcrumb">
                        <li><i class="fa fa-home"></i> Portal JCR Cloud</li>
                        <li><i class="fa fa-file-text"></i> Sistema de Chamado</li>
                        <li><i class="fa fa-edit"></i> Configurações de Chamado</li>
                        <li><i class="fa fa-edit"></i> Sla Chamado</li>
                        <li><i class="fa fa-edit"></i> Cadastro Chamado</li>

                    </ol>
                    <div class="content">
                        <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger">
                            <div id="manutencaoContratoFaturamentoForm:painel_content" class="ui-panel-content ui-widget-content">
                                <div class="box-header with-border">
                                    {loading === true ? (
                                        <div className="">
                                            <Modal
                                                open={loading}
                                                aria-labelledby="parent-modal-title"
                                                aria-describedby="parent-modal-description"
                                            >
                                                <Box sx={{ ...loadingcss, width: 400 }}>
                                                    <Spinner />
                                                </Box>
                                            </Modal>
                                        </div>
                                    ) : (
                                        <div>

                                        </div>
                                    )}
                                    <div class="card-body ">

                                        <label>Setor*: </label>
                                        <input type="text"
                                            disabled="disabled"
                                            value={setor}
                                            class="form-control"
                                            id="setor"
                                        ></input>
                                        <label>SubCategoria</label>
                                        <select style={{ height: '35px' }}
                                            class="form-control"

                                            onChange={(es) => {
                                                const select = es.target;
                                                const optgroup = select.options[select.selectedIndex].parentElement;
                                                setsubcategoriadto(select.value);
                                                setCategoriaChamadodto(optgroup.label);
                                            }}
                                        >
                                            <option value=""> Selecione</option>

                                            {subcategoria.map((e, key) => {
                                                return (
                                                    <optgroup key={key} label={e.nomecategoria} >
                                                        {subcategoria.filter(ea => e.id === ea.id)

                                                            .map(option =>
                                                                option.subcategoria.map((option2, i) => {

                                                                    return (
                                                                        <option key={i} value={option2.nomesubcategoria}

                                                                        >

                                                                            {option2.nomesubcategoria}
                                                                        </option>

                                                                    )
                                                                }))}

                                                    </optgroup>
                                                )
                                            })}

                                        </select>
                                        <label>TempoSla*: </label>
                                        <input id="hora-cons" type="time" name="hora-cons" step="2"
                                            onChange={e => settemposla(e.target.value)}
                                            value={temposla}
                                            class="form-control"

                                        ></input>
                                        <label>Prioridade do Chamado*: </label>
                                        <select style={{ height: '35px' }}
                                            id="ambienteenum"
                                            value={prioridade}
                                            class="form-control"
                                            onChange={e => setprioridade(e.target.value)}>
                                            <option>Selecione*</option>
                                            <option value="ALTA">ALTA</option>
                                            <option value="MEDIA">MÉDIA</option>
                                            <option value="BAIXA">BAIXA</option>
                                        </select>
                                        <br></br><br></br>
                                        <div class="col-sm-2 col-md-6 cantodireito">
                                            <button type="button" class="btn btn-block btn-danger btn-lg"
                                                onClick={voltar}>
                                                <i class="fa fa-chevron-left"></i> VOLTAR</button>
                                        </div>
                                        <div class="col-sm-2 col-md-6 cantodireito2">
                                            <button type="button" class="btn btn-block btn-primary btn-lg"
                                                onClick={handleOpenSucess}
                                            >
                                                <i class="fa fa-save"></i> SALVAR</button>
                                            <br></br>  <br></br>
                                        </div>
                                    </div>
                                </div>
                                <Modal
                                    open={validcaoSucess}
                                    onClose={handleCloseSucess}
                                    aria-labelledby="parent-modal-title"
                                    aria-describedby="parent-modal-description"
                                >
                                    <Box sx={{ ...stylealert, width: 460 }}>
                                        <h2 id="parent-modal-title">Você deseja Realmente Salvar esse Sla ?</h2>
                                        <p id="parent-modal-description">
                                            <button type="button" class="btn btn-block btn-danger btn-lg" onClick={handleCloseSucess}>Fechar</button>
                                            <button type="button" class="btn btn-block btn-primary btn-lg" onClick={() => novocadastro()}>Salvar</button>
                                        </p>

                                    </Box>
                                </Modal>
                                <Modal
                                    open={validcaoalerta}
                                    onClose={handleCloseAlerta}
                                    aria-labelledby="parent-modal-title"
                                    aria-describedby="parent-modal-description"
                                >
                                    <Box sx={{ ...stylealert, width: 460 }}>
                                        <h2 id="parent-modal-title" className="font">Alerta(500) Sla </h2>
                                        <h3> Já possui um Cadastro ou valide se todos os campos foram preenchidos</h3>
                                        <h4>------Log Cadastro------</h4>
                                        <h5>Setor:{setor}</h5>
                                        <h5>SubCategoria:{subcategoriadto === '' ? `Campo SubCategoria não Preenchido` : `${subcategoriadto}`} </h5>
                                        <h5>Tempo Sla:{temposla === '' ? `Campo Tempo Sla não Preenchido` : `${temposla}`}</h5>
                                        <h5>Prioridade do Chamado :{prioridade === 'Selecione*' ? `Campo Prioridade não Preenchido` : `${prioridade}`}</h5>
                                        <h4>------Fim Log-----</h4>
                                        <p id="parent-modal-description">
                                            <button type="button" class="btn btn-block btn-success btn-lg" onClick={handleCloseAlerta}>Fechar</button>

                                        </p>

                                    </Box>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}