import { Alert, Dialog, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import Footer from '../../../common/template/footer';
import Header from '../../../common/template/header';
import SideBar from '../../../common/template/sideBar';
import axiosInstance from '../../../config/api';

import { useTheme } from '@mui/material/styles';

import VisualizarIMG from '../../../common/template/img/visualizar.png'

import Spinner from '../../../common/template/Spinner';

import './paineldechamados.css';
import { Box } from '@mui/system';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';




export default function PainelChamado(props) {
    const tableRef = useRef(null);
    const [DisabledNextButton, setDisabledNextButton] = React.useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(null);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [chamado, setchamado] = useState([]);
    const [totalemaberto, settotalemaberto] = useState([]);
    const [totalemaandamento, settotalemaandamento] = useState([]);
    const [situacaoChamado, setSituacaoChamado] = useState(false);
    const [pagenumber, setpageNumber] = React.useState('');
    const [loading, setloading] = useState(false);
    const [completed, setcompleted] = useState(false);
    const [totalPaginas, setTotalpaginas] = React.useState([]);
    const [counter, setCounter] = useState(0);
    const [size, setsize] = React.useState([]);
    const [totalElements, setTotalElements] = useState(0);

    const [quantidadecount, setquantidadecount] = React.useState([]);

    const [last, setLast] = React.useState([]);

    var newInt2 = JSON.parse(localStorage.getItem('idsetor'));
    var idusuario = newInt2.idsetor;

    const handleChangeRowsPerPage = (event) => {
        setpageNumber(pagenumber++);
    };
    const handleDragStart = (e) => {
        setIsDragging(true);
        setStartX(e.clientX);
        setScrollLeft(tableRef.current.scrollLeft);
        e.preventDefault();
    };

    const handleDragMove = (e) => {
        if (!isDragging) return;
        const x = e.clientX;
        const distance = x - startX;
        tableRef.current.scrollLeft = scrollLeft - distance;
        e.preventDefault();
    };

    const handleDragEnd = () => {
        setIsDragging(false);
    };


    const styles = {
        ticketaberto: {
            backgroundColor: '#00a65a',
            background: '#00a65a',
            color: '#ffffff',
        },

        ticketfechado: {
            backgroundColor: '#dd4b39',
            background: '#dd4b39',
            color: '#ffffff',
        },

        ticketemandamento: {
            backgroundColor: '#f39c12',
            background: '#f39c12',
            color: '#ffffff',
        },
        ticketresolvido: {
            backgroundColor: '#00c0ef',
            background: '#00c0ef',
            color: '#ffffff',
        },
        ticketreaberto: {
            backgroundColor: '#e03232',
        },
        palta: {
            backgroundColor: '#e03232',
            background: '#e03232',
            color: '#ffffff',
        },
        pmedia: {
            backgroundColor: '#ffbcbc',
            background: '#ffbcbc',
            color: 'black',
        },
        pbaixa: {
            backgroundColor: '#ffe6e6',
            background: '#ffe6e6',
            color: 'black',
        }

    };

    function TablePaginationActions() {
        const theme = useTheme();
        const { count, rowsPerPage } = props;

        // Adicione esta linha para calcular o número de página exibido para o usuário
        const displayPageNumber = pagenumber + 1;

        async function handleBackButtonClick() {
            try {
                const newPage = pagenumber - 1;
                const response = await axiosInstance.get(`/chamado/paginado/${idusuario}?page=${newPage}`);
                setchamado(response.data.content);
                setTotalElements(response.data.totalElements);
                setquantidadecount(response.data.pageable.offset);
                setpageNumber(newPage);

        
                if (response.data.content.length < 20) {
                    setDisabledNextButton(true);
                } else {
                    setDisabledNextButton(false);
                }
                
                
            } catch (error) {
                // Tratamento de erro
            }
        }
        async function handleNextButtonClick() {
            try {
                const newPage = pagenumber + 1;


                const response = await axiosInstance.get(`/chamado/paginado/${idusuario}?page=${newPage}`);
                setchamado(response.data.content);
                setTotalElements(response.data.totalElements);
                setquantidadecount(response.data.pageable.offset);
                setpageNumber(newPage);

                if (response.data.content.length < 20) {
                    setDisabledNextButton(true);
                } else {
                    setDisabledNextButton(false);
                }
            } catch (error) {
                // Tratamento de erro
            }
        }

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={pagenumber === 0}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton>
                    <IconButton>{displayPageNumber}</IconButton>
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={pagenumber >= Math.ceil(count / rowsPerPage) || DisabledNextButton === true}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
            </Box>
        );
    }


    useEffect(() => {
        setTimeout(() => {
            setcompleted(true);
        }, 500);

        async function loadingchamado() {
            const response = await axiosInstance.get(`/chamado/paginado/${idusuario}`)
                .then(resp => {
                    if (resp.data.totalElements === 0) {
                        setSituacaoChamado(true);
                    } else {
                        setchamado(resp.data.content);
                        setTotalElements(resp.data.totalElements);
                        setquantidadecount(resp.data.pageable.offset);
                        setpageNumber(0);

                        if (resp.data.content.length < 20) {
                            setDisabledNextButton(true);
                        } else {
                            setDisabledNextButton(false);
                        }

                    }
                })
                .catch(error => {

                    setTimeout(() => {
                        setloading(true);
                        //window.location.href = '/';
                    }, 3000);
                })
        }

        loadingchamado();

        async function quantidadeNotasemaberto() {
            const response = await axiosInstance.get(`/chamado/totalemaberto/${idusuario}`);
            settotalemaberto(response.data)
        }
        quantidadeNotasemaberto();
        async function quantidadeNotasemandamento() {
            const response = await axiosInstance.get(`/chamado/totalandamento/${idusuario}`);
            settotalemaandamento(response.data);
        }
        quantidadeNotasemandamento();


    }, [idusuario], [pagenumber], [counter]);

    async function AtribuirChamado(id) {
        window.location.href = "/paineldechamados/" + id;
    }





    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <div>
                    <div class="card-header">

                        <section class="content-header">
                            <h1><i class="fa fa-plus-square fa-1x cor-padrao"></i> Painel de Chamados
                                <small></small>
                            </h1>
                            <ol class="breadcrumb">
                                <li><i class="fa fa-home"></i> Portal JCR</li>
                                <li><i class="fa fa-wrench"></i> Sistema de Chamado</li>
                                <li class="active"><i class="fa fa-reorder"></i> Painel de Chamados</li>
                            </ol>
                        </section>



                    </div>
                    <div className='content'>

                        <div class="card ">
                            {!completed ? (
                                <>
                                    {!loading ? (
                                        <Dialog
                                            open={true}

                                            aria-describedby="alert-dialog-slide-description"

                                        >
                                            <div className="pos-center">
                                                <Spinner />
                                            </div>
                                        </Dialog>
                                    ) : (
                                        <Dialog
                                            open={true}

                                            aria-describedby="alert-dialog-slide-description"

                                        >
                                            <div className="pos-center">
                                                <Spinner />
                                            </div>
                                        </Dialog>
                                    )}
                                </>
                            ) : (
                                <>
                                    <div class="row">
                                        <div class="col-lg-6 col-xs-6">

                                            <div class="small-box bg-green">
                                                <div class="inner">
                                                    <div class="inner">
                                                        {situacaoChamado === true ?
                                                            <div>
                                                                <h3>
                                                                    0

                                                                </h3>
                                                                <p class="h3default">ABERTOS</p>
                                                            </div>
                                                            :
                                                            <div>
                                                                <h3 class="h3default">
                                                                    {totalemaberto}
                                                                </h3>
                                                                <p class="h3default">ABERTOS</p>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div class="icon">
                                                    <i class="fa fa-plus"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-xs-6">

                                            <div class="small-box bg-yellow">
                                                <div class="inner">

                                                    <div class="inner">
                                                        {situacaoChamado === true ?
                                                            <div>
                                                                <h3>
                                                                    0

                                                                </h3>
                                                                <p class="h3default">EM ANDAMENTO</p>
                                                            </div>
                                                            :
                                                            <div>
                                                                <h3 class="h3default">
                                                                    {totalemaandamento}
                                                                </h3>
                                                                <p class="h3default">EM ANDAMENTO</p>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div class="icon">
                                                    <i class="fa fa-pencil"></i>
                                                </div>
                                            </div>

                                        </div>





                                    </div>
                                    <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger "><div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" class="ui-panel-content ui-widget-content">
                                        <div class="box-header with-border ">
                                            <div class="form-horizontal">
                                                <div className='content'>

                                                    <div className="largurafixa table table-striped table-bordered table-resizable box-body card scrollverticalmente" >
                                                        {situacaoChamado === true ?
                                                            <div>
                                                                <h4 style={{ paddingLeft: '35%' }} >Nenhum Ticket Localizado!</h4>
                                                            </div>
                                                            :
                                                            <div>
                                                                <Table
                                                                    className=""
                                                                    stickyHeader
                                                                    aria-label="sticky table"
                                                                   
                                                                    ref={tableRef}
                                                                    onMouseDown={handleDragStart}
                                                                    onMouseMove={handleDragMove}
                                                                    onMouseUp={handleDragEnd}
                                                                >

                                                                    <TableHead >


                                                                        <TableRow>
                                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center"></TableCell>
                                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">Ticket</TableCell>
                                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">Situação</TableCell>
                                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">Prioridade</TableCell>
                                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">Respósavel</TableCell>



                                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">Titulo</TableCell>
                                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">Solicitante</TableCell>
                                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">Categoria</TableCell>
                                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">Data da Abertura</TableCell>


                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {chamado.map((row) => (
                                                                            <TableRow
                                                                                key={row.numero}
                                                                                sx={{ border: 100, width: 100 }}
                                                                            >
                                                                                <TableCell align="center" sx={{ paddingRight: '10px' }}>
                                                                                    <img
                                                                                        src={VisualizarIMG}
                                                                                        onClick={() => AtribuirChamado(row.id)}
                                                                                        width="15"
                                                                                        height="15"
                                                                                        style={{ cursor: 'pointer' }}
                                                                                    />
                                                                                </TableCell>

                                                                                <TableCell align="center">{row.id}</TableCell>
                                                                                <TableCell align="center"
                                                                                    style={row.statuschamado === 'ABERTO' ? styles.ticketaberto :
                                                                                        row.statuschamado === 'EM ANDAMENTO' ? styles.ticketemandamento :
                                                                                            row.statuschamado === 'FECHADO' ? styles.ticketfechado :
                                                                                                row.statuschamado === 'RESOLVIDO' ? styles.ticketresolvido :
                                                                                                    row.statuschamado === 'EMANDAMENTOREABERTO' ? styles.ticketreaberto : null}
                                                                                >{row.statuschamado}</TableCell>
                                                                                <TableCell align="center"
                                                                                    style={row.prioridadeenum === 'BAIXA' ? styles.pbaixa :
                                                                                        row.prioridadeenum === 'MEDIA' ? styles.pmedia :
                                                                                            row.prioridadeenum === 'ALTA' ? styles.palta : null}
                                                                                >{row.prioridadeenum}</TableCell>
                                                                                <TableCell align="center">{row.usuarioatendimento == null ? " " : row.usuarioatendimento.nomecomplemento} </TableCell>


                                                                                <TableCell align="center">{row.titulo}</TableCell>
                                                                                <TableCell align="center">{row.usuariosolicitantechamado.nomecomplemento}</TableCell>
                                                                                <TableCell align="center">{row.categoria}</TableCell>
                                                                                <TableCell align="center">{row.dataaberturachamado}</TableCell>

                                                                            </TableRow>

                                                                        ))}


                                                                    </TableBody>
                                                                </Table>
                                                            </div>
                                                        }




                                                    </div>
                                                    <div class="cardmov">
                                                        <TablePagination
                                                            colSpan={3}
                                                            component="div"
                                                            rowsPerPageOptions={[20]}
                                                            count={quantidadecount}
                                                            rowsPerPage={chamado.length}
                                                            page={counter}
                                                            ActionsComponent={TablePaginationActions}

                                                            labelDisplayedRows={({ from, to, count }) => (
                                                                <>
                                                                    <p><strong>Total de notas do período:       {totalElements}</strong></p>
                                                                    <div class="custom-label">
                                                                        <p><strong>Total de registros por página:        20</strong></p>
                                                                    </div>
                                                                </>
                                                            )}
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>

                                </>
                            )}
                        </div>

                    </div>
                </div >
            </div >
            <Footer />
        </div >
    )
}