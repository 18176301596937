import React, { useEffect, useState } from 'react';
import 'react-vertical-timeline-component/style.min.css';
import axiosInstance from '../../../config/api';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

function FluxoProjeto(props) {
  const { id } = props;

  const [timelineData, setTimelineData] = useState([]);
  const [nomeprojeto, setnomeprojeto] = useState('');
  const [fimdoprojeto, setfimdoprojeto] = useState('');

  var setorescrito = JSON.parse(localStorage.getItem('idsetor'));
  var setorprincipal = setorescrito.idsetor;



  useEffect(() => {
    async function loadingProjeto() {
      try {
        const response = await axiosInstance.get(`/projeto/${id}/${setorprincipal}`);
        console.log(response.data[0]);
        setnomeprojeto(response.data[0].nomeprojeto);
        if (response.data[0].fimdoprojeto !== null ) {
          setfimdoprojeto(response.data[0].fimdoprojeto);
        } else {
          setfimdoprojeto(null);
        }
      } catch (error) {
        console.error('Erro ao buscar os dados:', error);
      }
    }
    loadingProjeto();
  
    async function fetchData() {
      try {
        const response = await axiosInstance.get(`projeto/itemdetalhadoprojeto/itemprojeto/obteritemdetalhado/${id}`);
        setTimelineData(response.data);
      } catch (error) {
        console.error('Erro ao buscar os dados:', error);
      }
    }
  
    fetchData();
  
    const interval = setInterval(() => {
      fetchData();
    }, 5000);
  
    return () => {
      clearInterval(interval); // Limpa o intervalo quando o componente for desmontado
    };
    
  }, [id, setorprincipal]); // Inclua as dependências necessárias dentro do array de dependências
  

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = [...timelineData];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setTimelineData(items);
  };

  return (
    <div style={{ height: '500px', overflowY: 'auto' }} className='experience'>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="timeline" direction="vertical">
          {(provided) => (
            <VerticalTimeline lineColor='#000' {...provided.droppableProps} ref={provided.innerRef}>
              <VerticalTimelineElement
                iconStyle={{ background: '#000', color: '#fff' }}
                date='Inicio'>
                <p>Projeto : {nomeprojeto}</p>
              </VerticalTimelineElement>

              {timelineData.map((evento, index) => (
                <Draggable key={index} draggableId={`evento-${index}`} index={index}>
                  {(provided) => (
                    <VerticalTimelineElement
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="vertical-timeline-element--work"
                      date={evento.data}
                      iconStyle={{
                        background:
                          evento.ambienteenum === 'NAOINICIADA' ? 'rgba(160,7,7,0.767)' :
                            evento.ambienteenum === 'EMANDAMENTO' ? '#F39C12' :
                              evento.ambienteenum === 'FINALIZADA' ? '#00A65A' :
                                evento.ambienteenum === 'ENTREGUE' ? '#00C0EF'
                                  : null,
                        color: evento.ambienteenum === 'EM ANDAMENTO' ? '#fff' : '#fff'
                      }}
                      position="right"
                    >
                      <p>{'\nTarefa: ' + evento.nometarefadetalhada} </p>
                      <p> {'\n Status: ' + evento.ambienteenum} </p>
                      <p> {'\n Responsável: ' + evento.usuarioresponsavel === null ? ' ' : evento.usuarioresponsavel}</p>
                    </VerticalTimelineElement>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              {fimdoprojeto !== null ?

                <VerticalTimeline lineColor='#000' {...provided.droppableProps} ref={provided.innerRef}>
                  <VerticalTimelineElement
                    iconStyle={{ background: '#000', color: '#fff' }}
                    date='Fim'>
                    <p>{nomeprojeto}</p>
                  </VerticalTimelineElement>
                </VerticalTimeline>
                : null
              }
            </VerticalTimeline>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default FluxoProjeto;