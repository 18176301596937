import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '../../common/template/img/closebutton.png'
import React, { useState } from 'react'
import './login.css';



const style = {
  position: 'absolute',
  position: 'absolute',
  top: '52%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#ffffff',
  border: '2px solid #ffffff',
  boxShadow: 21,
  pt: 2,
  px: 4,
  pb: 3,

};

export default function LoginInvalido() {


  




  return (
    <div className='body'>
      <div className="container">
        <div className="screen">
          <Modal
            open="true"
            
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style, width: 400 }}>
              <h2 >Senha Invalida! <img src={CloseIcon}

                width="20" height="20" style={{ marginLeft: '1%' }}
              ></img></h2>

              <p></p>
              <p >
                <button type="button" class="btn btn-block btn-danger btn-lg" >Fechar</button>

              </p>

            </Box>
          </Modal>
        </div>
        <div className="footer navbar-fixed-bottom footerperson fontemaiscula">JCR Administração e Participações S/A <strong classNameName="fontcustom1"> 2023</strong></div>
      </div>

    </div>
  )
}