
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Card, Modal, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { Box } from '@mui/system';

import Footer from '../../../../common/template/footer';
import Header from '../../../../common/template/header';
import SideBar from '../../../../common/template/sideBar';
import axiosInstance from '../../../../config/api';

import AlterarIMG from '../../../../common/template/img/lapis.png';
import LixeiraIcon from '../../../../common/template/img/lixeira.png';
import CloseIcon from '../../../../common/template/img/closebutton.png';
import InfoIcon from '../../../../common/template/img/info.png'
import './categoriaglobal.css';


export default function CategoriaChamadoId() {
    const { id } = useParams();


    var netInt3 = JSON.parse(localStorage.getItem('idsetor'));
    var idusuariosetor = netInt3.idsetor;
    const [idusesubcategoria, setidusesubcategoria] = useState(id);
    const [nomedados, setsubNomeCategoria] = React.useState([]);
    const [subcategoria, setsubcategoria] = useState('');
    const [nomesubcategoria, setnomeSubcategoria] = useState('');
    const [nomesubcategoriaaux, setnomeSubcategoriaaux] = useState('');
    const [loading, setloading] = useState(false);
    const [alertConcluido, setAlertConcluido] = useState(false);
    const [alertDeletado, setalertDeletado] = useState(false);
    const [completed, setcompleted] = useState(false);
    const [alertanull, setAlertnull] = useState(false);
    const [nomecategoria, setnomecategoria] = useState([]);
    const [idSubcategoriaaux, setidSubcategoriaaux] = useState('');

    const [setor, setSetor] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            setloading(true);

            setTimeout(() => {
                setcompleted(true);
            }, 200);
        });
        async function listaCategoriachamado() {
            const response = await axiosInstance.get(`/categoriachamado/${idusuariosetor}?idcategoriat=${id}`)
            setnomecategoria(response.data[0].nomecategoria)
            setSetor(response.data[0].grupousuario.setor)


        }
        listaCategoriachamado();

        async function listarsubcategoriachamado() {
            const response = await axiosInstance.get("/subcategoriachamado/" + id)
            setsubNomeCategoria(response.data);
            setnomeSubcategoria(response.data.nomesubcategoria);



            if (response.data.length === 0) {
                setAlertnull(true);
            } else {
                setAlertnull(false);
            }


        }
        listarsubcategoriachamado();

    }, []);

    async function editcadastro() {
        const novoitemdatacrud = {
            nomesubcategoria: nomesubcategoriaaux,
            categoriadto: {
                id: idusesubcategoria
            }

        }
        const response = await axiosInstance.put(`/subcategoriachamado/${idSubcategoriaaux}`, novoitemdatacrud)
        setTimeout(() => { window.location.href = '/categoriachamadodswid=1912311/' + id; }, 2000);


    }

    async function edit() {

        const novoitemdatacrud = {
            nomesubcategoria: subcategoria,
            categoriadto: {
                id: idusesubcategoria
            }

        }
        const response = await axiosInstance.post('/subcategoriachamado/', novoitemdatacrud)
            .then(resp => {
                if (resp.status === 201) {

                    setTimeout(() => { setAlertConcluido(true); }, 1000);
                    handleCloseSucess();
                    setTimeout(() => { window.location.href = '/categoriachamadodswid=1912311/' + id; }, 2000);

                }
            })
            .catch(error => {


                if (error.response.status === 500) {
                    setOpen(true);
                }
            });


        ;




    }


    const style = {
        position: 'absolute',
        top: '50%',
        left: '52%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#ffffff',
        border: '2px solid #ffffff',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    const crudtabela = {
        position: 'absolute',
        top: '50%',
        left: '55%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: '#F8F8FF',
        border: '2px solid #F8F8FF',
        boxShadow: 50,
        pt: 10,
        px: 20,
        pb: 50,
    };



    const [openCrud, setOpenCrud] = React.useState(false);


    const handleOpenCrud = () => {
        setOpenCrud(true);
    };
    const handleCloseCrud = () => {
        setOpenCrud(false);
    };




    const [open, setOpen] = React.useState(false);


    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenSuccess = () => {
        setAlertConcluido(true);
    };
    const handleCloseSucess = () => {
        setAlertConcluido(false);
    };

    const handleCloseDeletado = () => {
        setalertDeletado(true);
    }


    async function voltar() {
        window.location.href = '/categoriachamadodswid=1912311'
    }

    async function deletarSubCategoria(id) {
        const response = await axiosInstance.delete("/subcategoriachamado/" + id)
            .then(resp => {
                setOpen(false);
                setalertDeletado(true);
                setTimeout(() => { window.location.href = '/categoriachamadodswid=1912311'; }, 2000);
            })
            .catch(error => {

                setOpen(true);
            })
    }

    async function modalsubCategoria(id) {
        setOpenCrud(true);
        const response = await axiosInstance.get("/subcategoriachamado/find/" + id)
        setnomeSubcategoriaaux(response.data.nomesubcategoria);
        setidSubcategoriaaux(response.data.id);

    }

    async function alterarcategoriachamado(id) {
        window.location.href = '/categoriachamadodswid=19123112/' + id;
    }

    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <div>
                    <div class="card-header">
                        <h3 class="card-title"> Cadastro Categoria Chamado

                        </h3>

                    </div>
                    <br></br>
                    <ol class="breadcrumb">
                        <li><i class="fa fa-home"></i> Portal JCR Cloud</li>
                        <li><i class="fa fa-file-text"></i> Sistema de Chamado</li>
                        <li><i class="fa fa-edit"></i> Configurações de Chamado</li>
                        <li><i class="fa fa-edit"></i> Cadastro Categoria Chamado</li>
                    </ol>
                    <div class="content">
                        <div class="ui-panel ui-widget box-danger">
                            <div class="col-sm-12 col-md-12 cantodireito">
                                <button type="button" class="btn btn-block btn-danger btn-lg"
                                    onClick={() => voltar()}>
                                    <i class="fa fa-chevron-left"></i> VOLTAR</button>
                            </div>
                        </div>
                        <br></br><br></br><br></br>
                        <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger" data-widget="widget_edicaoCategoriaChamadoForm_painelEdicao"><div id="edicaoCategoriaChamadoForm:painelEdicao_content" class="ui-panel-content ui-widget-content">

                            <div class="box-header with-border">
                                <h3 class="box-title">Categoria de Chamado</h3>
                            </div>
                            <div class="form-horizontal">
                                <div class="box-body">
                                    <div class="form-group">

                                        <div class="col-sm-2 col-md-2 control-label">
                                            <label for="nome">ID: *</label>
                                        </div>
                                        <div class="col-sm-10 col-md-10">
                                            <input
                                                type="text"
                                                value={id}
                                                disabled="disabled"

                                                class="form-control" />
                                        </div>

                                    </div>

                                    <div class="form-group">

                                        <div class="col-sm-2 col-md-2 control-label">
                                            <label for="nome">Nome: *</label>
                                        </div>
                                        <div class="col-sm-10 col-md-10">
                                            <input
                                                type="text"
                                                disabled="disabled"
                                                value={nomecategoria}

                                                class="form-control" />
                                        </div>

                                    </div>

                                    <div class="form-group">

                                        <div class="col-sm-2 col-md-2 control-label">
                                            <label for="nome">Setor: *</label>
                                        </div>

                                        <div class="col-sm-3 col-md-8">

                                            <input
                                                type="text"
                                                value={setor}
                                                disabled="disabled"
                                                class="form-control">

                                            </input>


                                        </div>
                                    </div>
                                    <br></br>
                                    <div class="form-group">
                                        <div class="col-sm-8 col-md-2">
                                            <button
                                                id="edicaoCategoriaChamadoForm:btn_adicionarSubcategoria"
                                                type="submit"
                                                role="button"
                                                onClick={() => alterarcategoriachamado(id)}
                                                class="ui-button-icon-left ui-icon ui-c fa fa-edit btn btn-block btn-warning btn-sm"
                                                aria-disabled="false"
                                                style={{ marginLeft: '550%' }} // Use porcentagem para tornar responsivo
                                            >
                                            </button>
                                        </div>
                                    </div>



                                    <div class="box-body">
                                        <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger">
                                            <div id="edicaoCategoriaChamadoForm:painelSubcategoria_content"
                                                class="ui-panel-content ui-widget-content">
                                                <div class="box-header with-border">
                                                    <h3 class="box-title">Subcategorias</h3>
                                                </div>
                                                <div class="form-horizontal">
                                                    <div class="box-body">
                                                        <div class="form-group">
                                                            <div class="col-sm-2 col-md-2 control-label">
                                                                <label for="nome">Subcategoria: *</label>
                                                            </div>

                                                            <div class="col-sm-3 col-md-8">

                                                                <input

                                                                    onChange={e => setsubcategoria(e.target.value)}
                                                                    type="text" class="form-control">

                                                                </input>




                                                            </div>

                                                            <div class="col-sm-10 col-md-2">
                                                                <button id="edicaoCategoriaChamadoForm:btn_adicionarSubcategoria"
                                                                    type="submit"
                                                                    role="button"
                                                                    onClick={() => edit()}
                                                                    class="ui-button-icon-left ui-icon ui-c fa fa-plus btn btn-block btn-success btn-sm"
                                                                    aria-disabled="false">
                                                                </button>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger"><div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" class="ui-panel-content ui-widget-content">
                                        <div class="box-header with-border">
                                            <div class="form-horizontal">
                                                <div className='content'>

                                                    <div class="box-body">

                                                        <Table className="table table-striped table-bordered table-resizable" data-toggle="tooltip" stickyHeader aria-label="sticky table">

                                                            <TableHead >



                                                                <TableRow>

                                                                    <TableCell sx={{ fontWeight: 'bold' }} align="center">Identificação</TableCell>
                                                                    <TableCell sx={{ fontWeight: 'bold' }} align="center">Sub Categoria</TableCell>
                                                                    <TableCell sx={{ fontWeight: 'bold' }} align="center">Alterar</TableCell>
                                                                    <TableCell sx={{ fontWeight: 'bold' }} align="center">Excluir</TableCell>

                                                                </TableRow>
                                                            </TableHead>
                                                            {alertanull == true ?
                                                                <div>
                                                                    <h4 style={{ paddingLeft: '80%' }} >Nenhuma SubCategoria Localizado!</h4>
                                                                </div>
                                                                :
                                                                <div>

                                                                </div>
                                                            }
                                                            <TableBody>

                                                                {nomedados.map((row) => (
                                                                    <TableRow
                                                                        key={row.id}
                                                                        sx={{ border: 100, width: 100 }}
                                                                    >
                                                                        <TableCell align="center">{row.id}</TableCell>
                                                                        <TableCell align="center">{row.nomesubcategoria}</TableCell>
                                                                        <TableCell align="center">
                                                                            <img src={AlterarIMG}
                                                                                onClick={() => modalsubCategoria(row.id)}
                                                                                width="20" height="20" style={{ marginLeft: '1%' }}
                                                                            >
                                                                            </img></TableCell>
                                                                        <TableCell align="center">
                                                                            <img src={LixeiraIcon}
                                                                                onClick={() => deletarSubCategoria(row.id)}
                                                                                width="20" height="20" style={{ marginLeft: '1%' }}>
                                                                            </img></TableCell>
                                                                    </TableRow>

                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                        <Modal
                                                            open={openCrud}
                                                            onClose={handleCloseCrud}
                                                            aria-labelledby="parent-modal-title"
                                                            aria-describedby="parent-modal-description"
                                                        >
                                                            <Box sx={{ ...crudtabela, width: '82%' }}>

                                                                <img src={CloseIcon}
                                                                    onClick={() => handleCloseCrud()}
                                                                    width="30" height="30" style={{ marginLeft: '105%', cursor: 'pointer' }}>
                                                                </img>
                                                                <h2 id="parent-modal-title " className="centralizar">Alterar SubCategoria </h2>
                                                                <p id="parent-modal-description">
                                                                    <div class="box-body">
                                                                        <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger">
                                                                            <div id="edicaoCategoriaChamadoForm:painelSubcategoria_content"
                                                                                class="ui-panel-content ui-widget-content">
                                                                                <div class="box-header with-border">
                                                                                    <div class="form-group">
                                                                                        <div class="col-sm-2 col-md-2 control-label">
                                                                                            <label for="nome">Id: *</label>
                                                                                        </div>

                                                                                        <div class="col-sm-12 col-md-1">

                                                                                            <input
                                                                                                value={idSubcategoriaaux}
                                                                                                disabled="disabled"
                                                                                                type="text" class="form-control" maxlength="80">

                                                                                            </input>

                                                                                        </div>


                                                                                    </div>
                                                                                    <br></br> <br></br>
                                                                                    <div class="form-group">
                                                                                        <div class="col-sm-2 col-md-2 control-label">
                                                                                            <label for="nome">Subcategoria: *</label>
                                                                                        </div>

                                                                                        <div class="col-sm-3 col-md-8">

                                                                                            <input
                                                                                                value={nomesubcategoriaaux}
                                                                                                onChange={e => setnomeSubcategoriaaux(e.target.value)}
                                                                                                type="text" class="form-control" maxlength="80">

                                                                                            </input>

                                                                                        </div>


                                                                                    </div>
                                                                                    <div className='quebrarlinha'></div>
                                                                                    <div>
                                                                                        <div class="col-sm-12 col-md-12 ">
                                                                                            <button type="button" class="btn btn-block btn-primary btn-lg"

                                                                                                onClick={() => editcadastro()}>
                                                                                                <i class="fa fa-save"  >
                                                                                                </i> SALVAR</button>
                                                                                            <div className='quebrarlinha2'></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </p>

                                                            </Box>
                                                        </Modal>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>


                            </div>


                        </div>
                        </div>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                        >
                            <Box sx={{ ...style, width: 400 }}>
                                <h2 id="parent-modal-title">Favor Preencher os Campos Obrigatorios! <img src={InfoIcon}

                                    width="20" height="20" style={{ marginLeft: '1%' }}
                                ></img></h2>
                                <p>Parametro SubCategoria Não Informado!</p>
                                <p></p>
                                <p id="parent-modal-description">
                                    <button type="button" class="btn btn-block btn-danger btn-lg" onClick={handleClose}>Voltar</button>

                                </p>

                            </Box>
                        </Modal>
                        <Modal
                            open={alertConcluido}
                            onClose={handleCloseSucess}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                        >
                            <Box sx={{ ...style, width: 400 }}>
                                <h2 id="parent-modal-title">Cadastrado com sucesso! <img src={InfoIcon}

                                    width="20" height="20" style={{ marginLeft: '1%' }}
                                ></img></h2>

                                <p></p>
                                <p id="parent-modal-description">
                                    <button type="button" class="btn btn-block btn-danger btn-lg" >Voltar</button>

                                </p>

                            </Box>
                        </Modal>

                        <Modal
                            open={alertDeletado}
                            onClose={handleCloseDeletado}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                        >
                            <Box sx={{ ...style, width: 400 }}>
                                <h2 id="parent-modal-title">Deletado com sucesso! <img src={InfoIcon}

                                    width="20" height="20" style={{ marginLeft: '1%' }}
                                ></img></h2>

                                <p></p>
                                <p id="parent-modal-description">
                                    <button type="button" class="btn btn-block btn-danger btn-lg" >Voltar</button>

                                </p>

                            </Box>
                        </Modal>

                    </div>
                </div>
            </div>


            <Footer />
        </div >
    )
}