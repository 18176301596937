
import { Dialog, Divider, IconButton, Stack, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Footer from '../../../common/template/footer';
import Header from '../../../common/template/header';
import SideBar from '../../../common/template/sideBar';
import axiosInstance from '../../../config/api';
import VisualizarIMG from '../../../common/template/img/visualizar.png'
import Spinner from '../../../common/template/Spinner';
import Box from '@mui/material/Box';

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '@material-ui/core/Checkbox';
import flatpickr from "flatpickr"
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { Portuguese } from "flatpickr/dist/l10n/pt"

import { useTheme } from '@mui/material/styles';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import './meuschamados.css';



export default function MeusChamados(props, myElem) {
    flatpickr(myElem, {
        "locale": Portuguese // locale for this instance only
    });

    const [chamado, setchamado] = useState([]);
    const [Ticket, setTicket] = useState('');
    const [situacaoChamado, setSituacaoChamado] = useState(false);
    const [buscar, setBuscar] = React.useState('');
    const [loading, setloading] = useState(false);
    const [completed, setcompleted] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [pagenumber, setpageNumber] = React.useState('');
    const [totalPaginas, setTotalpages] = React.useState('');
    const [DataInicial, setDataInicial] = React.useState('');
    const [DataFinal, setDataFinal] = React.useState('');

    const [hora1, setHora1] = React.useState(' 00:00:00');
    const [hora2, setHora2] = React.useState(' 23:59:59');

    const [StatusChamadoAberto, setStatusChamadoAberto] = React.useState('');
    const [StatusChamadoEmandamento, setStatusChamadoEmandamento] = React.useState('');
    const [busca, setBusca] = useState('');



    var newInt2 = JSON.parse(localStorage.getItem('id'));
    var idusuario = newInt2.id;

    const styles = {
        ticketaberto: {
            backgroundColor: '#00a65a',
            background: '#00a65a',
            color: '#ffffff',
        },

        ticketfechado: {
            backgroundColor: '#dd4b39',
            background: '#dd4b39',
            color: '#ffffff',
        },

        ticketemandamento: {
            backgroundColor: '#f39c12',
            background: '#f39c12',
            color: '#ffffff',
        },
        ticketresolvido: {
            backgroundColor: '#00c0ef',
            background: '#00c0ef',
            color: '#ffffff',
        },
        ticketreaberto: {
            backgroundColor: '#e03232',
        },
        palta: {
            backgroundColor: '#e03232',
            background: '#e03232',
            color: '#ffffff',
        },
        pmedia: {
            backgroundColor: '#ffbcbc',
            background: '#ffbcbc',
            color: 'black',
        },
        pbaixa: {
            backgroundColor: '#ffe6e6',
            background: '#ffe6e6',
            color: 'black',
        }

    };

    const handleChangePage = (event, newPage) => {
        setpageNumber(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setpageNumber(pagenumber++);
    };

    useEffect(() => {
        setTimeout(() => {
            setloading(true);

            setTimeout(() => {
                setcompleted(true);
            }, 300);
        });
        async function loadingchamado() {
            if (buscar !== null) {
                const response = await axiosInstance.get(`/chamado/paginado/solicitante/${idusuario}`)
                    .then(resp => {
                        setchamado(resp.data.content);
                        setpageNumber(resp.data.number)
                        setTotalpages(resp.data.totalPages);
                    })
                    .catch(error => {

                        setTimeout(() => {
                            setloading(true);
                            //window.location.href = '/';
                        }, 3000);
                    })
            }
        }

        loadingchamado();


    }, [Ticket]);

    const handleChangeValidationABERTO = (event) => {
        if (event.target.checked) {
            setValidaaberto(true);
            setStatusChamadoAberto('ABERTO');
        } else {
            setValidaaberto(false);
            setStatusChamadoAberto('');
        }


    };

    const handleChangeValidationEMANDAMENTO = (event) => {
        if (event.target.checked) {
            setabertovalidaandamento(true);
            setStatusChamadoEmandamento('FECHADO');
        } else {
            setabertovalidaandamento(false);
            setStatusChamadoEmandamento('');
        }



    };



    async function handleBuscar() {


        const response = await axiosInstance.get(`chamado/paginado/solicitante/${idusuario}?${Ticket !== '' ? `ticketchamado=${Ticket}` : ''}&datainicial=${DataInicial !== '' ? `${DataInicial}T00:00` : ''}&datafinal=${DataFinal !== '' ? `${DataFinal}T23:59` : ''}&listastatus=${StatusChamadoAberto}${StatusChamadoEmandamento !== '' ? `,${StatusChamadoEmandamento}` : ``}`)


        setchamado(response.data.content);

        if (response.data.totalElements === 0 || DataFinal < DataInicial) {
            setSituacaoChamado(true);
        } else {
            setSituacaoChamado(false);
        }



    }




    function TablePaginationActions() {
        const theme = useTheme();
        const { count, page, rowsPerPage } = props;


        async function handleBackButtonClick() {

            
            if (pagenumber === 0) {
                setpageNumber(pagenumber);
            } 
            const response = await axiosInstance.get(`/chamado/paginado/solicitante/${idusuario}?page=${pagenumber}`);
            setchamado(response.data.content);
        };

        async function handleNextButtonClick() {
            if (pagenumber === totalPaginas) {
                setpageNumber(pagenumber);
            } else {
                setpageNumber(pagenumber + 1);
            }
            const response = await axiosInstance.get(`/chamado/paginado/solicitante/${idusuario}?page=${pagenumber}`);
            setchamado(response.data.content);
        };



        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>

                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>

                <IconButton>
                    {pagenumber}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>

            </Box>
        );
    }

    async function refresh() {
        setTimeout(() => {
            setloading(true);
            //window.location.href = '/';
        }, 1);
        window.location.href = "/meuschamados"
    }




    const handleChange = event => {
        if (event.target.checked) {

        } else {

        }
        setIsSubscribed(current => !current);
    };



    const [abertovalida, setValidaaberto] = useState(false);
    const [validaandamento, setabertovalidaandamento] = useState(false);


    

    async function visualizar(id) {
        window.location.href = "paineldechamados/" + id;
    }

    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <div>
                    <div class="card-header">

                        <section class="content-header">
                            <h1><i class="fa fa-newspaper-o fa-1x cor-padrao"></i> Meus Chamados
                                <small></small>
                            </h1>
                            <ol class="breadcrumb">
                                <li><i class="fa fa-home"></i> Portal JCR</li>
                                <li><i class="fa fa-wrench"></i> Sistema de Chamado</li>
                                <li class="active"><i class="fa fa-newspaper-o"></i> Meus Chamados</li>

                            </ol>
                        </section>
                        <div className='content'>
                            <div class="card">
                            </div>
                            <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger">
                                <div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" class="ui-panel-content ui-widget-content">
                                    <div class="">
                                        <div class="form-horizontal">
                                            <div className='content'>
                                                <h4 sx={{ fontWeight: 'bold' }}>Consultar Chamados</h4>
                                                <Divider />
                                                <br></br>


                                                <div class="form-group">
                                                    <div class="col-sm-3 col-md-3 control-label">
                                                        <label for="selectPrioridade">Ticket Nº: </label>
                                                    </div>
                                                    <div class="col-sm-3 col-md-2 control-label">
                                                        <input id="chamadoForm:titulo"
                                                            type="text"
                                                            onChange={(ev) => setTicket(ev.target.value)}
                                                            value={Ticket}
                                                            name="chamadoForm:titulo" class="form-control" maxlength="100">
                                                        </input>
                                                    </div>

                                                </div>
                                                <div class="form-group">
                                                    <div class="form-group">

                                                        <div class="col-sm-6 col-md-3 control-label">
                                                            <label for="selectPrioridade">Status Chamado: </label>
                                                        </div>
                                                        <div class="col-sm-2 col-md-2 ">
                                                            <FormControl component="fieldset" >

                                                                <div class="col-sm-12">
                                                                    <FormGroup>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox checked={abertovalida}
                                                                                    value={StatusChamadoAberto}
                                                                                    onChange={handleChangeValidationABERTO}
                                                                                    name="ABERTO" />}
                                                                            label="Aberto"
                                                                        ></FormControlLabel>
                                                                    </FormGroup>
                                                                </div>
                                                            </FormControl>

                                                            <FormControl component="fieldset" >

                                                                <div class="col-sm-12">
                                                                    <FormGroup>
                                                                        <FormControlLabel
                                                                            control={<Checkbox checked={validaandamento}
                                                                                onChange={handleChangeValidationEMANDAMENTO}
                                                                                value={StatusChamadoEmandamento}
                                                                                name="FECHADO" />}
                                                                            label="Fechado"
                                                                        ></FormControlLabel>
                                                                    </FormGroup>
                                                                </div>
                                                            </FormControl>
                                                        </div>



                                                    </div>



                                                </div>




                                                <div class="form-group">
                                                    <div class="col-sm-3 col-md-3 control-label">
                                                        <label for="selectPrioridade">Período: </label>

                                                    </div>

                                                    <div class="form-group">


                                                        <div class="col-sm-3 col-md-3 control-label">
                                                            <Stack spacing={8}>
                                                                <input type="date"
                                                                    value={DataInicial}
                                                                    onChange={(ev) => setDataInicial(ev.target.value)}
                                                                    min="01/01/2022"
                                                                    max="01/01/2030"></input>
                                                            </Stack></div>
                                                        <div class="form-group">


                                                            <div class="col-sm-3 col-md-3 control-label">
                                                                <Stack spacing={8}>
                                                                    <input type="date"
                                                                        value={DataFinal}
                                                                        onChange={(ev) => setDataFinal(ev.target.value)}
                                                                        min="01/01/2022"
                                                                        max="01/01/2030"></input>


                                                                </Stack>
                                                            </div>



                                                        </div>


                                                    </div>



                                                    <div class="col-md-6 offset-md-7 moverlimparfiltro">
                                                        <button type="button"
                                                            onClick={() => refresh()}

                                                            class="btn  btn-primary col-sm-5 col-md-5 ">
                                                            <i class="fa fa-search"></i> Limpar Filtro</button>
                                                        <button type="button"
                                                            onClick={() => handleBuscar()}
                                                            class="btn btn-block btn-danger offset-md-1 col-sm-6 col-md-4">
                                                            <i class="fa fa-search"></i> Pesquisar</button>
                                                    </div>



                                                </div>




                                            </div>
                                            <Divider />
                                            <br></br>
                                            {!completed ? (
                                                <>
                                                    {!loading ? (
                                                        <Dialog
                                                            open={true}

                                                            aria-describedby="alert-dialog-slide-description"

                                                        >
                                                            <div className="pos-center">
                                                                <Spinner />
                                                            </div>
                                                        </Dialog>
                                                    ) : (
                                                        <Dialog
                                                            open={true}

                                                            aria-describedby="alert-dialog-slide-description"

                                                        >
                                                            <div className="pos-center">
                                                                <Spinner />
                                                            </div>
                                                        </Dialog>
                                                    )}
                                                </>
                                            ) : (
                                                <>


                                                    <div class="card ">
                                                        <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger "><div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" class="ui-panel-content ui-widget-content">
                                                            <div class="box-header with-border ">
                                                                <div class="form-horizontal">
                                                                    <div className='content'>

                                                                        <div class="table table-striped table-bordered table-resizable box-body card scrollverticalmente ">
                                                                            {situacaoChamado === true ?
                                                                                <div>
                                                                                    <h4 style={{ paddingLeft: '35%' }} >Nenhum Documento Localizado!</h4>
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    <Table className="table " stickyHeader aria-label="sticky table">

                                                                                        <TableHead >


                                                                                            <TableRow>
                                                                                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Visualizar</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Ticket Nº </TableCell>
                                                                                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Situação</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Data da Abertura</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Categoria</TableCell>

                                                                                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Titulo</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Solicitante</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Respósavel</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Prioridade</TableCell>

                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        <TableBody>
                                                                                            {chamado.map((row) => (
                                                                                                <TableRow
                                                                                                    key={row.numero}
                                                                                                    sx={{ border: 100, width: 10 }}
                                                                                                >
                                                                                                    <TableCell align="center"><img src={VisualizarIMG}
                                                                                                        onClick={() => visualizar(row.id)}
                                                                                                        width="15" height="15" style={{ marginLeft: '1%', cursor: 'pointer' }}
                                                                                                    >
                                                                                                    </img></TableCell>
                                                                                                    <TableCell align="center">{row.id}</TableCell>
                                                                                                    <TableCell align="center"
                                                                                                        style={row.statuschamado === 'ABERTO' ? styles.ticketaberto :
                                                                                                            row.statuschamado === 'EM ANDAMENTO' ? styles.ticketemandamento :
                                                                                                                row.statuschamado === 'FECHADO' ? styles.ticketfechado :
                                                                                                                    row.statuschamado === 'RESOLVIDO' ? styles.ticketresolvido :
                                                                                                                        row.statuschamado === 'EMANDAMENTOREABERTO' ? styles.ticketreaberto : null}
                                                                                                    >{row.statuschamado === "EMANDAMENTO" ? "EM ANDAMENTO" : row.statuschamado}</TableCell>
                                                                                                    <TableCell align="center">{row.dataaberturachamado}</TableCell>
                                                                                                    <TableCell align="center">{row.categoria}</TableCell>
                                                                                                    <TableCell align="center">{row.titulo}</TableCell>
                                                                                                    <TableCell align="center">{row.usuariosolicitantechamado.nomecomplemento}</TableCell>
                                                                                                    <TableCell align="center">{row.usuarioatendimento === null ? " " : row.usuarioatendimento.nomecomplemento} </TableCell>
                                                                                                    <TableCell align="center"
                                                                                                        style={row.prioridadeenum === 'BAIXA' ? styles.pbaixa :
                                                                                                            row.prioridadeenum === 'MEDIA' ? styles.pmedia :
                                                                                                                row.prioridadeenum === 'ALTA' ? styles.palta : null}
                                                                                                    >{row.prioridadeenum}</TableCell>
                                                                                                </TableRow>

                                                                                            ))}


                                                                                        </TableBody>
                                                                                    </Table>
                                                                                </div>
                                                                            }



                                                                        </div>
                                                                        <div class="cardmov">
                                                                            <TablePagination
                                                                                colSpan={3}
                                                                                component="div"
                                                                                rowsPerPageOptions={[20]}
                                                                                count={chamado.length}
                                                                                rowsPerPage={chamado.length}
                                                                                page={pagenumber}
                                                                                ActionsComponent={TablePaginationActions}

                                                                                onRowsPerPageChange={handleChangeRowsPerPage}

                                                                            />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>

                                                    </div>

                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>




                </div >
            </div >
            <Footer />
        </div >
    )
}