import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '../../common/template/img/closebutton.png'
import React, { useState } from 'react'
import './login.css';

import logoJCR from '../../common/template/img/logo-jcr.png';


import { API_BASE_URL } from '../config/configfetch';

const style = {
  position: 'absolute',
  position: 'absolute',
  top: '52%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#ffffff',
  border: '2px solid #ffffff',
  boxShadow: 21,
  pt: 2,
  px: 4,
  pb: 3,

};

export default function Login() {
  const [loginValid, setLoginValid] = useState(true);
  const [senhaValid, setSenhaValid] = useState(true);

  const [logindto, setlogindto] = useState('');
  const [senhadto, setsenhadto] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [usuarioexiste, setusuarioexiste] = useState(false);
  const [usuarioexisteaux, setusuarioexisteaux] = useState(false);

  const [message, setMessage] = useState('');


  const handleCloseusuarioexiste = () => {
    setusuarioexiste(false);
  }

  const handleCloseusuarioexisteaux = () => {
    setusuarioexisteaux(false);
  }

  function onClickButton(event) {
    event.preventDefault();
  }

  async function signIn() {
    if (!logindto && !senhadto) {
      setLoginValid(false);
      setSenhaValid(false);
      setusuarioexiste(true);
      return;
    } else if (logindto && !senhadto) {
      setLoginValid(true);
      setSenhaValid(false);
      setusuarioexiste(true);
      return;
    }

    try {
      const crudinsertuser = {
        login: logindto,
        senha: senhadto,
      }

      const requestInfo = {
        method: 'POST',
        body: JSON.stringify(crudinsertuser),
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
      };

      const response = await fetch(`${API_BASE_URL}login/authenticate`, requestInfo);


      const result = await response.json();
      if (result.jwttoken) {
        localStorage.setItem('jwttoken', JSON.stringify({ jwttoken: result.jwttoken }));
        localStorage.setItem('id', JSON.stringify({ id: result.id }));
        localStorage.setItem('usuario', JSON.stringify({ username: result.username }));
        localStorage.setItem('setor', JSON.stringify({ setor: result.setor }));
        localStorage.setItem('idsetor', JSON.stringify({ idsetor: result.idsetor }));
        localStorage.setItem('nomecomplemento', JSON.stringify({ nomecomplemento: result.nomecomplemento }));
        localStorage.setItem('coligada', JSON.stringify({ coligada: result.coligada }));
        localStorage.setItem('chapa', JSON.stringify({ coligada: result.chapa }));

        window.location.href = "/home";
      } else if (result.status === 401 || result.status === "401") {
        setusuarioexiste(true);
      }
    } catch (error) {
      if (error.code === 401) {
        setusuarioexiste(true);
      } else if (error.code === 'ERR_CONNECTION_REFUSED') {
        setusuarioexisteaux(true);
        setMessage('Falha de comunicação com o servidor de banco de dados. Erro 400');
      } else if (error.code === 'Failed to fetch') {
        setMessage('Falha de comunicação com o servidor de banco de dados. Erro 400');
        setusuarioexisteaux(true);
      } else {
        setMessage(error.message);
        setusuarioexisteaux(true);
      }
    }
  }




  return (
    <div className='body'>
      <div className="container">
        <div className="screen">
          <div className="screen__content">
            <div className='personalizadologo'>
              <img src={logoJCR} width="100" height="100" />
            </div>
            <form className="login" onSubmit={e => e.preventDefault()}>
              <div className="login__field">
                <i className="login__icon fa fa-user fa-lg x-bigger"></i>
                <input type="text" className={`login__input ${(!loginValid && !senhaValid) ? 'invalid' : ''}`} placeholder={(!loginValid && !senhaValid) ? 'Campo obrigatório*' : 'Login: *'} onChange={es => { setlogindto(es.target.value); setLoginValid(true); }} />
              </div>
              <div className="login__field">
                <i className="login__icon fa fa-lock fa-lg x-bigger"></i>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className={`login__input ${(!loginValid && !senhaValid) ? 'invalid' : ''}`}
                  placeholder={(!loginValid && !senhaValid) ? 'Campo obrigatório*' : 'Senha'}
                  onChange={es => { setsenhadto(es.target.value); setSenhaValid(true); }}
                />
                <button
                  type="button"
                  className="login__show-password-button"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ background: '#6F1C21', border: 'none', color: "#ffffff" }}
                >
                  {showPassword ? (
                    <i className="fa fa-eye-slash fa-lg" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-eye fa-lg" aria-hidden="true"></i>
                  )}
                </button>
              </div>
              <button className="button login__submit" type="submit" onClick={() => signIn()}>
                <span className="button__text">ENTRAR</span>
                <i className="button__icon fa fa-chevron-right fa-lg x-bigger"></i>
              </button>
              <br></br>
              {/*
              <small id="pwd-icon" tooltip-placement="left" tooltip="Forgot Password;" style={{ textAlign: 'center' }}>
                <a href="/forgotPassword" target="_blank">
                  Esqueceu sua senha?
                </a>
              </small>
             */}

            </form>

          </div>
          <div className="screen__background">
            <span className="screen__background__shape screen__background__shape4"></span>
            <span className="screen__background__shape screen__background__shape3"></span>
            <span className="screen__background__shape screen__background__shape2"></span>
            <span className="screen__background__shape screen__background__shape1"></span>
          </div>
          <Modal
            open={usuarioexiste}
            onClose={handleCloseusuarioexiste}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style, width: 400 }}>
              <img src={CloseIcon} onClick={handleCloseusuarioexiste}

                width="30" height="30" style={{ marginLeft: '100%', cursor: 'pointer' }}
              ></img>
              <h2 >Usuário ou Senha inválidos!!! </h2>




            </Box>
          </Modal>

          <Modal
            open={usuarioexisteaux}
            onClose={handleCloseusuarioexisteaux}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style, width: 400 }}>
              <img src={CloseIcon} onClick={handleCloseusuarioexisteaux} width="30" height="30" style={{ marginLeft: '100%', cursor: 'pointer' }}></img>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h4 style={{ textAlign: 'center' }}>Falha de Comunicação com servidor de Banco de dados!!!</h4>
                <p style={{ textAlign: 'center', fontWeight: 'bold' }}>{message}</p>
              </div>
            </Box>
          </Modal>
        </div>
        <div className="footer navbar-fixed-bottom footerperson ">
          <strong className="fontcustom1 fontemaiscula">JCR Administração e Participações S/A  2024 - </strong>
          <strong className="fontcustom1 fontemaiscula">  </strong>
          <strong > Version: 1.04.02 </strong>
        </div>
      </div>

    </div>
  )
}