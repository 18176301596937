import React, { useEffect, useState } from 'react';
import Footer from '../../../common/template/footer';
import Header from '../../../common/template/header';
import SideBar from '../../../common/template/sideBar';
import allowedExtensions from '../../../common/constants/utils/allowextensions';
import LixeiraIMG from "../../../common/template/img/lixeira.png";

import { API_BASE_URL } from '../../config/configfetch';
import { Box } from '@mui/system';
import {
    TableCell,
    TableHead,
    TableRow,
    Table,
    Dialog,
    Modal,
} from "@mui/material";
import axiosInstance from '../../../config/api';
import id from 'date-fns/esm/locale/id/index.js';
import Spinner from '../../../common/template/Spinner';

import SucessIcon from '../../../common/template/img/success.png'

export default function NovaPublicacao() {
    const [draggingx, setDraggingx] = useState(false);
    const [alertConcluido, setAlertConcluido] = useState(false);

    const [AlertaFormato, setAlertaFormato] = useState(false);
    const [droppedFiles, setDroppedFiles] = useState([]);
    const [totalSize, setTotalSize] = useState(0);
    const [loading, setLoading] = useState(false);

    const [publicacao, setPublicacao] = useState([]);

    const localStorageData = localStorage.getItem("idsetor");


    const parsedData = JSON.parse(localStorageData);


    const idSetor = parsedData.idsetor;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '52%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#ffffff',
        border: '2px solid #ffffff',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,


    };

    const handleClose = () => {
        setAlertConcluido(false);
    };

    useEffect(() => {
        async function loadingPublicacao() {
            const response = await axiosInstance.get(`publicacaopost/${idSetor}`);
            setPublicacao(response.data.content);
        }
        loadingPublicacao();
    }, [publicacao]);


    const handleFileInput = (e) => {
        const files = Array.from(e.target.files);

        const invalidFiles = files.filter(file => {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            return !allowedExtensions.includes(fileExtension);
        });

        if (invalidFiles.length > 0) {
            setAlertaFormato(true);
            return;
        }

        handleFiles(files);

        e.target.value = null;
    };

    const handleFiles = (files) => {
        const newFiles = [...droppedFiles, ...files];
        setDroppedFiles(newFiles);
        setTotalSize(newFiles.reduce((total, file) => total + file.size, 0));
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        setDraggingx(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDraggingx(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setDraggingx(true);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDraggingx(false);
        const files = Array.from(e.dataTransfer.files);
        handleFiles(files);
    };

    function formatBytes(bytes) {
        return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
    }

    const handleRemoveFile = (index) => {
        const newFiles = [...droppedFiles];
        const removedFile = newFiles.splice(index, 1)[0];
        setDroppedFiles(newFiles);
        setTotalSize(totalSize - removedFile.size);
    };

    async function loadingPublicacaoAuxiliar() {
        const response = await axiosInstance.get(`publicacaopost/${idSetor}`);
        setPublicacao(response.data.content);
    }
    async function novapublicacao() {

        setLoading(true);
        const token = JSON.parse(localStorage.getItem('jwttoken'))?.jwttoken;

        const formData = new FormData();

        const crudinsert = {

            grupousuarioid: { id: idSetor },
        };
        formData.append('anexojson', new Blob([JSON.stringify(crudinsert)], { type: 'application/json' }));
        droppedFiles.forEach((file, index) => {
            const fileExtension = file.name.split('.').pop().toLowerCase();

            if (!allowedExtensions.includes(fileExtension)) {
                alert(`Extensão de arquivo não permitida: ${file.name}`);
                setLoading(false);
                throw new Error('Extensão de arquivo não permitida.');
            }

            formData.append('anexo', file, file.name);
        });

        const response = await fetch(`${API_BASE_URL}publicacaopost`, {
            method: 'POST',

            body: formData,
            headers: {
                'Authorization': `Bearer ${token}`,

            },
        });


        if (response && (response.status === 201 || response.status === '201')) {

            setAlertConcluido(true);
           
            setDroppedFiles([]);
           

         
            setTimeout(() => {
                setAlertConcluido(false);
            }, 1000);

            setTimeout(() => {
                setLoading(false);
                loadingPublicacaoAuxiliar();
            }, 1000);

            loadingPublicacaoAuxiliar();
        } else {
            console.log(response)
            loadingPublicacaoAuxiliar();
            setDroppedFiles([]);
            setLoading(false);
            alert('Falha na postagem da imagem');
        }


    }

    async function deletarpublicacao(idaux) {
        try {
            const response = await axiosInstance.delete(`/publicacaopost/${idaux}`);

            if (response.status === 200 || response.status === 204) {

                loadingPublicacaoAuxiliar();
            } else {

                console.error('Erro ao excluir publicação:', response.status);
            }
        } catch (error) {

            console.error('Erro ao excluir publicação:', error);
        }

    }

    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className="content-wrapper">
                <div>
                    <div class="card-header">
                        <section class="content-header">
                            <h1>
                                <i class="fa fa-plus-square fa-1x cor-padrao"></i> Publicação
                                <small></small>
                            </h1>
                            <ol class="breadcrumb">
                                <li>
                                    <i class="fa fa-home"></i> Portal JCR
                                </li>
                                <li>
                                    <i class="fa fa-newspaper-o"></i> Publicação
                                </li>
                                <li class="active">
                                    <i class="fa fa-plus"></i> Novo Projeto
                                </li>
                            </ol>
                        </section>
                    </div>
                </div>

                <div className="content-wrapper content pl-1">

                    <div className="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger">
                        <div className="card">
                            <br />
                            <div className="row">
                                <div className="col-sm-2 col-md-3 col-xs-12 control-label text-center">
                                    <label htmlFor="fileInput" className="btn btn-block btn-danger btn-lg custom-button ml-sm-2">
                                        <i className="fa fa-upload"></i> Selecionar arquivos para Anexar
                                    </label>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        style={{ display: 'none' }}
                                        onChange={handleFileInput}
                                        multiple
                                    />
                                    <br />


                                </div>

                                <div onClick={() => novapublicacao()} className="col-sm-2 col-md-3 col-xs-12 control-label text-center">
                                    <label className="btn btn-block btn-success btn-lg custom-button">
                                        <i className="fa fa-check"> </i>
                                        Publicar
                                    </label>
                                </div>
                            </div>




                            <div
                                className={`drop-container ${draggingx ? 'draggingx' : ''}`}
                                onDragEnter={handleDragEnter}
                                onDragLeave={handleDragLeave}
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                            >
                                {droppedFiles.length > 0 ? (
                                    <div>
                                        <p>Arquivos no container:</p>
                                        <ul>
                                            {droppedFiles.map((file, index) => (
                                                <li key={index}>
                                                    {file.name} ({formatBytes(file.size)}){' '}
                                                    <span className="close-icon" onClick={() => handleRemoveFile(index)}>
                                                        &#x2716;
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                        <p>Tamanho total: {formatBytes(totalSize)}</p>
                                    </div>
                                ) : (
                                    <p>ou arraste e solte os arquivos aqui (limite: 20 MB) </p>
                                )}
                            </div>


                        </div>



                    </div>

                    <>
                        {loading ? (
                            <Dialog open={true} aria-describedby="alert-dialog-slide-description">
                                <div className="pos-center">
                                    <Spinner />
                                </div>
                            </Dialog>
                        ) : (
                            <Dialog open={false} aria-describedby="alert-dialog-slide-description">
                                <div className="pos-center">

                                </div>
                            </Dialog>
                        )}
                    </>
                    <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger "><div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" class="ui-panel-content ui-widget-content">
                        <div class="box-header with-border ">
                            <div class="form-horizontal">
                                <div className='content'>

                                    <div className="">
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: "bold" }} align="center">Visualizar Imagem</TableCell>
                                                    <TableCell sx={{ fontWeight: "bold" }} align="center">Nome do Arquivo</TableCell>
                                                    <TableCell sx={{ fontWeight: "bold" }} align="center">Deletar Publicação</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <tbody>

                                                {publicacao.length === 0 ? (
                                                    <TableRow>
                                                        <TableCell colSpan={3} align="center">Nenhum registro encontrado.</TableCell>
                                                    </TableRow>
                                                ) : publicacao.map((registro) => (
                                                    <TableRow key={registro.id}>
                                                        <TableCell align="center">
                                                            {registro.conteudoAnexo && (
                                                                <img
                                                                    src={`data:image/png;base64,${registro.conteudoAnexo}`}
                                                                    alt="Imagem"
                                                                    style={{ maxWidth: '120px', maxHeight: '120px' }}
                                                                />
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center">{registro.nomearquivo}</TableCell>
                                                        <TableCell align="center"><img
                                                            src={LixeiraIMG}
                                                            onClick={() => deletarpublicacao(registro.id)}
                                                            width="25"
                                                            height="25"
                                                            style={{ cursor: "pointer" }}
                                                        /></TableCell>
                                                    </TableRow>
                                                ))}

                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <Modal
                            open={alertConcluido}
                            onClose={handleClose}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                        >
                            <Box sx={{ ...style, width: 500 }}>
                                <h2 ><img
                                    src={SucessIcon}
                                    width="30"
                                    height="30" >
                                </img> Publicação Cadastrado com Sucesso !</h2>
                            </Box>
                        </Modal>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    );
}
