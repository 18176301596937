import { Alert, Divider, Modal, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import Footer from '../../../common/template/footer';
import Header from '../../../common/template/header';
import SideBar from '../../../common/template/sideBar';
import axiosInstance from '../../../config/api';

import axios, { AxiosError, AxiosResponse } from "axios";

import InfoIcon from '../../../common/template/img/info.png'
import Success from '../../../common/template/img/success.png'
import Spinner from '../../../common/template/Spinner';


export default function NovoChamado() {
    const [open, setOpen] = React.useState(false);
    const [openAnexo, setOpenAnexo] = React.useState(false);

    const [alertstate, setAlertstate] = useState([]);
    const [alertConcluido, setAlertConcluido] = useState(false);

    const [alertWarning, setalertWarning] = React.useState(false);
    const [loading, setloading] = useState(false);
    const [prioridade, setPrioridade] = useState('');
    const [tituloc, setTitulo] = useState('');
    const [descricao, setDescricao] = useState('');
    const [categoriadto, setCategoriaChamadodto] = useState('');
    const [subcategoriadto, setsubcategoriadto] = useState('');
    const [statuschamado, setstatuschamado] = useState('ABERTO');
    const [gruposetor, setGruposetor] = useState(0);
    const [Grupochamado, setGrupochamado] = useState([]);
    const [subcategoria, setsubcategoria] = useState([]);

    const [Coligada, setColigada] = React.useState([]);

    const [ComboColigadasFornecedor, setComboColigadasFornecedor] = React.useState([]);


    var newInt = JSON.parse(localStorage.getItem('id'));

    var newIntGrupo = JSON.parse(localStorage.getItem('idsetor'));


    var grupoID = newIntGrupo.idsetor;



    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseWarning = () => {
        setalertWarning(false);
    }

    const handleOpenWarning = () => {
        setalertWarning(true);
    }

    const handleOpenAnexo = () => {
        setOpenAnexo(true);
        setOpen(false);
    };
    const handleCloseAnexo = () => {
        setOpenAnexo(false);
        setOpen(true);
    };





    useEffect(() => {


        async function loadingchamado() {

            const response = await axiosInstance.get("/chamado/grupousuarios?ids=1,2")

                .then(resp => {
                    setGrupochamado(resp.data);
                }).catch(error => {
                    setloading(true);
                    setTimeout(() => { window.location.href = '/'; }, 2000);
                });





        }

        loadingchamado();

        async function nomefantasiacoligada() {
            const response = await axiosInstance.get('/coligadas');
            setComboColigadasFornecedor(response.data);
        }


        nomefantasiacoligada();

        async function loadingSubcategoria() {

            const response = await axiosInstance.get(`/categoriachamado/${gruposetor}`)

                .then(resp => {
                    setsubcategoria(resp.data);

                }).catch(error => {
                    setloading(true);
                    setTimeout(() => { window.location.href = '/'; }, 2000);
                });




        }
        loadingSubcategoria();


    }, [gruposetor]);


    const style = {
        position: 'absolute',
        top: '50%',
        left: '52%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#ffffff',
        border: '2px solid #ffffff',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,


    };

    const loadingcss = {
        position: 'absolute',
        top: '50%',
        left: '52%'
    }

    const handleCloseDeletado = () => {
        setAlertstate(true);
    }



    async function validacao() {
        setAlertConcluido(true);
        setTimeout(() => { window.location.href = '/paineldechamados'; }, 1500);
    }
    async function validacao2() {
        setloading(false);
        setalertWarning(true);
        setTimeout(() => { setalertWarning(false); }, 1500);
    }
    async function novoticketchamado() {
        handleClose(false);
        if (!prioridade || !gruposetor || !categoriadto || !subcategoria || !tituloc || !descricao || !newInt || !statuschamado) {
            console.log("Campos obrigatórios não preenchidos.");
            setloading(false);
            validacao2();
            return;
        }
        const crudinsertnovoticket = {
            prioridadeenum: prioridade,
            grupousuario: gruposetor,
            categoria: categoriadto,
            subcategoria: subcategoriadto,
            titulo: tituloc,
            descricao: descricao,
            usuariosolicitantechamado: newInt,
            grupousuario: { id: gruposetor },
            statuschamado: statuschamado,
            coligada: categoriadto === "RM" ? Coligada : "",
        };
        try {
            const response = await axiosInstance.post('/chamado/novochamado', crudinsertnovoticket);
            if (response.status === 201) {
                validacao();
            } else {
                validacao2();
            }
        } catch (error) {
            if (error.response && error.response.status) {
                validacao2();
            } else {
                console.log("Erro na requisição:", error);
            }
        }
    }
    async function voltar() {
        window.location.href = '/home';
    }



    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <div>
                    <div class="card-header">

                        <section class="content-header">
                            <h1><i class="fa fa-plus-square fa-1x cor-padrao"></i> Novo Chamado
                                <small></small>
                            </h1>
                            <ol class="breadcrumb">
                                <li><i class="fa fa-home"></i> Portal JCR</li>
                                <li><i class="fa fa-wrench"></i> Sistema de Chamado</li>
                                <li class="active"><i class="fa fa-ticket"></i> Novo Chamado</li>
                            </ol>
                        </section>



                    </div>
                    <div className='content'>
                        <div class="card">
                        </div>
                        <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger"><div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" class="ui-panel-content ui-widget-content">
                            <div class="box-header with-border">
                                <div class="form-horizontal">
                                    <div className='content'>

                                        <div class="box-body">
                                            <h4>Chamado</h4>

                                            <Divider />
                                            <br></br>
                                            <div class="form-group">
                                                <div class="col-sm-12 col-md-3 control-label">
                                                    <label for="selectPrioridade">Prioridade: *</label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 control-label">
                                                    <select style={{ height: '35px' }}
                                                        id="prioridades"
                                                        onChange={e => setPrioridade(e.target.value)}
                                                        class="form-control"
                                                    >
                                                        <option value=''>Selecione*</option>
                                                        <option value="ALTA">ALTA</option>
                                                        <option value="MEDIA">MÉDIA</option>
                                                        <option value="BAIXA">BAIXA</option>
                                                    </select>
                                                </div>

                                            </div>
                                            {loading === true ? (
                                                <div className="">
                                                    <Modal
                                                        open={loading}
                                                        aria-labelledby="parent-modal-title"
                                                        aria-describedby="parent-modal-description"
                                                    >
                                                        <Box sx={{ ...loadingcss, width: 400 }}>
                                                            <Spinner />
                                                        </Box>
                                                    </Modal>
                                                </div>
                                            ) : (
                                                <div>

                                                </div>
                                            )}


                                            <div class="form-group">
                                                <div class="col-sm-12 col-md-3 control-label">
                                                    <label for="selectPrioridade">Localização do Chamado: *</label>
                                                </div>
                                                <div class="col-sm-12 col-md-3 control-label">
                                                    <select style={{ height: '35px' }}
                                                        class="form-control"
                                                        id="teste"
                                                        onChange={es => setGruposetor(es.target.value)}
                                                    >
                                                        <option value=''>Selecione*</option>
                                                        {Grupochamado.map((item, index) => (
                                                            <option value={item.id} key={index}

                                                            >{item.setor}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                            </div>






                                            <div class="form-group">
                                                <div class="col-sm-12 col-md-3 control-label">
                                                    <label for="selectPrioridade">Sub- Categoria: *</label>
                                                </div>
                                                <div class="col-sm-12 col-md-3 control-label" >
                                                    <select style={{ height: '35px' }}
                                                        class="form-control"

                                                        onChange={(es) => {
                                                            const select = es.target;
                                                            const optgroup = select.options[select.selectedIndex].parentElement;
                                                            setsubcategoriadto(select.value);
                                                            setCategoriaChamadodto(optgroup.label);
                                                        }}
                                                    >
                                                        <option> Selecione</option>

                                                        {subcategoria.map((e, key) => {
                                                            return (
                                                                <optgroup key={key} label={e.nomecategoria} >
                                                                    {subcategoria.filter(ea => e.id === ea.id)

                                                                        .map(option =>
                                                                            option.subcategoria.map((option2, i) => {

                                                                                return (
                                                                                    <option key={i} value={option2.nomesubcategoria}

                                                                                    >

                                                                                        {e.nomecategoria + "->" + option2.nomesubcategoria}
                                                                                    </option>

                                                                                )
                                                                            }))}

                                                                </optgroup>
                                                            )
                                                        })}

                                                    </select>
                                                </div>


                                            </div>


                                            {categoriadto === 'RM' && (
                                                <div className="form-group">
                                                    <div className="col-sm-12 col-md-3 control-label">
                                                        <label htmlFor="selectColigada">Coligada: *</label>
                                                    </div>
                                                    <div className="col-sm-12 col-md-3 control-label">
                                                        <select selected="selected" class="form-control select2 select2-hidden-accessible" multiple=""
                                                            style={{ height: 35 }} onChange={(ev) => setColigada(ev.target.value)}>
                                                            <option value="">Selecione</option>
                                                            {ComboColigadasFornecedor.map((row) => (

                                                                <option value={row.nomecategoria}>{row.nomefantasia}</option>

                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            )}





                                            <div class="form-group">
                                                <div class="col-sm-12 col-md-3 control-label">
                                                    <label for="selectPrioridade">Titulo do Chamado: *</label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 control-label">
                                                    <input id="chamadoForm:titulo" type="text"
                                                        onChange={e => setTitulo(e.target.value)}
                                                        maxlength="60"
                                                        name="chamadoForm:titulo" class="form-control">
                                                    </input>
                                                </div>

                                            </div>
                                            <div class="form-group">
                                                <div class="col-sm-12 col-md-3 control-label">
                                                    <label for="selectPrioridade">Descrição do Chamado: *</label>

                                                </div>
                                                <div class="col-sm-12 col-md-8 control-label ">

                                                    <textarea
                                                        onChange={e => setDescricao(e.target.value)}
                                                        oninput="countText()"

                                                        maxlength="1000"
                                                        name="chamadoForm:descricao" class="form-control" rows="5"></textarea>
                                                </div>

                                            </div>
                                            <div class="col-sm-12 col-md-10 control-label ">
                                                <label for="characters">Quant de caracteres descrição: </label><span id="characters"> {descricao.length} </span>
                                            </div>
                                        </div>
                                        <br></br>
                                        <Divider />
                                        <br></br>
                                        <div class="col-sm-12 col-md-6">
                                            <button type="button" class="btn btn-block btn-danger btn-lg"
                                                onClick={() => voltar()}
                                            >
                                                <i class="fa fa-chevron-left"></i> VOLTAR</button>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <button type="button" onClick={handleOpen} class="btn btn-block btn-primary btn-lg"

                                            >
                                                <i class="fa fa-floppy-o"></i> Salvar</button>
                                            <br></br>
                                        </div>
                                        <Modal
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="parent-modal-title"
                                            aria-describedby="parent-modal-description"
                                        >
                                            <Box sx={{ ...style, width: 400 }}>
                                                <h2 id="parent-modal-title">Você deseja Abrir esse chamado?</h2>
                                                <p></p>
                                                <p id="parent-modal-description">
                                                    <div class="col-sm-12 col-md-6 control-label">
                                                        <button type="button" class="btn btn-block btn-danger btn-lg" onClick={handleClose}>Não</button>
                                                    </div>
                                                    <div class="col-sm-12 col-md-6 control-label">
                                                        <button type="button" class="btn btn-block btn-primary btn-lg" onClick={() => {
                                                            setloading(true); // Ativa o indicador de carregamento
                                                            novoticketchamado();
                                                        }}>Sim</button>
                                                    </div>

                                                </p>

                                            </Box>
                                        </Modal>

                                        <Modal
                                            open={openAnexo}
                                            onClose={handleCloseAnexo}
                                            aria-labelledby="parent-modal-title"
                                            aria-describedby="parent-modal-description"
                                        >
                                            <Box sx={{ ...style, width: 400 }}>
                                                <h2 id="parent-modal-title">Adicione um Anexo</h2>
                                                <div>
                                                    <Table className="table table-striped table-bordered table-resizable" data-toggle="tooltip" stickyHeader aria-label="sticky table">

                                                        <TableHead >


                                                            <TableRow>

                                                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Anexo</TableCell>
                                                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Excluir</TableCell>


                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>

                                                        </TableBody>
                                                    </Table>
                                                </div>

                                                <div class="col-sm-3 col-md-6 control-label">
                                                    <button type="button" class="btn btn-block btn-danger btn-lg" onClick={handleCloseAnexo}>Voltar</button>
                                                </div>

                                                <div class="col-sm-3 col-md-6 control-label">
                                                    <button type="button" class="btn btn-block btn-primary btn-lg" >Salvar</button>
                                                </div>

                                            </Box>
                                        </Modal>
                                    </div>

                                </div>

                            </div>
                        </div>
                        </div>
                        <Modal
                            open={alertConcluido}
                            onClose={handleClose}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                        >
                            <Box sx={{ ...style, width: 500 }}>
                                <h2 ><img
                                    src={Success}
                                    width="30"
                                    height="30" >
                                </img> Ticket Cadastrado com Sucesso !</h2>
                            </Box>
                        </Modal>

                        <Modal
                            open={alertWarning}
                            onClose={handleCloseWarning}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                        >
                            <Box sx={{ ...style, width: 400 }}>
                                <h2 >Favor Preencher os Campos Necessários! <img src={InfoIcon}

                                    width="20" height="20" style={{ marginLeft: '1%' }}
                                ></img></h2>

                                <p></p>
                                <p >
                                    <button type="button" class="btn btn-block btn-danger btn-lg" onClick={handleCloseWarning}>Fechar</button>

                                </p>

                            </Box>
                        </Modal>


                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}