import React, { useEffect, useState } from "react";
import Header from "../../../common/template/header";
import Footer from "../../../common/template/footer";
import SideBar from "../../../common/template/sideBar";
import Success from "../../../common/template/img/success.png";
import WarningIcon from '../../../common/template/img/Warning.png'
import qs from 'qs';

import "react-datepicker/dist/react-datepicker.css";

import "./novogerencia.css";

import { API_BASE_URL } from '../../config/configfetch';
import allowedExtensions from '../../../common/constants/utils/allowextensions';

import { Dialog, Modal, Stack } from "@mui/material";
import { format, addBusinessDays } from "date-fns";
import axiosInstance from "../../../config/api";
import { Box } from "@mui/system";
import Spinner from "../../../common/template/Spinner";
import moment from "moment/moment";

export default function NovoProjeto(props) {
  var netInt3 = JSON.parse(localStorage.getItem("setor"));
  var usuariosetor = netInt3.setor;
  var newInt = JSON.parse(localStorage.getItem("id"));
  var newIntGrupo = JSON.parse(localStorage.getItem("idsetor"));
  const [loading, setLoading] = useState(true);
  const [nomeprojeto, setnomeprojeto] = useState("");
  const [inicioprojeto, setInicioProjeto] = useState("");
  const [prevprojeto, setPrevProjeto] = useState("");
  const [responsavelprojeto, setresponsavelprojeto] = useState("");
  const [descricao, setDescricao] = useState("");
  const [diasProjeto, setDiasProjeto] = useState("");
  const [crudSuccess, setCrudSuccess] = useState(false);
  const [campoVazio, setCampoVazio] = useState(false);




  const [draggingx, setDraggingx] = useState(false);
  const [AlertaQuantidadeMB, setAlertaQuantidadeMB] = useState(false);
  const [AlertaFormato, setAlertaFormato] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [totalSize, setTotalSize] = useState(0);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDraggingx(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDraggingx(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDraggingx(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDraggingx(false);
    const files = Array.from(e.dataTransfer.files);
    handleFiles(files);
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...droppedFiles];
    const removedFile = newFiles.splice(index, 1)[0];
    setDroppedFiles(newFiles);
    setTotalSize(totalSize - removedFile.size);
  };

  const handleFileInput = (e) => {
    const files = Array.from(e.target.files);



    const invalidFiles = files.filter(file => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      return !allowedExtensions.includes(fileExtension);
    });

    if (invalidFiles.length > 0) {
      setAlertaFormato(true);
      return;
    }
 

    handleFiles(files);


    e.target.value = null;
  };



  const handleFiles = (files) => {
    const newFiles = [...droppedFiles, ...files];
    setDroppedFiles(newFiles);
    setTotalSize(newFiles.reduce((total, file) => total + file.size, 0));
  };



  const handleClose = () => {
    setCrudSuccess(false);
  };

  const handleCloseAlerta = () => {
    setAlertaQuantidadeMB(false)
  }

  const handleCloseAlertaFormato = () => {
    setAlertaFormato(false);
  }

  const handleInputChange = (e) => {
    setnomeprojeto(e.target.value);
    if (!e.target.value) {
      setCampoVazio(true);
    } else {
      setCampoVazio(false);
    }
  };

  const calculateBusinessDays = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    let count = 0;

    while (start <= end) {
      const dayOfWeek = start.getDay(); // Get the day of the week (0 - Sunday, 6 - Saturday)
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        count++; // Count only weekdays (Monday - Friday)
      }
      start.setTime(start.getTime() + millisecondsPerDay);
    }

    return count;
  };



  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 200);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleInicioProjetoChange = (ev) => {
    setInicioProjeto(ev.target.value);
    calculateAndSetDiasProjeto(ev.target.value, prevprojeto);
  };

  const handlePrevProjetoChange = (ev) => {
    setPrevProjeto(ev.target.value);
    calculateAndSetDiasProjeto(inicioprojeto, ev.target.value);
  };

  const calculateAndSetDiasProjeto = (start, end) => {
    if (start && end) {
      const days = calculateBusinessDays(start, end);
      setDiasProjeto(days);
    } else {
      setDiasProjeto("");
    }
  };

  async function novoProjet() {
    
    if (
      !nomeprojeto ||
      !inicioprojeto ||
      !prevprojeto ||
      !responsavelprojeto ||
      !descricao
    ) {
      const camposFaltando = [];

      if (!nomeprojeto) {
        setCampoVazio(true);
        camposFaltando.push("Nome do Projeto");
      } else {
        setCampoVazio(false);
      }

      // Repita o mesmo padrão para os outros campos obrigatórios
      if (!inicioprojeto) {
        setCampoVazio(true);
        camposFaltando.push("Início do Projeto");
      } else {
        setCampoVazio(false);
      }

      if (!prevprojeto) {
        setCampoVazio(true);
        camposFaltando.push("Previsão do Projeto");
      } else {
        setCampoVazio(false);
      }

      if (!responsavelprojeto) {
        setCampoVazio(true);
        camposFaltando.push("Responsável pelo Projeto");
      } else {
        setCampoVazio(false);
      }

      if (!descricao) {
        setCampoVazio(true);
        camposFaltando.push("Descrição do Projeto");
      } else {
        setCampoVazio(false);
      }

      // Agora você pode usar o array camposFaltando para tratar os campos não preenchidos
      return;
    }





    setLoading(true);

    try {
      const token = JSON.parse(localStorage.getItem('jwttoken'))?.jwttoken;

      const formData = new FormData();

      const crudinsert = {
        nomeprojeto: nomeprojeto,
        inicioprojeto: moment(inicioprojeto).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        previsaodoprojeto: moment(prevprojeto).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        previsaoprojetodias: diasProjeto,
        responsavelprojeto: responsavelprojeto,
        descricaoprojeto: descricao,
        grupousuario: { id: newIntGrupo.idsetor },
      };

      // Adiciona os dados do projeto ao FormData
      formData.append('projeto', new Blob([JSON.stringify(crudinsert)], { type: 'application/json' }));

      // Adiciona os arquivos ao FormData com validação de extensão
      droppedFiles.forEach((file, index) => {
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
          alert(`Extensão de arquivo não permitida: ${file.name}`);
          setLoading(false);
          throw new Error('Extensão de arquivo não permitida.');
        }

        formData.append('conteudoAnexo', file, file.name);
      });

      // Realiza a requisição para enviar os dados do projeto e os anexos
      const response = await fetch(`${API_BASE_URL}projeto`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      // Lida com a resposta
      if (response.status === 201) {
        setLoading(false);
        setCrudSuccess(true);
        window.location.href = "/gerencimanetoprojeto";
      } else {
        const data = await response.json();
        setAlertaQuantidadeMB(true);
        setCrudSuccess(false);
        setLoading(false);
      }
    } catch (error) {
      alert("Erro: " + error.message);
      setCrudSuccess(false);
      setLoading(false);
    }





  }

  /*
    ----------
        CSS ABAIXO:
    */

  const style = {
    position: "absolute",
    top: "50%",
    left: "52%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#ffffff",
    border: "2px solid #ffffff",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };


  function formatBytes(bytes) {
    return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
  }

  return (
    <div className="wrapper">
      <Header />
      <SideBar />
      <div className="content-wrapper">
        <div>
          <div class="card-header">
            <section class="content-header">
              <h1>
                <i class="fa fa-plus-square fa-1x cor-padrao"></i> Projeto
                <small></small>
              </h1>
              <ol class="breadcrumb">
                <li>
                  <i class="fa fa-home"></i> Portal JCR
                </li>
                <li>
                  <i class="fa fa-wrench"></i> Gerenciamento do Projeto
                </li>
                <li class="active">
                  <i class="fa fa-ticket"></i> Novo Projeto
                </li>
              </ol>
            </section>
          </div>
          <div className="content-wrapper content pl-1">
            <div class="card">
              <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger">
                <div
                  id="edicaoCategoriaChamadoForm:painelSubcategoria_content"
                  class="ui-panel-content ui-widget-content"
                >
                  <div class="box-header with-border">
                    {loading ? (
                      <Dialog
                        open={true}
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <div className="pos-center">
                          <Spinner />
                        </div>
                      </Dialog>
                    ) : (
                      <div class="form-horizontal">
                        <div className="content row">
                          <div className="box-body">
                            <h4>Novo Projeto</h4>

                            <Modal
                              open={crudSuccess}
                              onClose={handleClose}
                              aria-labelledby="parent-modal-title"
                              aria-describedby="parent-modal-description"
                            >
                              <Box sx={{ ...style, width: 500 }}>
                                <h2>
                                  <img
                                    src={Success}
                                    width="30"
                                    height="30"
                                  ></img>{" "}
                                  Projeto Cadastrado com Sucesso !
                                </h2>
                              </Box>
                            </Modal>

                            <Modal
                              open={AlertaQuantidadeMB}
                              onClose={handleCloseAlerta}
                              aria-labelledby="parent-modal-title"
                              aria-describedby="parent-modal-description"
                            >
                              <Box sx={{ ...style, width: 500 }}>
                                <h2>
                                  <img
                                    src={WarningIcon}
                                    width="30"
                                    height="30"
                                  ></img>{" "}
                                  Limite de tamanho excedido (20 MB) Valide os campos do anexo
                                </h2>
                              </Box>
                            </Modal>

                            <Modal
                              open={AlertaFormato}
                              onClose={handleCloseAlertaFormato}
                              aria-labelledby="parent-modal-title"
                              aria-describedby="parent-modal-description"
                            >
                              <Box sx={{ ...style, width: 500 }}>
                                <h2>
                                  <img src={WarningIcon} width="30" height="30" alt="Warning"></img>{" "}
                                  Extensão de arquivo não permitida
                                </h2>
                                <br />
                                <h3>Formatos aceitos:</h3>
                                <h4 style={{ whiteSpace: 'pre-line' }}>{allowedExtensions.join(', ')}</h4>

                              </Box>
                            </Modal>

                            <div>
                              <div className="form-group">
                                <div className="col-sm-12 col-md-3 control-label">
                                  <label htmlFor="selectPrioridade">
                                    Nome do Projeto: *
                                  </label>
                                </div>
                                <div className="col-sm-12 col-md-9 control-label">
                                  <input
                                    id="nomeprojetoForm:titulo"
                                    type="text"
                                    maxLength="60"
                                    onChange={handleInputChange}
                                    className="form-control"
                                    value={nomeprojeto}
                                    placeholder={
                                      campoVazio
                                        ? "Favor preencher os campos obrigatórios *"
                                        : ""
                                    }
                                    style={{
                                      borderColor: campoVazio ? "red" : "",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="col-sm-12 col-md-3 control-label">
                                  <label htmlFor="selectPrioridade">
                                    Inicio do Projeto: *
                                  </label>
                                </div>
                                <div className="col-sm-12 col-md-2">
                                  <Stack spacing={8}>
                                    <input
                                      type="datetime-local"
                                      onChange={handleInicioProjetoChange}
                                      min="2022-01-01"
                                      value={inicioprojeto}
                                      style={{
                                        borderColor: campoVazio ? "red" : "",
                                      }}
                                      max="2030-01-01"
                                    ></input>
                                  </Stack>
                                </div>
                                <div className="col-sm-12 col-md-2 control-label">
                                  <label htmlFor="selectPrioridade">
                                    Previsão do Projeto:*
                                  </label>
                                </div>
                                <div className="col-sm-12 col-md-2">
                                  <Stack spacing={8}>
                                    <input
                                      type="datetime-local"
                                      value={prevprojeto}
                                      onChange={handlePrevProjetoChange}
                                      min="2022-01-01"
                                      max="2030-01-01"
                                      style={{
                                        borderColor: campoVazio ? "red" : "",
                                      }}
                                    ></input>
                                  </Stack>
                                </div>

                                <div className="col-sm-12 col-md-1 control-label">
                                  <label htmlFor="selectPrioridade">
                                    Dias: *
                                  </label>
                                </div>
                                <div className="col-sm-12 col-md-2">
                                  <input
                                    type="text"
                                    disabled
                                    value={
                                      diasProjeto === 1
                                        ? diasProjeto + " DIA"
                                        : diasProjeto + " DIAS"
                                    }
                                    onChange={(e) =>
                                      setDiasProjeto(e.target.value)
                                    }
                                    className="form-control"
                                  />
                                </div>
                              </div>

                              <div className="form-group">
                                <div className="col-sm-12 col-md-3 control-label">
                                  <label htmlFor="selectPrioridade">
                                    Setor da Abertura do Projeto: *
                                  </label>
                                </div>
                                <div className="col-sm-12 col-md-9 control-label">
                                  <input
                                    id="setoraberturaForm:titulo"
                                    type="text"
                                    maxLength="60"
                                    value={usuariosetor}
                                    disabled
                                    name="nomeprojetoForm:titulo"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="col-sm-12 col-md-3 control-label">
                                  <label htmlFor="selectPrioridade">
                                    Responsavel pelo Projeto: *
                                  </label>
                                </div>
                                <div className="col-sm-12 col-md-9 control-label">
                                  <input
                                    id="chamadoForm:titulo"
                                    type="text"
                                    value={responsavelprojeto}
                                    onChange={(e) =>
                                      setresponsavelprojeto(e.target.value)
                                    }
                                    placeholder={
                                      campoVazio
                                        ? "Favor preencher os campos obrigatórios *"
                                        : ""
                                    }
                                    style={{
                                      borderColor: campoVazio ? "red" : "",
                                    }}
                                    maxLength="60"
                                    name="chamadoForm:titulo"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div class="form-group">
                                <div class="col-sm-12 col-md-3 control-label">
                                  <label for="selectPrioridade">
                                    Descrição do Projeto: *
                                  </label>
                                </div>
                                <div class="col-sm-12 col-md-9 control-label ">
                                  <textarea
                                    onChange={(e) =>
                                      setDescricao(e.target.value)
                                    }
                                    oninput="countText()"
                                    maxlength="1000"
                                    name="chamadoForm:descricao"
                                    class="form-control"
                                    style={{
                                      borderColor: campoVazio ? "red" : "",
                                    }}
                                    placeholder={
                                      campoVazio
                                        ? "Favor preencher os campos obrigatórios *"
                                        : ""
                                    }
                                    rows="5"
                                  ></textarea>
                                </div>
                              </div>
                              <div class="col-sm-12 col-md-10 control-label ">
                                <label for="characters">
                                  Quant de caracteres descrição:{" "}
                                </label>
                                <span id="characters">
                                  {" "}
                                  {descricao.length}{" "}
                                </span>
                              </div>
                              <br></br><br></br>
                              <div className="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger">
                                <div className="card">
                                  <br />
                                  <div className="col-sm-6 col-md-6 control-label text-center">
                                    <label htmlFor="fileInput" className="btn btn-block btn-danger btn-lg custom-button">
                                      Selecionar arquivos para Anexar
                                    </label>
                                    <input
                                      type="file"
                                      id="fileInput"
                                      style={{ display: 'none' }}
                                      onChange={handleFileInput}
                                      multiple
                                    />
                                    <br />
                                  </div>

                                  <div
                                    className={`drop-container ${draggingx ? 'draggingx' : ''}`}
                                    onDragEnter={handleDragEnter}
                                    onDragLeave={handleDragLeave}
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                  >
                                    {droppedFiles.length > 0 ? (
                                      <div>
                                        <p>Arquivos no container:</p>
                                        <ul>
                                          {droppedFiles.map((file, index) => (
                                            <li key={index}>
                                              {file.name} ({formatBytes(file.size)}){' '}
                                              <span className="close-icon" onClick={() => handleRemoveFile(index)}>
                                                &#x2716;
                                              </span>
                                            </li>
                                          ))}
                                        </ul>
                                        <p>Tamanho total: {formatBytes(totalSize)}</p>
                                      </div>
                                    ) : (
                                      <p>ou arraste e solte os arquivos aqui (limite: 20 MB) </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div class="col-sm-3 col-md-6 control-label">
                          <button
                            type="button"
                            class="btn btn-block btn-danger btn-lg"
                          >
                            Voltar
                          </button>
                        </div>

                        <div class="col-sm-3 col-md-6 control-label">
                          <button
                            type="button"
                            class="btn btn-block btn-primary btn-lg"
                            onClick={() => {
                              if (!nomeprojeto) {
                                setCampoVazio(true);
                              } else {
                                novoProjet();
                              }
                            }}
                          >
                            Salvar
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
