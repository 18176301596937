import { Divider, Modal, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Footer from '../../../../common/template/footer';
import Header from '../../../../common/template/header';
import SideBar from '../../../../common/template/sideBar';
import axiosInstance from '../../../../config/api';
import { useParams } from 'react-router-dom';

import InfoIcon from '../../../../common/template/img/info.png'
import Success from '../../../../common/template/img/success.png'


import Box from '@mui/material/Box';
import Spinner from '../../../../common/template/Spinner';

const loadingcss = {
    position: 'absolute',
    top: '50%',
    left: '52%'
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '52%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#ffffff',
    border: '2px solid #ffffff',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,


};

export default function CategoriaChamadoEdit() {

    const { id } = useParams();
    const [alertWarning, setalertWarning] = React.useState(false);

    var newIntGrupo = JSON.parse(localStorage.getItem('idsetor'));

    var setorGrupo = JSON.parse(localStorage.getItem('setor'));


    var grupoID = newIntGrupo.idsetor;

    var setorparecer = setorGrupo.setor;

    var setorparecerID = setorGrupo.id;


    const [nomecategoriadto, setnomecategoriadto] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [open, setOpen] = React.useState(false);

    const [alertConcluido, setAlertConcluido] = useState(false);

    const [loading, setLoading] = React.useState(false);

    const handleCloseWarning = () => {
        setalertWarning(false);
    }
    

    async function voltar() {
        window.location.href = "/categoriachamadodswid=1912311"
    }

    useEffect(() => {

        async function listaCategoriachamado() {
            const response = await axiosInstance.get(`/categoriachamado/listadegrupo/${grupoID}/${id}`)

            setnomecategoriadto(response.data[0].nomecategoria)
        }
        listaCategoriachamado();


    }, []);

    async function cadastroapop(){
        handleClose();
        setTimeout(() => { setAlertConcluido(true); }, 2000);
        setLoading(false);
        setTimeout(() => {window.location.href = `/categoriachamadodswid=1912311/`+id;}, 2000);
    }

    async function warningpop(){

        handleClose();
        setTimeout(() => { setalertWarning(true); }, 2000);
        setLoading(false);
        window.location.href = `/categoriachamadodswid=19123112/`+id;
    }

    async function novocadastro() {

        setLoading(true);

        const crudinsertuser = {
            nomecategoria: nomecategoriadto,
            grupousuario: { id: grupoID }
        }
        handleClose();
        const response = await axiosInstance.put(`/categoriachamado/${id}`, crudinsertuser)
            .then(resp => {
               cadastroapop();
            })
            .catch(error => {
                warningpop();

            });
    }

 

    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <div>
                    <div class="card-header">

                        <section class="content-header">
                            <h1><i class="fa fa-plus-square fa-1x cor-padrao"></i> Categoria Chamado
                                <small></small>
                            </h1>
                            <ol class="breadcrumb">
                                <li><i class="fa fa-home"></i> Portal JCR</li>
                                <li><i class="fa fa-wrench"></i> Sistema de Chamado</li>
                                <li class="active"><i class="fa fa-reorder"></i> Categoria Chamado</li>
                            </ol>
                        </section>



                    </div>
                    <div className='content'>
                        <div class="card">
                        </div>
                        <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger"><div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" class="ui-panel-content ui-widget-content">
                            <div class="box-header with-border">
                                <div class="form-horizontal">
                                    <div className='content'>

                                        {loading === true ? (
                                            <div className="">
                                                <Modal
                                                    open={loading}
                                                    aria-labelledby="parent-modal-title"
                                                    aria-describedby="parent-modal-description"
                                                >
                                                    <Box sx={{ ...loadingcss, width: 400 }}>
                                                        <Spinner />
                                                    </Box>
                                                </Modal>
                                            </div>
                                        ) : (
                                            <div>

                                            </div>
                                        )}

                                        <div class="box-body">
                                            <h4>Categoria Chamado</h4>
                                            <Divider />
                                            <br></br>
                                            <div class="form-group">
                                                <div class="col-sm-3 col-md-3 control-label">
                                                    <label for="login">Nome Categoria: *</label>
                                                </div>
                                                <div class="col-sm-3 col-md-8 control-label">
                                                    <input id="chamadoForm:titulo" type="text"
                                                        value={nomecategoriadto}
                                                        onChange={es => setnomecategoriadto(es.target.value)}
                                                        name="chamadoForm:titulo" class="form-control" maxlength="100">
                                                    </input>
                                                </div>

                                            </div>

                                            <div class="form-group">
                                                <div class="col-sm-3 col-md-3 control-label">
                                                    <label for="login">Setor: *</label>
                                                </div>
                                                <div class="col-sm-3 col-md-8 control-label">
                                                    <input id="chamadoForm:titulo" type="text"
                                                        value={setorparecer}
                                                        readonly="readonly"
                                                        name="chamadoForm:titulo" class="form-control" maxlength="100">
                                                    </input>
                                                </div>



                                            </div>
                                            <div class="col-sm-2 col-md-6">
                                                <button type="button" class="btn btn-block btn-danger btn-lg"
                                                    onClick={() => voltar()}
                                                >
                                                    <i class="fa fa-chevron-left"></i> VOLTAR</button>
                                            </div>
                                            <div class="col-sm-2 col-md-6">
                                                <button type="button" class="btn btn-block btn-success btn-lg"
                                                    onClick={() => novocadastro()}
                                                >
                                                    <i class="fa fa-floppy-o"></i> Salvar</button>

                                            </div>
                                        </div>
                                        <Modal
                                            open={alertConcluido}
                                            onClose={handleClose}
                                            aria-labelledby="parent-modal-title"
                                            aria-describedby="parent-modal-description"
                                        >
                                            <Box sx={{ ...style, width: 500 }}>
                                                <h2 ><img
                                                    src={Success}
                                                    width="30"
                                                    height="30" >
                                                </img> Cadastro Categoria Chamado com Sucesso !</h2>
                                            </Box>
                                        </Modal>

                                        <Modal
                                            open={alertWarning}
                                            onClose={handleCloseWarning}
                                            aria-labelledby="parent-modal-title"
                                            aria-describedby="parent-modal-description"
                                        >
                                            <Box sx={{ ...style, width: 400 }}>
                                                <h2 >Favor Preencher os Campos Necessários! <img src={InfoIcon}

                                                    width="20" height="20" style={{ marginLeft: '1%' }}
                                                ></img></h2>

                                                <p></p>
                                                <p >
                                                    <button type="button" class="btn btn-block btn-danger btn-lg" onClick={handleCloseWarning}>Fechar</button>

                                                </p>

                                            </Box>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}