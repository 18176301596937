import { Alert, Divider, Modal, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Footer from '../../../common/template/footer'
import Header from '../../../common/template/header'
import SideBar from '../../../common/template/sideBar'
import axiosInstance from '../../../config/api'
import { useParams } from 'react-router-dom';
import InfoIcon from '../../../common/template/img/info.png'
import { Box } from '@mui/system'

export default function UsuarioNewEdit(props) {
    const { id } = useParams();
    const [senhanull, setsenhanull] = useState('');
    const [loading, setloading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [Grupochamado, setGrupochamado] = useState([]);

    const [gruposetor, setGruposetor] = useState(0);

    const [alertstate, setAlertstate] = useState([]);
    const [alertConcluido, setAlertConcluido] = useState([]);

    const [logindto, setlogindto] = useState('');
    const [senhadto, setsenhadto] = useState('');
    const [nomecomplementodto, setnomecomplementodto] = useState('');
    const [emaildto, setemaildto] = useState('');
    const [chapadto, setchapadto] = useState('');
    const [coligadadto, setColigada] = useState([]);
    const [gruposetordto, setgruposetordto] = useState('');
    const [coligadadtog, setColigadadtog] = useState(0);

    const [Senhafraca, setSenhafraca] = useState(false);
    const [usuariostate, setusuariostate] = useState(false);
    const [usuarioexiste, setusuarioexiste] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseusuariostate = () => {
        setusuariostate(false);
    }

    const handleCloseusuarioexiste = () => {
        setusuarioexiste(false);
    }

    const handleClosesenhafraca = () => {
        setSenhafraca(false);
    }


    const style = {
        position: 'absolute',
        top: '50%',
        left: '52%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#ffffff',
        border: '2px solid #ffffff',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,


    };

    useEffect(() => {
        setTimeout(() => {

            if(senhadto.length < 8 || senhadto.length < '8'){
                setsenhadto('');
               
            }
            setTimeout(() => {
                setloading(true);
            }, 300);

            async function loadUsuario() {
                const response = await axiosInstance.get("/login/todos/" + id);

                setlogindto(response.data[0].login);
                setsenhadto(response.data[0].senha);
                setnomecomplementodto(response.data[0].nomecomplemento);
                setgruposetordto(response.data[0].grupousuario.setor);
                setemaildto(response.data[0].email);
                setchapadto(response.data[0].chapa);
                setgruposetordto(response.data[0].coligada.nomefantasia);


            }

            loadUsuario();
        });

        async function loadingColigada() {
            const response = await axiosInstance.get("/coligadas")
                .then(resp => {
                    setColigada(resp.data);
                }).catch(error => {
                    setloading(true);
                    setTimeout(() => { window.location.href = '/'; }, 2000);
                });
        }
        loadingColigada();

        async function loadingchamado() {

            const response = await axiosInstance.get("/chamado/grupousuario")
                .then(resp => {
                    setGrupochamado(resp.data);
                }).catch(error => {
                    setloading(true);
                    setTimeout(() => { window.location.href = '/'; }, 2000);
                });
        }
        loadingchamado();
    },[]);



    async function voltar() {
        window.location.href = "/cadastrouser";
    }




    async function novocadastrousuario() {
        
      

        const crudinsertuser = {
            login: logindto,
            senha: senhadto,
            nomecomplemento: nomecomplementodto,
            grupousuario: { id: gruposetor },
            email: emaildto,
            coligada: { id: coligadadtog },
            chapa: chapadto,
        }

      

        handleClose();
        const response = await axiosInstance.put(`/login/${id}`, crudinsertuser)
            .then(resp => {
                handleClose();
                setTimeout(() => { setAlertConcluido(false); }, 1000);
                setTimeout(() => { setAlertConcluido(true); }, 7500);
                setTimeout(() => { window.location.href = '/cadastrouser'; }, 2000);

            })
            .catch(error => {
                
                    setusuariostate(true);
      

            });
    }
    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <div>
                    <div class="card-header">

                        <section class="content-header">
                            <h1><i class="fa fa-plus-square fa-1x cor-padrao"></i> Novo Usuário
                                <small></small>
                            </h1>
                            <ol class="breadcrumb">
                                <li><i class="fa fa-home"></i> Portal JCR</li>
                                <li><i class="fa fa-wrench"></i> Configurações</li>
                                <li class="active"><i class="fa fa-reorder"></i> Usuario</li>
                            </ol>
                        </section>



                    </div>
                    <div className='content'>
                        <div class="card">
                        </div>
                        <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger"><div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" class="ui-panel-content ui-widget-content">
                            <div class="box-header with-border">
                                <div class="form-horizontal">
                                    <div className='content'>
                                        {!alertstate &&
                                            <Stack
                                                sx={{ width: '100%' }} spacing={2}>
                                                <Alert severity="warning">Favor Preencher os campos !</Alert>
                                            </Stack>
                                        }

                                        {!alertConcluido &&
                                            <Stack
                                                sx={{ width: '100%' }} spacing={2}>
                                                <Alert severity="success">Cadastro Realizado com Sucesso !</Alert>
                                            </Stack>
                                        }
                                        <div class="box-body">
                                            <h4>Cadastro Usuário</h4>
                                            <Divider />
                                            <br></br>
                                            <div class="form-group">
                                                <div class="col-sm-3 col-md-3 control-label">
                                                    <label for="login">Login: *</label>
                                                </div>
                                                <div class="col-sm-3 col-md-8 control-label">
                                                    <input id="chamadoForm:titulo" type="text"
                                                        value={logindto}
                                                        onChange={es => setlogindto(es.target.value)}
                                                        name="chamadoForm:titulo" class="form-control" maxlength="100">
                                                    </input>
                                                </div>

                                            </div>

                                            <div class="form-group">
                                                <div class="col-sm-3 col-md-3 control-label">
                                                    <label for="login">Senha: *</label>
                                                </div>
                                                <div class="col-sm-3 col-md-8 control-label">
                                                    <input id="chamadoForm:titulo" type="password"
                                                        value={senhadto}
                                                        onChange={es => setsenhadto(es.target.value)}
                                                        name="chamadoForm:titulo" class="form-control" maxlength="100">
                                                    </input>
                                                </div>

                                            </div>

                                            <div class="form-group">
                                                <div class="col-sm-3 col-md-3 control-label">
                                                    <label for="login">Nome Completo do Colaborador: *</label>
                                                </div>
                                                <div class="col-sm-3 col-md-8 control-label">
                                                    <input id="chamadoForm:titulo" type="text"
                                                        value={nomecomplementodto}
                                                        onChange={es => setnomecomplementodto(es.target.value)}
                                                        name="chamadoForm:titulo" class="form-control" maxlength="100">
                                                    </input>
                                                </div>

                                            </div>

                                            <div class="form-group">
                                                <div class="col-sm-3 col-md-3 control-label">
                                                    <label for="coligada">Coligada Folha de Pagamento: *</label>
                                                </div>
                                                <div class="col-sm-3 col-md-8 control-label">
                                                    <select style={{ height: '35px' }}
                                                        class="form-control"
                                                        id="teste2"

                                                        onChange={es => setColigadadtog(es.target.value)}
                                                    >
                                                        <option > Selecione*</option>
                                                        {coligadadto.map((item, index) => (
                                                            <option value={item.id} key={index}

                                                            >{item.nomefantasia}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                            </div>


                                            <div class="form-group">
                                                <div class="col-sm-3 col-md-3 control-label">
                                                    <label for="login">Grupo Setor: *</label>
                                                </div>
                                                <div class="col-sm-3 col-md-8 control-label">
                                                    <select style={{ height: '35px' }}
                                                        class="form-control"
                                                        id="teste"

                                                        onChange={es => setGruposetor(es.target.value)}
                                                    >
                                                        <option>Selecione*</option>
                                                        {Grupochamado.map((item, index) => (
                                                            <option value={item.id} key={index}

                                                            >{item.setor}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                            </div>

                                            <div class="form-group">
                                                <div class="col-sm-3 col-md-3 control-label">
                                                    <label for="login">Chapa RM: </label>
                                                </div>
                                                <div class="col-sm-3 col-md-8 control-label">
                                                    <input id="chamadoForm:titulo" type="text"
                                                        onChange={es => setchapadto(es.target.value)}
                                                        value={chapadto}
                                                        name="chamadoForm:titulo" class="form-control" maxlength="100">
                                                    </input>
                                                </div>

                                            </div>


                                            <div class="form-group">
                                                <div class="col-sm-3 col-md-3 control-label">
                                                    <label for="login">E-mail: *</label>
                                                </div>
                                                <div class="col-sm-3 col-md-8 control-label">
                                                    <input id="chamadoForm:titulo" type="text"
                                                        onChange={es => setemaildto(es.target.value)}
                                                        value={emaildto}
                                                        name="chamadoForm:titulo" class="form-control" maxlength="100">
                                                    </input>
                                                </div>

                                            </div>
                                            <div class="col-sm-3 col-md-6 control-label">
                                                <button type="button" class="btn btn-block btn-danger btn-lg" onClick={() => voltar()} >Voltar</button>
                                            </div>

                                            <div class="col-sm-3 col-md-6 control-label">
                                                <button type="button" class="btn btn-block btn-primary btn-lg" onClick={() => novocadastrousuario()}>Salvar</button>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <Modal
                            open={usuariostate}
                            onClose={handleCloseusuariostate}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                        >
                            <Box sx={{ ...style, width: 400 }}>
                                <h2 >Senha Fraca ou os campos nao obrigatorios nao foram preenchidos !!!<img src={InfoIcon}

                                    width="20" height="20" style={{ marginLeft: '1%' }}
                                ></img></h2>
                                    <h3>-----Inicio do Log----------------</h3>
                                    <p>Login* {logindto === '' ? " 'Campos nao foram Preenchidos!!' " : logindto}</p> 
                                    <p>Nome Completo* {nomecomplementodto === '' ? " 'Campos nao foram Preenchidos!!' " : nomecomplementodto} </p>
                                    <p>Grupo Coligada* {coligadadtog === '' ? " 'Campos nao foram Preenchidos!! '" : coligadadtog} </p>
                                    <p>GrupoSetor* {gruposetor === '' ? "' Campos nao foram Preenchidos!! '" :  gruposetor === '0' || gruposetor === 0 ? " TI" : "' Não Informado no banco de dados!!! '"} </p>
                                    <p>E-mail* {emaildto === '' ? "'Campos nao foram Preenchidos!!'" : emaildto}</p>
                                    <p>Senha* {senhadto === '' ? "'Campos nao foram Preenchidos!!'" : senhadto < 8 ? "Senha fraca !!!... Utilize uma senha Forte!! " : "**************" }</p>
                                    <h3>-----Fim do Log----------------</h3>
                                <p></p>
                                <p >
                                    <button type="button" class="btn btn-block btn-danger btn-lg" onClick={handleCloseusuariostate}>Fechar</button>

                                </p>

                            </Box>
                        </Modal>
                        <Modal
                            open={Senhafraca}
                            onClose={handleClosesenhafraca}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                        >
                            <Box sx={{ ...style, width: 400 }}>
                                <h2 >Senha Fraca! <img src={InfoIcon}

                                    width="20" height="20" style={{ marginLeft: '1%' }}
                                ></img></h2>

                                <p></p>
                                <p >
                                    <button type="button" class="btn btn-block btn-danger btn-lg" onClick={handleClosesenhafraca}>Fechar</button>

                                </p>

                            </Box>
                        </Modal>

                        <Modal
                            open={usuarioexiste}
                            onClose={handleCloseusuarioexiste}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                        >
                            <Box sx={{ ...style, width: 400 }}>
                                <h2 >Usuário ja existe! <img src={InfoIcon}

                                    width="20" height="20" style={{ marginLeft: '1%' }}
                                ></img></h2>

                                <p></p>
                                <p >
                                    <button type="button" class="btn btn-block btn-danger btn-lg" onClick={handleCloseusuarioexiste}>Fechar</button>

                                </p>

                            </Box>
                        </Modal>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )

}