const allowedExtensions = [
'doc',
'docx',
'xlsx',
'csv',
'xls',
'txt',
'dwg',
'ico',
'pdf',
'jpg',
'jpeg',
'png',
'PNG',
'ppt',
'gif',
'pptx',
'mp4',
'avi',
'mkv',
'wmv',
'drawio',
'xml',
'SQL',
'sql'

];


export default allowedExtensions;