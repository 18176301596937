import React from 'react';
import Footer from '../../common/template/footer';
import Header from '../../common/template/header';
import SideBar from '../../common/template/sideBar';
import '../doc/nfselayoutscroll.css';
import "../doc/teste.scss";


export default function Coligada(props) {

    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <div>

                    <br></br>
                    <div className='container-fluid scrollBox'>
                        <h1>Coligada</h1>
                    </div>
                </div>


            </div>

            <Footer />

        </div>
    )
}