import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Stack, Tab, Tabs } from "react-bootstrap";
import { API_BASE_URL } from '../../config/configfetch';
import { useParams, useHistory } from "react-router-dom";
import Spinner from "../../../common/template/Spinner";
import Header from "../../../common/template/header";
import SideBar from "../../../common/template/sideBar";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Tab as TabAuxiliar,
  Tabs as TabsAuxiliar,
  TablePagination,
  IconButton,
  Modal,
  Box,
} from "@mui/material";
import moment from 'moment';

import AnexoIcon from '../../../common/template/img/download.png'
import { Box as BoxModal } from "@mui/system";
import VisualizarIMG from "../../../common/template/img/lapis.png";
import axiosInstance from "../../../config/api";
import VisualizarItemIMG from "../../../common/template/img/visualizar.png";
import LixeiraItemIMG from "../../../common/template/img/lixeira.png";
import Select from "react-select";

import SuccessImg from "../../../common/template/img/success.png";
import CloseButtonIcon from "../../../common/template/img/closebutton.png";
import WarningImg from "../../../common/template/img/Warning.png";

import "./gerenciamentoid.css";
import FILE_TYPES from '../../../common/constants/utils/filetypes';
import { Divider } from "@material-ui/core";
import Footer from "../../../common/template/footer";
import { format, addBusinessDays } from "date-fns";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import "react-datetime/css/react-datetime.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt-BR";
import FluxoTempoReal from "./fluxotemporeal";
import FluxoProjeto from "./fluxotemporeal";
import allowedExtensions from "../../../common/constants/utils/allowextensions";

export default function GerenciamentoID(props) {
  
  
  const { id } = useParams();
  const [anexolistitemdetalhado, setanexolistitemdetalhado] = useState([]);
  const [anexolist, setanexolist] = useState([]);
  const [anexolistItem, setanexolistItem] = useState([]);
 
  const [identificadorProjeto, setIdentificadorProjeto] = useState(id);
  const [loading, setLoading] = useState(false);
  const [ModalOpen, setModalOpen] = useState(false);
  const [ModalItemDetalhado, setModalItemDetalhado] = useState(false);
  var setorescrito = JSON.parse(localStorage.getItem("idsetor"));
  var setorprincipal = setorescrito.idsetor;
  const [nomeprojeto, setnomeprojeto] = useState("");
  const [idauxiliar, setidauxiliar] = useState("");
  const [inicioprojeto, setInicioProjeto] = useState("");
  const [previsaodoprojeto, setprevisaodoprojeto] = useState("");
  const [previsaoprojetodias, setprevisaoprojetodias] = useState("");
  const [fimdoprojeto, setfimdoprojeto] = useState("");
  const [fimdoprojetodias, setfimdoprojetodias] = useState("");
  const [responsavelprojeto, setresponsavelprojeto] = useState("");
  const [descricaoprojeto, setdescricaoprojeto] = useState("");
  const [ambienteenum, setambienteenum] = useState("");
  const [ModalEditItem, setModalEditItem] = useState(false);
  const [itemgrupoprojeto, setItemgrupoprojeto] = useState("");
  const [novatarefa, setnovatarefa] = useState("");
  const [ambienteenumitemdetalhado, setambienteenumitemdetalhado] = useState("");

  const [nometarefadetalhada, setnometarefadetalhada] = useState("");

  const [nometarefadetalhadasubitem, setnometarefadetalhadasubitem] = useState("");
  const [datainicialtarefasubitem, setdatainicialtarefasubitem] = useState("");
  const [dataprevtarefasubitem, setdataprevtarefasubitem] = useState("");
  const [usuarioSelecionadosubitem, setUsuarioSelecionadosubitem] = useState(null);
  const [usuariotarefaitemprojeto, setusuariotarefaitemprojeto] = useState('');
  const [idsubitemprojeto, setidsubitemprojeto] = useState('');
 
  const [idauxiliarparaitemprojeto, setidauxiliarparaitemprojeto] = useState('');
  const [idauxiliarparaitemprojetodefault, setidauxiliarparaitemprojetodefault] = useState('');

  const [statustarefaitemprojeto, setstatustarefaitemprojeto] = useState("");

  const [idauxiliarsubitemprojeto, setidauxiliarsubitemprojeto] = useState("");
  const [Datainicialauxiliar, setDatainicialauxiliar] = useState("");
  const [Datafinalauxiliar, setDatafinalauxiliar] = useState("");
  const [StatusTarefaauxiiar, setStatusTarefaauxiiar] = useState("");
  const [idaxuiliarrev, setidaxuiliarrev] = useState("");
  const [usuarioresponsavel, setUsuarioResponsavel] = useState("");
  const [novatarefaauxiliar, setnovatarefaauxiliar] = useState("");
  const [datainicialtarefa, setdatainicialtarefa] = useState("");
  const [dataprevtarefa, setdataprevtarefa] = useState("");
  const [Datafinal, setdatafinal] = useState("");
  const [Datainicial, setDatainicial] = useState("");
  const [diasProjeto, setDiasProjeto] = useState("");
  const [validarcampos, setvalidacampos] = useState(false);
  const [validacaocamposstring, setvalidacaocamposstring] = useState("");
  const [prevprojeto, setPrevProjeto] = useState("");
  const [campoVazio, setCampoVazio] = useState(false);
  const [crudSuccess, setCrudSuccess] = useState(false);
  const [crudDeletado, setDeletado] = useState(false);
  const [ModalEditItemDetalhado, setModalEditItemDetalhado] = useState(false);
  const [SituacaoProjetoAux, setSituacaoProjetoAux] = useState(false);
  const [usuarioSelecionado, setUsuarioSelecionado] = useState(null);
  const [parametroauxiliar, setParametro] = useState(null);
  const [ModalOpenAuxiliar, setModalOpenAuxiliar] = useState(false);
  const [NenhumRegistro, setNenhumRegistro] = useState(false);
  const history = useHistory();
  const [statusenumalteraritemprojeto, setstatusenumalteraritemprojeto] = useState('');
  const [activeKey, setActiveKey] = useState("ItemGerenciamento");
  const [usuarioresponsavelauxiliar, setusuarioresponsavelauxiliar] = useState("");
  const [AlertaFormato, setAlertaFormato] = useState(false);
  const [AlertWarning, setAlertWarning] = useState(false);

  const [mensagemerrordeleteitemgerenciamento, setmensagemerrordeleteitemgerenciamento] = useState(false);
  const [mensagelfechamentoprojeto, setmensagelfechamentoprojeto] = useState(false);


  const [permissao, setPermissao] = useState(false);

  const handleSelectChange = (selectedOption) => {
    setUsuarioSelecionado(selectedOption);
  };

  const handleSelectChangeSubItem = (selectedOption) => {
    setUsuarioSelecionadosubitem(selectedOption);
  };

  const [rows, setRows] = useState([]);

  const handleNomeChange = (event) => {
    const nomeDoProjeto = event.target.value;
    setnomeprojeto(nomeDoProjeto);

    const idDoProjeto = `ID-${nomeDoProjeto}`;
    setIdentificadorProjeto(idDoProjeto);
  };

  var newIntGrupo = JSON.parse(localStorage.getItem("idsetor"));

  var netInt3 = JSON.parse(localStorage.getItem("setor"));
  var usuariosetor = netInt3.setor;

  const handleInicioProjetoChange = (ev) => {
    setInicioProjeto(ev.target.value);
    calculateAndSetDiasProjeto(ev.target.value, prevprojeto);
  };

  const handleInicioProjetoChangeSubItem = (ev) => {
    setdatainicialtarefasubitem(ev.target.value);
  }

  const handlePrevProjetoChangeSubItem = (ev) => {
    setdataprevtarefasubitem(ev.target.value);
  }

  const handleCloseAlertaFormato = () => {
    setAlertaFormato(false);
  }

  const handlePrevProjetoChange = (ev) => {
    setPrevProjeto(ev.target.value);
    calculateAndSetDiasProjeto(inicioprojeto, ev.target.value);
  };

  const opcoesStatus = [
    { value: "NAOINICIADA", label: "Não Iniciada" },
    { value: "EMANDAMENTO", label: "Em Andamento" },
    { value: "FINALIZADA", label: "Finalizada" },
    { value: "ENTREGUE", label: "Entregue" },
  ];

  const opcoesStatusAuxiliar = [
    { value: "NAOINICIADA", label: "Não Iniciada" },
    { value: "EMANDAMENTO", label: "Em Andamento" },

  ];


  const handleClosePermissao = () => {
    setPermissao(false);
    window.location.href = '/gerencimanetoprojeto';
  }


  const handleStatusChange = (selectedOption) => {
    setstatusenumalteraritemprojeto(selectedOption.value);
  };
  const handleStatusChangeItemProjeto = (selectedOption) => {
    setstatustarefaitemprojeto(selectedOption.value);
  }


  const handleStatusChangeAuxiliar = (selectedOption) => {
    setambienteenum(selectedOption.value);
  };


  const handleStatusItemDetalhado = (selectedOption) => {
    setambienteenumitemdetalhado(selectedOption.value);
  };

  const formatToISODate = (date) => {
    if (!date) return "";

    // Verifica se o valor de 'date' é uma data válida
    const isValidDate = !isNaN(new Date(date).getTime());

    if (!isValidDate) {
      console.error("Data inválida:", date);
      return "";
    }

    const adjustedDate = new Date(date);
    adjustedDate.setMinutes(
      adjustedDate.getMinutes() - adjustedDate.getTimezoneOffset()
    );

    const isoDate = adjustedDate.toISOString();
    return isoDate.slice(0, 16);
  };

  const calculateAndSetDiasProjeto = (start, end) => {
    if (start && end) {
      const days = calculateBusinessDays(start, end);
      setDiasProjeto(days);
    } else {
      setDiasProjeto("");
    }
  };

  const calculateBusinessDays = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    let count = 0;

    while (start <= end) {
      const dayOfWeek = start.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        count++;
      }
      start.setTime(start.getTime() + millisecondsPerDay);
    }

    return count;
  };

  const [listaItemProjeto, setlistaItemProjeto] = useState([]);

  const [SituacaoProjeto, setSituacaoProjeto] = useState(false);
  const [usuario, setUsuario] = useState([]);

  async function loadingItemprojetoIdRapida() {
    const response = await axiosInstance.get(`/projeto/itemprojeto/${id}`);

    if (response.data.totalElements === 0 || response.data.length === 0) {
      setSituacaoProjeto(true);
    } else {
      setlistaItemProjeto(response.data);

    }
  }

  useEffect(
    () => {
      async function loadingProjeto() {
        const response = await axiosInstance.get(
          `/projeto/${id}/${setorprincipal}`
        );

        console.log(response.data)
        if (response.data.length === 0) {

          setPermissao(true);

          setTimeout(() => {

            window.location.href = '/gerencimanetoprojeto';
          }, 6000);

          return;
        }


        setItemgrupoprojeto(response.data[0].id);
        setnomeprojeto(response.data[0].nomeprojeto);
        setInicioProjeto(response.data[0].inicioprojeto);
        setprevisaodoprojeto(response.data[0].previsaodoprojeto);
        setprevisaoprojetodias(response.data[0].previsaoprojetodias);
        setfimdoprojeto(response.data[0].fimdoprojeto);
        setfimdoprojetodias(response.data[0].fimdoprojetodias);
        setresponsavelprojeto(response.data[0].responsavelprojeto);
        setdescricaoprojeto(response.data[0].descricaoprojeto);
        setambienteenum(response.data[0].ambienteenum);
        setprevisaoprojetodias(response.data[0].previsaoprojetodias);






      }
      loadingProjeto();

      async function loadUsuario() {
        try {
          const response = await axiosInstance.get("/login/todos/");
          setUsuario(response.data);
        } catch (error) {
          console.error("Erro ao carregar usuários:", error);
        }
      }

      loadUsuario();

      async function loadinganexoprojeto() {
        const response = await axiosInstance.get("/projeto/anexoprojeto/" + parseInt(id))
        setanexolist(response.data);

      }
      loadinganexoprojeto();





      async function loadingItemprojetoId() {
        const response = await axiosInstance.get(`/projeto/itemprojeto/${id}`);

        if (response.data.totalElements === 0 || response.data.length === 0) {
          setSituacaoProjeto(true);
        } else {
          setlistaItemProjeto(response.data);

        }
      }
      loadingItemprojetoId();

      console.log("Usuario selecionado:", usuarioSelecionado);
    },
    [usuarioSelecionado],
    [rows],
    [parametroauxiliar],
    [listaItemProjeto],
    [idauxiliar],
    [idauxiliarsubitemprojeto],
    [activeKey],
    [idsubitemprojeto],
    [NenhumRegistro],
    [anexolistItem]
    [idaxuiliarrev],
    [rows],
    [ambienteenum],
    [idauxiliarparaitemprojeto],
    [idauxiliarparaitemprojetodefault]
    , []
  );

  async function loadingProjetoRefresh() {
    const response = await axiosInstance.get(
      `/projeto/${id}/${setorprincipal}`
    );


    setItemgrupoprojeto(response.data[0].id);
    setnomeprojeto(response.data[0].nomeprojeto);
    setInicioProjeto(response.data[0].inicioprojeto);
    setprevisaodoprojeto(response.data[0].previsaodoprojeto);
    setprevisaoprojetodias(response.data[0].previsaoprojetodias);
    setfimdoprojeto(response.data[0].fimdoprojeto);
    setfimdoprojetodias(response.data[0].fimdoprojetodias);
    setresponsavelprojeto(response.data[0].responsavelprojeto);
    setdescricaoprojeto(response.data[0].descricaoprojeto);
    setambienteenum(response.data[0].ambienteenum);
    setprevisaoprojetodias(response.data[0].previsaoprojetodias);






  }

  async function loadinganexoprojetoitemsubitem(idaux) {

    const response = await axiosInstance.get(`/projeto/itemdetalhadoprojeto/itemprojeto/anexoprojeto/${idaux}`)


    setanexolistitemdetalhado(response.data);

  }

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const reorderedData = [...rows];
    const [reorderedItem] = reorderedData.splice(sourceIndex, 1);
    reorderedData.splice(destinationIndex, 0, reorderedItem);

    const lowerIndex = Math.min(sourceIndex, destinationIndex);
    const upperIndex = Math.max(sourceIndex, destinationIndex);

    for (let i = lowerIndex; i <= upperIndex; i++) {
      reorderedData[i].ordem = i + 1;
    }

    try {
      for (let i = lowerIndex; i <= upperIndex; i++) {
        await axiosInstance.put(
          `/projeto/itemdetalhadoprojeto/itemprojeto/alterarordem/${parametroauxiliar}/${reorderedData[i].id}/${reorderedData[i].ordem}`,
          {
            ordem: reorderedData[i].ordem,
          }
        );
      }

      setRows([...reorderedData]);


    } catch (error) {

      console.error("ERRO VAMOS MOSTRAR::::::::", error);
    }
  }
  const handleDragEndItemGerenciamento = async (result) => {

    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;


    const reorderedData = [...listaItemProjeto];
    const [reorderedItem] = reorderedData.splice(sourceIndex, 1);
    reorderedData.splice(destinationIndex, 0, reorderedItem);

    const lowerIndex = Math.min(sourceIndex, destinationIndex);
    const upperIndex = Math.max(sourceIndex, destinationIndex);

    for (let i = lowerIndex; i <= upperIndex; i++) {
      reorderedData[i].ordem = i + 1;
    }

    try {
      for (let i = lowerIndex; i <= upperIndex; i++) {
        await axiosInstance.put(
          `/projeto/itemprojeto/alterarordem/${id}/${reorderedData[i].id}/${reorderedData[i].ordem}`,
          {
            ordem: reorderedData[i].ordem,
          }
        );
      }

      setlistaItemProjeto(reorderedData);


    } catch (error) {

      console.error("ERRO VAMOS MOSTRAR::::::::", error);
    }
  }


  async function loadinganexoprojetoauxiliar() {
    const response = await axiosInstance.get("/projeto/anexoprojeto/" + id)
    setanexolist(response.data);

  }

  const previsaoProjetoDate = new Date(previsaodoprojeto);
  const currentDate = new Date();
  const timeDifference = currentDate - previsaoProjetoDate;
  const daysOverdue = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  async function voltar() {
    window.location.href = "/gerencimanetoprojeto";
  }

  async function incluiritemprojeto() {
    setModalOpen(true);
  }

  async function deletaritemdetalhadoProjeto(idaux) {
    try {
      const response = await axiosInstance.delete(
        `/projeto/itemdetalhadoprojeto/itemprojeto/${idaux}`
      );

      if (response.status === "204" || response.status === 204) {

        setCrudSuccess(true);

        const response2 = await axiosInstance.get(
          `projeto/itemdetalhadoprojeto/itemprojeto/` + idaxuiliarrev
        );

        const adjustedRows = response2.data.map((item) => ({
          ...item,
          id: String(item.id),
        }));

        setParametro(idaxuiliarrev);

        console.log(rows);

        setRows(adjustedRows);
        setCrudSuccess(false);

        visualizardetalhamento(idaxuiliarrev)
      } else {
        alert(response.status + "Validacao de Falha Delete Falha");
      }
    } catch (error) { }
  }

  async function incluiritemdetalhadoProjeto() {
    const formData = new FormData();
    const token = JSON.parse(localStorage.getItem('jwttoken'))?.jwttoken;

    if (
      !nometarefadetalhada ||
      !datainicialtarefa ||
      !dataprevtarefa ||
      !ambienteenumitemdetalhado ||
      !usuarioSelecionado.value ||
      !idaxuiliarrev
    ) {
      setAlertWarning(true);
      return;
    }

    const crudinsert = {
      nometarefadetalhada: nometarefadetalhada,
      datainicialtarefa: datainicialtarefa,
      dataprevisaotarefa: dataprevtarefa,
      ambienteenum: ambienteenumitemdetalhado,
      usuarioresponsavel: usuarioSelecionado.value,
      itemprojeto: { id: idaxuiliarrev },
    };

    try {
      setLoading(true);

      formData.append('itemdetalhadoprojeto', new Blob([JSON.stringify(crudinsert)], { type: 'application/json' }));

      // Adiciona os arquivos ao FormData com validação de extensão
      droppedFiles.forEach((file, index) => {
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
          alert(`Extensão de arquivo não permitida: ${file.name}`);
          setLoading(false);
          setDroppedFiles([]);
          throw new Error('Extensão de arquivo não permitida.');
        }

        formData.append('conteudoAnexo', file, file.name);
      });

      const response = await fetch(`${API_BASE_URL}projeto/itemdetalhadoprojeto/itemprojeto/${idaxuiliarrev}`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });


      setDroppedFiles([]);

      // Aqui, após o POST ser bem-sucedido, fazemos o GET
      axiosInstance
        .get(`projeto/itemdetalhadoprojeto/itemprojeto/${idaxuiliarrev}`)
        .then((response2) => {
          const adjustedRows = response2.data.map((item) => ({
            ...item,
            id: String(item.id),
          }));

          setParametro(idaxuiliarrev);
          setRows(adjustedRows);
          setLoading(false);
          setDroppedFiles([]);
          visualizardetalhamento(idaxuiliarrev);
        })
        .catch((error) => {
          // Tratar erros do GET, se necessário
          setLoading(false);
        });
    } catch (error) {
      // Tratar erros do POST, se necessário
      setLoading(false);
    }
  }

  const closeModal = () => {
    setModalOpen(false);
  };

  const closeModalAlterarItemProjeto = () => {
    setModalOpenAuxiliar(false);
  };

  const closemodalmensagem = () => {
    setvalidacampos(false);
  };

  async function salvarItem() {
    try {

      const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'png'];
      const formData = new FormData();
      const token = JSON.parse(localStorage.getItem('jwttoken'))?.jwttoken;

      const crudinsert = {
        nometarefa: novatarefa,
        datainicial: Datainicial,
        datafinal: Datafinal,
        usuarioresponsavel: usuarioresponsavel,
        ambienteenum: ambienteenum,
        projeto: {
          id: id,
        },
      };



      formData.append('itemprojeto', new Blob([JSON.stringify(crudinsert)], { type: 'application/json' }));

      // Adiciona os arquivos ao FormData com validação de extensão
      droppedFiles.forEach((file, index) => {
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
          alert(`Extensão de arquivo não permitida: ${file.name}`);
          setLoading(false);
          throw new Error('Extensão de arquivo não permitida.');
        }

        formData.append('conteudoAnexo', file, file.name);
      });


      // Envie a solicitação para salvar o item
      const response = await fetch(`${API_BASE_URL}projeto/itemprojeto`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });


      if (response.ok) {

        setCrudSuccess(true);


        setModalOpen(false);


        visualizardetalhamentoAuxiliar();


        setTimeout(() => {
          setCrudSuccess(false);
        }, 1000);


        const response2 = await axiosInstance.get(`/projeto/itemprojeto/${id}`);


        setlistaItemProjeto(response2.data);


        if (response2.data.totalElements === 0 || response2.data.length === 0) {
          setSituacaoProjeto(true);
        } else {
          setSituacaoProjeto(false);
        }


        setnovatarefa('');
        setDatainicial('');
        setdatafinal('');
        setusuarioresponsavelauxiliar('');
        setDroppedFiles([]);

      } else {

        const data = await response.json();
        alert('Erro ao salvar o item: ' + data.message);
      }
    } catch (error) {

      setvalidacampos(true);
      setvalidacaocamposstring(error.response?.data?.message || 'Erro ao salvar o item.');
    }
  }


  async function updateItem(idaux) {
    const crudinsert = {
      nometarefa: novatarefaauxiliar,

      datainicial: Datainicialauxiliar,
      datafinal: Datafinalauxiliar,
      usuarioresponsavel: usuarioresponsavelauxiliar,
      ambienteenum: statusenumalteraritemprojeto,

      projeto: {
        id: parseInt(id),
      },
    };

    try {
      const response = await axiosInstance.put(
        `/projeto/itemprojeto/` + idauxiliar,
        crudinsert
      );

      setCrudSuccess(true);
      setModalOpen(false);

      setTimeout(() => {
        setCrudSuccess(false);
      }, 1000);

      setModalOpenAuxiliar(false);

      const response2 = await axiosInstance.get(`/projeto/itemprojeto/${id}`);
      console.log(response2.data);
      if (response2.data.totalElements === 0 || response2.data.length === 0) {
        setSituacaoProjeto(true);
      } else {
        setlistaItemProjeto(response2.data);
      }
    } catch (error) {
      setvalidacampos(true);
      setvalidacaocamposstring(error.response.data.message);
    }
  }

  const stylealertEditProjetoAux = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "90%",
    bgcolor: "#ffffff",
    border: "2px solid #ffffff",
    boxShadow: 4,
    pt: 2,
    px: 4,
    pb: 3,
    overflowY: "auto",
    maxHeight: "85%",
  };

  const stylealertEditProjeto = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%", // Ajuste a largura conforme necessário
    bgcolor: "#ffffff",
    border: "2px solid #ffffff",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const stylealert = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800, // Ajuste a largura conforme necessário
    bgcolor: "#ffffff",
    border: "2px solid #ffffff",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };


  const stylealertAuxuiliarModalItem = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '76%',
    maxHeight: '70%', // Use 'maxHeight' em vez de 'maxheight'
    bgcolor: "#ffffff",
    border: "2px solid #ffffff",
    boxShadow: '10%',
    pt: 2,
    px: 4,
    pb: 3,
    overflowY: 'auto',
  }



  const style = {
    position: "absolute",
    top: "50%",
    left: "52%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#ffffff",
    border: "2px solid #ffffff",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const stylePermissao = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#ffffff", // Cor de fundo
    border: "2px solid #ffffff",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    zIndex: 9999,
  };


  const styleComponente = {
    position: "absolute",
    top: "50%",
    left: "52%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#ffffff",
    border: "2px solid #ffffff",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const editarItem = () => {
    setModalEditItem(true);
  };

  const voltarProjeto = () => {
    setModalEditItem(false);
  };

  const handleCloseMensagemDelete = () => {
    setmensagemerrordeleteitemgerenciamento(false);
  }

  const handleCloseProjetoMensagem = () => {
    setmensagelfechamentoprojeto(false);
  }

  const adjustFrontEndTimezone = (dateString) => {
    const date = new Date(dateString);
    date.setHours(date.getHours());
    return date.toISOString().slice(0, 16);
  };

  async function updateProjeto(idaux) {
    const crudinsert = {
      nomeprojeto: nomeprojeto,
      inicioprojeto: adjustFrontEndTimezone(inicioprojeto),
      previsaodoprojeto: adjustFrontEndTimezone(previsaodoprojeto),
      previsaoprojetodias: diasProjeto,
      responsavelprojeto: responsavelprojeto,
      descricaoprojeto: descricaoprojeto,
      ambienteenum: ambienteenum,
      grupousuario: { id: newIntGrupo.idsetor },
    };



    try {
      const response = await axiosInstance.put(`projeto/${idaux}`, crudinsert);

      setModalEditItem(false);

      setCrudSuccess(true);

      setTimeout(() => {
        setCrudSuccess(false);
      }, 1000);
    } catch (error) {
      // Lida com erros aqui.
      setCrudSuccess(false);
      setLoading(false);
    }
  }

  const handleClose = () => {
    setCrudSuccess(false);
  };

  const handleCloseItem = () => {
    setAlertWarning(false);
  };

  const handleDeleteClose = () => {
    setDeletado(false);
  };

  async function deletaritemgerenciamento(idaux) {



    try {
      const response = await axiosInstance.delete(
        `projeto/itemprojeto/` + idaux
      );


      const response2 = await axiosInstance.get(`/projeto/itemprojeto/${id}`);

      setlistaItemProjeto(response2.data);

      if (response.data.totalElements === 0 || response.data.length === 0) {
        setSituacaoProjeto(true);
      } else {
        setlistaItemProjeto(response2.data);
      }

      setDroppedFiles([]);



    } catch (error) {


      if (error.response && (error.response.status === 500 || error.response.status === '500')) {
        setmensagemerrordeleteitemgerenciamento(true);

      }
    }
  }

  async function visualizardetalhamento(idaux) {


    setidaxuiliarrev(idaux);

    setLoading(true);
    setModalEditItemDetalhado(true);
    try {
      const response = await axiosInstance.get(
        `projeto/itemdetalhadoprojeto/itemprojeto/` + idaux
      );

      const adjustedRows = response.data.map((item) => ({
        ...item,
        id: String(item.id),
      }));

      if (adjustedRows.length === 0) {
        setNenhumRegistro(true);
      } else {
        setNenhumRegistro(false);
      }

      setParametro(idaux);

      setRows(adjustedRows);

      console.log("Response data:", response.data);
      console.log("Adjusted rows:", adjustedRows);
      console.log("First row:", adjustedRows[0]);

      setSituacaoProjetoAux(adjustedRows.length === 0);

      setNenhumRegistro(adjustedRows.length === 0 ? true : false);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function visualizardetalhamentoAuxiliar(idaux) {


    try {
      const response = await axiosInstance.get(
        `projeto/itemprojeto/buscargrupoid/${id}/${idaux}`
      );

      const response2 = await axiosInstance.get(`/projeto/itemprojeto/anexoprojeto/${idaux}`)
      setanexolistItem(response2.data);

      setidauxiliar(response.data[0].id);
      setnovatarefaauxiliar(response.data[0].nometarefa);
      setDatainicialauxiliar(response.data[0].datainicial);
      setDatafinalauxiliar(response.data[0].dataprevisaotarefa);

      setusuarioresponsavelauxiliar(response.data[0].usuarioresponsavel);

      const valorInicial = response.data[0].ambienteenum;
      const capitalizedValue =
        valorInicial.charAt(0).toUpperCase() +
        valorInicial.slice(1).toLowerCase();
      setStatusTarefaauxiiar([
        { label: capitalizedValue, value: capitalizedValue },
      ]);

      setstatusenumalteraritemprojeto(valorInicial);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function deletarDetalhamento() { }



  async function visualizardetalhamentodoitem(iditem, idaux) {

    //iditem, idaux

    try {
      const response = await axiosInstance.get(
        `/projeto/itemdetalhadoprojeto/itemprojeto/${iditem}/${idaux}`
      );




      setidsubitemprojeto(response.data[0].id);
      setnometarefadetalhadasubitem(response.data[0].nometarefadetalhada);
      setdatainicialtarefasubitem(response.data[0].datainicialtarefa);
      setdataprevtarefasubitem(response.data[0].dataprevisaotarefa);
      setstatustarefaitemprojeto(response.data[0].ambienteenum);
      setusuariotarefaitemprojeto(response.data[0].usuarioresponsavel);
      setidauxiliarparaitemprojeto(idaux);
      setidauxiliarparaitemprojetodefault(iditem);


    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function updatesubitemprojeto(idaux) {

    idaux = idauxiliarparaitemprojeto;

    const crudinsert = {
      nometarefadetalhada: nometarefadetalhadasubitem,
      datainicialtarefa: datainicialtarefasubitem,
      dataprevisaotarefa: dataprevtarefasubitem,
      ambienteenum: statustarefaitemprojeto,
      usuarioresponsavel: 'Leonardo Fukuda',
      itemprojeto: { id: idaxuiliarrev },
    };


    const response = await axiosInstance.put(`projeto/itemdetalhadoprojeto/itemprojeto/${idaux}`, crudinsert);


    setDroppedFiles([]);


    onCloseModal(idaux);


  }


  async function editDetalhamento(iditem, idaux) {

    setidauxiliarsubitemprojeto(idaux);


    setModalEditItemDetalhado(false);
    setModalItemDetalhado(true);

    visualizardetalhamentodoitem(iditem, idaux);

    loadinganexoprojetoitemsubitem(idaux);
  }

  async function onCloseModal(idaux) {
    setModalItemDetalhado(false);
    setModalEditItemDetalhado(true);

    visualizardetalhamento(idauxiliarparaitemprojetodefault);
  }


  async function voltarProjetoAux() {
    setUsuarioSelecionado(null);
    setModalEditItemDetalhado(false);
  }

  async function alteraritemgerenciamento(idx) {


    setModalOpenAuxiliar(true);

    visualizardetalhamentoAuxiliar(idx);
  }

  async function finalizarProjeto(idaux) {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`projeto/fecharprojeto/` + idaux);

      if (response.status !== 201 && response.status !== 200) {
        alert("Precisa validar os itens se estão fechados");
      } else {
        setLoading(false);
        setmensagelfechamentoprojeto(true);
      }
    } catch (error) {
      setLoading(false);
      setmensagelfechamentoprojeto(true);
    }
  }


  async function downloadAnexo(id, tipoarquivo, nomearquivo) {
    const response = await axiosInstance.get(`projeto/lerpdf/${id}`, { responseType: 'blob' });

    if (response && response.data) {
      const fileType = getFileType(tipoarquivo);
      const file = new Blob([response.data], { type: fileType });

      downloadFile(file, nomearquivo);
    }
  }

  function getFileType(tipoarquivo) {
    return FILE_TYPES[tipoarquivo] || 'application/octet-stream';
  }

  function downloadFile(file, nomearquivo) {
    const link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
    link.href = URL.createObjectURL(file);
    link.download = nomearquivo;
    link.click();
  }



  function formatBytes(bytes) {
    return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
  }

  const [draggingx, setDraggingx] = useState(false);
  const [AlertaQuantidadeMB, setAlertaQuantidadeMB] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [totalSize, setTotalSize] = useState(0);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDraggingx(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDraggingx(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDraggingx(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDraggingx(false);
    const files = Array.from(e.dataTransfer.files);
    handleFiles(files);
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...droppedFiles];

    if (newFiles[index]) {
      const removedFile = newFiles.splice(index, 1)[0];
      setDroppedFiles(newFiles);
      setTotalSize(totalSize - removedFile.size);
      console.log("Arquivo removido com sucesso!");
    } else {
      console.log("Nenhum arquivo encontrado para remover nesta posição.");
    }
  };



  const handleFileInput = (e) => {
    const files = Array.from(e.target.files);

    const invalidFiles = files.filter(file => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      return !allowedExtensions.includes(fileExtension);
    });

    if (invalidFiles.length > 0) {
      setAlertaFormato(true);
      return;
    }


    handleFiles(files);


    e.target.value = null;
  };


  const handleFiles = (files) => {
    const newFiles = [...droppedFiles, ...files];
    setDroppedFiles(newFiles);
    setTotalSize(newFiles.reduce((total, file) => total + file.size, 0));
  };

  async function loadinganexoprojetoauxiliar() {
    const response = await axiosInstance.get("/projeto/anexoprojeto/" + id)
    setanexolist(response.data);

  }
  async function deletarAnexo(idxrow) {
    setLoading(true);

    const response = await axiosInstance.delete(`anexoprojeto/` + idxrow);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    loadinganexoprojetoauxiliar();
    setDroppedFiles([]);
  }

  async function deletarAnexoDeletado(idxrow) {


    const response = await axiosInstance.delete(`anexaritemprojetochamado/` + idxrow);

    visualizardetalhamentoAuxiliar(parseInt(idauxiliar));

    setDroppedFiles([]);
  }

  async function salvarAnexo() {
    setLoading(true);

    const crudinsert = {
      nomearquivo: 'nome',
      tipoArquivo: 'zzz',
      projeto: { id: id },
    };

    const token = JSON.parse(localStorage.getItem('jwttoken'))?.jwttoken;

    const formData = new FormData();
    formData.append('anexojson', new Blob([JSON.stringify(crudinsert)], { type: 'application/json' }));


    if (droppedFiles && droppedFiles.length > 0) {

      const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'png'];

      for (const file of droppedFiles) {
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {

          alert(`Extensão de arquivo não permitida: ${file.name}`);
          setLoading(false);
          return;
        }

        formData.append('anexo', file, file.name);
      }


      const response = await fetch(`${API_BASE_URL}anexoprojeto/`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setDroppedFiles([]);
      setTimeout(() => {
        setLoading(false);
      }, 500);
      loadinganexoprojetoauxiliar();

    } else {

      alert('O conteúdo está vazio. Adicione arquivos antes de salvar.');
      handleRemoveFile();
      setLoading(false);
    }


  }

  async function salvarAnexoItem() {
    var itemdoprojetoid = parseInt(idauxiliar)
    const crudinsert = {
      nomearquivo: 'nome',
      tipoArquivo: 'zzz',
      itemprojeto: { id: itemdoprojetoid },
    };
    const token = JSON.parse(localStorage.getItem('jwttoken'))?.jwttoken;
    const formData = new FormData();
    formData.append('anexojson', new Blob([JSON.stringify(crudinsert)], { type: 'application/json' }));
    if (droppedFiles && droppedFiles.length > 0) {
      const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'png'];
      for (const file of droppedFiles) {
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {

          alert(`Extensão de arquivo não permitida: ${file.name}`);
          setLoading(false);
          return;
        }

        formData.append('anexo', file, file.name);
      }

      const response = await fetch(`${API_BASE_URL}anexaritemprojetochamado`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      visualizardetalhamentoAuxiliar(parseInt(idauxiliar));
      setDroppedFiles([]);
    } else {
      alert('O conteúdo está vazio. Adicione arquivos antes de salvar.');
      handleRemoveFile();
    }
  }


  async function salvarAnexoItemDetalhado() {
    var itemdoprojetoid = parseInt(idsubitemprojeto)

    const crudinsert = {
      nomearquivo: 'nome',
      tipoArquivo: 'zzz',
      itemdetalhadoprojeto: { id: itemdoprojetoid },
    };
    const token = JSON.parse(localStorage.getItem('jwttoken'))?.jwttoken;
    const formData = new FormData();
    formData.append('anexojson', new Blob([JSON.stringify(crudinsert)], { type: 'application/json' }));
    if (droppedFiles && droppedFiles.length > 0) {
      const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'png'];
      for (const file of droppedFiles) {
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {

          alert(`Extensão de arquivo não permitida: ${file.name}`);
          setLoading(false);
          return;
        }

        formData.append('anexo', file, file.name);
      }

      const response = await fetch(`${API_BASE_URL}anexaritemprojetodetalhadochamado`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      loadinganexoprojetoitemsubitem(itemdoprojetoid);

      setDroppedFiles([]);
    } else {
      alert('O conteúdo está vazio. Adicione arquivos antes de salvar.');
      handleRemoveFile();
    }
  }


  const formatarData = (dataString) => {
    const data = new Date(dataString);

    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    const hora = data.getHours().toString().padStart(2, '0');
    const minuto = data.getMinutes().toString().padStart(2, '0');

    return `${dia}/${mes}/${ano} ${hora}:${minuto}`;
  };

  const adjustTimezone = (dateString, timezoneOffset) => {
    const date = new Date(dateString);
    const adjustedDate = new Date(date.getTime() - timezoneOffset * 60000);
    return adjustedDate.toISOString().slice(0, 16);
  };

  async function downloadAnexoSubitem(idsubitemprojeto, tipoarquivo, nomearquivo) {
    const response = await axiosInstance.get(`projeto/itemdetalhadoprojeto/itemprojeto/lerpdf/${idsubitemprojeto}`, { responseType: 'blob' });

    if (response && response.data) {
      const fileType = getFileType(tipoarquivo);
      const file = new Blob([response.data], { type: fileType });

      downloadFile(file, nomearquivo);
    }
  }


  async function downloadAnexoItemProjeto(idsubitemprojeto, tipoarquivo, nomearquivo) {
    const response = await axiosInstance.get(`projeto/itemprojeto/lerpdf/${idsubitemprojeto}`, { responseType: 'blob' });

    if (response && response.data) {
      const fileType = getFileType(tipoarquivo);
      const file = new Blob([response.data], { type: fileType });

      downloadFile(file, nomearquivo);
    }
  }
  async function deletarAnexoSubItem(idsubitem) {

    const response = await axiosInstance.delete(`anexaritemprojetodetalhadochamado/${idsubitem}`)

    const response2 = await axiosInstance.get(`projeto/itemdetalhadoprojeto/itemprojeto/anexoprojeto/${parseInt(idauxiliarsubitemprojeto)}`)
    setanexolistitemdetalhado(response2.data);

    setDroppedFiles([]);
  }



  return (
    <div className="wrapper">
      <Header />
      <SideBar />
      <div className="content-wrapper">
        <div>
          <div class="card-header">
            <section class="content-header">
              <h1>
                <i class="fa fa-plus-square fa-1x cor-padrao"></i> Gerenciamento
                de Projeto - {id}
                <small></small>
              </h1>
              <ol class="breadcrumb">
                <li>
                  <i class="fa fa-home"></i> Portal JCR
                </li>

                <li class="active">
                  <i class="fa fa-reorder"></i> Gerenciamento de Projeto
                </li>
              </ol>
            </section>
          </div>
        </div>

        <div class="box-body">
          {loading ? (
            <Dialog
              open={true}
              aria-describedby="alert-dialog-slide-description"
            >
              <div className="pos-center">
                <Spinner />
              </div>
            </Dialog>
          ) : (
            <div class="box-body">
              <div className="content">
                <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger">
                  <div
                    id="edicaoCategoriaChamadoForm:painelSubcategoria_content"
                    class="ui-panel-content ui-widget-content"
                  >
                    <div class="box-header with-border">
                      <div class="form-horizontal">
                        <div class="box-body">
                          <div className="col-md-11">
                            <Tabs
                              defaultActiveKey="GerenciamentoProjeto"
                              transition={false}
                              id="noanim-tab-example"
                              className="mb-3"
                            >
                              <Tab
                                eventKey="GerenciamentoProjeto"
                                title={
                                  <>
                                    <i className="fa fa-gear"></i> Gerenciamento
                                    de Projeto
                                  </>
                                }
                              >
                                <div
                                  className="d-flex justify-content-end align-items-center"
                                  style={{ height: "50px" }}
                                >
                                  <img
                                    src={VisualizarIMG}
                                    onClick={() => editarItem()}
                                    width="25"
                                    height="25"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>

                                {ModalEditItem ? (
                                  <Modal open={ModalEditItem}>
                                    <Box sx={stylealertEditProjeto}>
                                      <h4
                                        id="parent-modal-title"
                                        className="font"
                                      >
                                        Editar Projeto
                                      </h4>
                                      <div className="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger"></div>

                                      <div>
                                        <div className="form-group">
                                          <div className="row">
                                            <div className="col-md-2">
                                              <label htmlFor="selectPrioridade">
                                                Nome do Projeto:*
                                              </label>
                                            </div>
                                            <div className="col-md-10">
                                              <input
                                                id="nomeprojetoForm:titulo"
                                                type="text"
                                                maxLength="60"
                                                onChange={(e) =>
                                                  setnomeprojeto(e.target.value)
                                                }
                                                className="form-control w-100"
                                                value={nomeprojeto}
                                              />
                                            </div>
                                          </div>

                                          <div className="row col-sm-12 mt-3">
                                            <div className="col-sm-12 col-md-2">
                                              <label htmlFor="selectPrioridade">
                                                Inicio do Projeto:*
                                              </label>
                                            </div>
                                            <div className="col-sm-12 col-md-4">
                                              <input
                                                type="datetime-local"
                                                onChange={(e) => {

                                                  setInicioProjeto(e.target.value);
                                                }}
                                                min="2022-01-01"
                                                defaultValue={inicioprojeto ? adjustTimezone(inicioprojeto, +180) : ""}


                                                max="2030-01-01"
                                              />
                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                              <label htmlFor="selectPrioridade">
                                                Previsão do Projeto:*
                                              </label>
                                            </div>
                                            <div className="col-sm-12 col-md-4">
                                              <input
                                                type="datetime-local"
                                                defaultValue={
                                                  previsaodoprojeto ? adjustTimezone(previsaodoprojeto, +180) : ""
                                                }
                                                onChange={
                                                  (e) =>
                                                    setprevisaodoprojeto(
                                                      e.target.value
                                                    )

                                                }
                                                min="2022-01-01"
                                                max="2030-01-01"
                                              />
                                            </div>
                                          </div>

                                          <div className="row col-sm-12 mt-4">
                                            <div className="col-sm-12 col-md-2">
                                              <label htmlFor="selectPrioridade">
                                                Dias Projeto: *
                                              </label>
                                            </div>
                                            <div className="col-sm-12 col-md-8">
                                              <input
                                                type="text"
                                                disabled
                                                value={
                                                  previsaoprojetodias === 1
                                                    ? previsaoprojetodias +
                                                    " DIA"
                                                    : previsaoprojetodias +
                                                    " DIAS"
                                                }
                                                className="form-control"
                                              />
                                            </div>
                                          </div>

                                          <div className="row col-sm-12 mt-3">
                                            <div className="col-sm-12 col-md-2">
                                              <label htmlFor="selectPrioridade">
                                                Setor*
                                              </label>
                                            </div>
                                            <div className="col-sm-12 col-md-8">
                                              <input
                                                id="setoraberturaForm:titulo"
                                                type="text"
                                                maxLength="60"
                                                value={usuariosetor}
                                                disabled
                                                name="nomeprojetoForm:titulo"
                                                className="form-control"
                                              />
                                            </div>
                                          </div>

                                          <div className="row col-sm-12 mt-3">
                                            <div className="col-sm-12 col-md-2">
                                              <label htmlFor="selectPrioridade">
                                                Responsavel pelo Projeto:*
                                              </label>
                                            </div>
                                            <div className="col-sm-12 col-md-8">
                                              <input
                                                id="chamadoForm:titulo"
                                                type="text"
                                                value={responsavelprojeto}
                                                onChange={(e) =>
                                                  setresponsavelprojeto(
                                                    e.target.value
                                                  )
                                                }
                                                maxLength="60"
                                                name="chamadoForm:titulo"
                                                className="form-control"
                                              />
                                            </div>
                                          </div>

                                          <div className="row col-sm-12 mt-3">
                                            <div className="col-md-2">
                                              <label htmlFor="selectPrioridade">
                                                Status da Tarefa Alt:*
                                              </label>
                                            </div>
                                            <div className="col-md-8">
                                              <Select
                                                options={opcoesStatusAuxiliar}
                                                value={
                                                  opcoesStatusAuxiliar.find(
                                                    (option) =>
                                                      option.value ===
                                                      ambienteenum
                                                  ) || null
                                                }
                                                onChange={handleStatusChangeAuxiliar}
                                                placeholder="Selecione um status"
                                              />
                                            </div>
                                          </div>

                                          <div className="row col-sm-12 mt-3">
                                            <div className="col-sm-12 col-md-2">
                                              <label for="selectPrioridade">
                                                Descrição do Projeto:
                                              </label>
                                            </div>
                                            <div className="col-sm-12 col-md-8">
                                              <textarea
                                                onChange={(e) =>
                                                  setdescricaoprojeto(
                                                    e.target.value
                                                  )
                                                }
                                                oninput="countText()"
                                                value={descricaoprojeto}
                                                maxlength="1000"
                                                name="chamadoForm:descricao"
                                                class="form-control"
                                                rows="5"
                                              ></textarea>
                                            </div>
                                          </div>

                                          <div
                                            style={{ marginTop: "45%" }}
                                          ></div>
                                        </div>
                                        <br></br> <br></br>
                                        <div className="">
                                          <div class="col-sm-12 col-md-6">
                                            <button
                                              type="button"
                                              class="btn btn-block btn-danger btn-lg"
                                              onClick={() => voltarProjeto()}
                                            >
                                              <i class="fa fa-chevron-left"></i>{" "}
                                              VOLTAR
                                            </button>
                                          </div>

                                          <div class="col-sm-12 col-md-6">
                                            <button
                                              type="button"
                                              class="btn btn-block btn-primary btn-lg"
                                              onClick={() => updateProjeto(id)}
                                            >
                                              <i class="fa fa-save"></i> SALVAR
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </Box>
                                  </Modal>
                                ) : null}
                                <div className="form-group">
                                  <div className="row">
                                    <div class="col-sm-2 col-md-2 control-label">
                                      <label for="solicitante">
                                        Nome do Projeto:
                                      </label>
                                    </div>
                                    <div class="col-sm-2 col-md-2 control-label">
                                      <p for="solicitante">{nomeprojeto}</p>
                                    </div>

                                    <div class="col-sm-2 col-md-2 control-label">
                                      <label for="solicitante">
                                        Inicio do Projeto:
                                      </label>
                                    </div>
                                    <div class="col-sm-2 col-md-2 control-label">
                                      <p for="solicitante">{formatarData(inicioprojeto)}</p>
                                    </div>

                                    <div class="col-sm-2 col-md-2 control-label">
                                      <label for="solicitante">
                                        Previsão do Projeto:
                                      </label>
                                    </div>
                                    <div class="col-sm-2 col-md-2 control-label">
                                      <p for="solicitante">
                                        {formatarData(previsaodoprojeto)}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div class="col-sm-2 col-md-2 control-label">
                                      <label for="solicitante">
                                        Responsável pelo Projeto:
                                      </label>
                                    </div>
                                    <div class="col-sm-2 col-md-2 control-label">
                                      <p for="solicitante">
                                        {responsavelprojeto}
                                      </p>
                                    </div>

                                    <div class="col-sm-2 col-md-2 control-label">
                                      <label for="ambientenumptext">
                                        Status do Projeto:
                                      </label>
                                    </div>
                                    <div class="col-sm-2 col-md-2 control-label">
                                      <p
                                        for="ambientenump"
                                        className={
                                          ambienteenum === "NAOINICIADA"
                                            ? "not-finished"
                                            : ambienteenum === "EMANDAMENTO"
                                              ? "not-finished-yellow"
                                              : ambienteenum === "FINALIZADA"
                                                ? "not-finished-green"
                                                : ambienteenum === "ENTREGUE"
                                                  ? "Entregue"
                                                  : null
                                        }
                                      >
                                        {ambienteenum === "NAOINICIADA"
                                          ? "Não Iniciada"
                                          : ambienteenum === "EMANDAMENTO"
                                            ? "Em Andamento"
                                            : ambienteenum === "FINALIZADA"
                                              ? "Finalizada"
                                              : ambienteenum === "ENTREGUE"
                                                ? "Entregue"
                                                : null}
                                      </p>
                                    </div>

                                    <div class="col-sm-2 col-md-2 control-label">
                                      <label for="solicitante">
                                        Previsão em Dias:
                                      </label>
                                    </div>
                                    <div class="col-sm-2 col-md-2 control-label">
                                      <p for="solicitante">
                                        {previsaoprojetodias > 1
                                          ? previsaoprojetodias + " Dias"
                                          : previsaoprojetodias + "Dia"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div class="col-sm-2 col-md-2 control-label">
                                      <label for="solicitante">
                                        Data Final do Projeto:
                                      </label>
                                    </div>
                                    <div class="col-sm-2 col-md-2 control-label">
                                      <p for="solicitante">
                                        {fimdoprojeto === "" ||
                                          fimdoprojeto === null
                                          ? "Data não finalizada"
                                          : fimdoprojeto}
                                      </p>
                                    </div>

                                    <div class="col-sm-2 col-md-2 control-label">
                                      <label for="solicitante">
                                        Final do Projeto em Dias:
                                      </label>
                                    </div>
                                    <div class="col-sm-2 col-md-2 control-label">
                                      <p for="solicitante">
                                        {fimdoprojetodias === "" ||
                                          fimdoprojetodias === null
                                          ? "Data não finalizada para conter os dias "
                                          : fimdoprojetodias}
                                      </p>
                                    </div>

                                    <div class="col-sm-2 col-md-2 control-label">
                                      <label for="solicitante">
                                        Atrasado em Dias:
                                      </label>
                                    </div>
                                    <div className="col-sm-2 col-md-2 control-label">
                                      {daysOverdue > 0 ? (
                                        <p
                                          className="not-finished"
                                          htmlFor="solicitante"
                                        >
                                          {daysOverdue > 1
                                            ? `${daysOverdue} Dias`
                                            : `${daysOverdue} Dia`}
                                        </p>
                                      ) : (
                                        <p>Sem atraso</p> // Texto a ser exibido quando não há atraso
                                      )}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <br></br>
                                    <div class="col-sm-2 col-md-2 control-label">
                                      <label for="descricao">Descrição:</label>
                                    </div>
                                    <div class="col-sm-10 col-md-10">
                                      <textarea
                                        class="form-control"
                                        readonly="readonly"
                                        rows="5"
                                        value={
                                          descricaoprojeto === null ||
                                            descricaoprojeto === ""
                                            ? "Não preenchimento nesse campo"
                                            : descricaoprojeto
                                        }
                                      ></textarea>
                                    </div>
                                  </div>
                                  <br></br>

                                  <div className="row ">

                                    <div class="col-sm-2 col-md-2 control-label">
                                      <label for="descricao">Adicionar Anexo:</label>
                                    </div>


                                    <div class="col-sm-10 col-md-10">

                                      <div className="card ui-panel ui-widget ui-widget-content ui-corner-all box box-danger">
                                        <br></br>
                                        <div class="d-flex justify-content-between">

                                          <div class="col-sm-4 col-md-4 control-label text-center">
                                            <label htmlFor="fileInput" className="btn btn-block btn-danger btn-lg custom-button">
                                              Selecionar arquivo para Anexar
                                            </label>
                                            <input
                                              type="file"
                                              id="fileInput"
                                              style={{ display: 'none' }}
                                              onChange={handleFileInput}
                                              multiple
                                            />
                                          </div>
                                          <div class="col-sm-4 col-md-4 control-label text-center">
                                            <label className="btn btn-block btn-primary btn-lg" onClick={() => salvarAnexo()}> Salvar Anexo</label>
                                          </div>

                                        </div>
                                        <br></br>
                                        <div
                                          className={`drop-container ${draggingx ? 'draggingx' : ''}`}
                                          onDragEnter={handleDragEnter}
                                          onDragLeave={handleDragLeave}
                                          onDragOver={handleDragOver}
                                          onDrop={handleDrop}
                                        >
                                          {droppedFiles.length > 0 ? (
                                            <div>
                                              <p>Arquivos no container:</p>
                                              <ul>
                                                {droppedFiles.map((file, index) => (
                                                  <li key={index}>
                                                    {file.name} ({formatBytes(file.size)}){' '}
                                                    <span className="close-icon" onClick={() => handleRemoveFile(index)}>
                                                      &#x2716;
                                                    </span>
                                                  </li>
                                                ))}
                                              </ul>
                                              <p>Tamanho total: {formatBytes(totalSize)}</p>
                                            </div>
                                          ) : (
                                            <p>ou arraste e solte os arquivos aqui (limite: 20 MB)</p>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                  </div>




                                  <div className="row ">


                                    <div class="col-sm-2 col-md-2 control-label">
                                      <label for="descricao">Anexos:</label>
                                    </div>
                                    <div class="col-sm-10 col-md-10">
                                      <div class="card ui-panel ui-widget ui-widget-content ui-corner-all box box-danger scroolvertical">
                                        <Table className="table table-striped table-bordered table-resizable " data-toggle="tooltip" stickyHeader aria-label="sticky table">

                                          <TableHead >


                                            <TableRow>
                                              <TableCell sx={{ fontWeight: 'bold' }} align="center">Nome do Arquivo</TableCell>
                                              <TableCell sx={{ fontWeight: 'bold' }} align="center">Download</TableCell>
                                              <TableCell sx={{ fontWeight: 'bold' }} align="center">Excluir</TableCell>


                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {anexolist.length === 0 ? (
                                              <TableRow>
                                                <TableCell colSpan={3} align="center">
                                                  Nenhum Registro encontrado
                                                </TableCell>
                                              </TableRow>
                                            ) : (

                                              anexolist.map((row) => (

                                                <TableRow
                                                  key={row.id}
                                                  sx={{ border: 100, width: 100 }}
                                                >

                                                  <TableCell size="small" component="th" scope="row" align="center">{row.nomearquivo}</TableCell>
                                                  <TableCell size="small" component="th" scope="row" align="center">
                                                    <a >
                                                      <img src={AnexoIcon} width="30" height="30"

                                                        onClick={() => downloadAnexo(row.id, row.tipoArquivo, row.nomearquivo)}
                                                      >
                                                      </img>
                                                    </a>

                                                  </TableCell>
                                                  <TableCell size="small" component="th" scope="row" align="center">
                                                    <a>
                                                      <img src={LixeiraItemIMG}
                                                        width="30"
                                                        height="30"
                                                        onClick={() => deletarAnexo(row.id)}
                                                      ></img>
                                                    </a>
                                                  </TableCell>
                                                </TableRow>
                                              ))
                                            )}
                                          </TableBody>
                                        </Table>
                                      </div>
                                    </div>

                                  </div>

                                  <div className="row">
                                    <div class="col-sm-12 col-md-6">
                                      <button
                                        type="button"
                                        class="btn btn-block btn-danger btn-lg"
                                        onClick={() => voltar()}
                                      >
                                        <i class="fa fa-chevron-left"></i>{" "}
                                        VOLTAR
                                      </button>
                                    </div>

                                    {ambienteenum === "EMANDAMENTO" ? <div class="col-sm-12 col-md-6">
                                      <button
                                        type="button"
                                        class="btn btn-block btn-success btn-lg"
                                        onClick={() => finalizarProjeto(id)}
                                      >
                                        <i class="fa fa-sign-out "></i>{" "}
                                        FINALIZAR PROJETO
                                      </button>
                                    </div> : null}
                                  </div>
                                </div>
                              </Tab>

                              <Tab
                                eventKey="ItemGerenciamento"
                                activeKey={activeKey}
                                onSelect={(k) => setActiveKey(k)}
                                transition={false}
                                id="itemgerenciamentoaba"
                                className="mb-3"
                                title={
                                  <div>
                                    <i className="fa fa-list"></i> Item de Gerenciamento
                                  </div>
                                }
                              >
                                <br></br>
                                <div className="row form-group ml-3">
                                  <div className="col-sm-12 col-md-6 ">
                                    <button
                                      type="button"
                                      className="btn btn-block btn-danger btn-lg"
                                      onClick={() => voltar()}
                                    >
                                      <i className="fa fa-chevron-left"></i>{" "}
                                      VOLTAR
                                    </button>
                                  </div>
                                  <div className="col-sm-12 col-md-6">
                                    <button
                                      type="button"
                                      className="btn btn-block btn-primary btn-lg"
                                      onClick={() => incluiritemprojeto()}
                                    >
                                      <i className="fa fa-plus"></i> INCLUIR
                                      ITEM
                                    </button>
                                  </div>
                                </div>
                                <br></br><br></br><br></br>
                                <div className="my-4 ml-3">
                                  <DragDropContext onDragEnd={handleDragEndItemGerenciamento}>
                                    <Droppable droppableId="droppable-2">
                                      {(provided) => (
                                        <div className="table-container" ref={provided.innerRef} {...provided.droppableProps}>
                                          <table className="table bordered">
                                            <thead>
                                              <tr>
                                                <th scope="col" className="text-center">Identificador Item </th>
                                                <th scope="col" className="text-center">Ordem</th>
                                                <th scope="col" className="text-center">Nome da Tarefa</th>
                                                <th scope="col" className="text-center">Data Inicial</th>
                                                <th scope="col" className="text-center">Data Prev Tarefa</th>
                                                <th scope="col" className="text-center">Duração em Dias</th>
                                                <th scope="col" className="text-center">Status do Projeto</th>
                                                <th scope="col" className="text-center">Responsável pelo Projeto</th>
                                                <th scope="col" className="text-center">Visualizar</th>
                                                <th scope="col" className="text-center">Alterar Item</th>
                                                <th scope="col" className="text-center">Deletar Item</th>
                                              </tr>
                                            </thead>
                                            <tbody className="text-capitalize">

                                              {listaItemProjeto.length === 0 ? (
                                                <tr>
                                                  <tr>
                                                    <td colSpan="12">&nbsp;</td>
                                                  </tr>
                                                  <td colSpan="12" className="text-center font-weight-bold">
                                                    Nenhum Registro Encontrado
                                                  </td>
                                                </tr>
                                              ) : (
                                                listaItemProjeto.map((row, index) => (
                                                  <Draggable key={row.ordem.toString()} draggableId={row.ordem.toString()} index={index}>
                                                    {(provided) => (
                                                      <tr
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                      >
                                                        <td className="text-center">{row.id}</td>
                                                        <td className="text-center">{row.ordem}</td>
                                                        <td className="text-center">{row.nometarefa}</td>
                                                        <td className="text-center">{moment(row.datainicial).format('DD/MM/YYYY HH:mm')}</td>
                                                        <td className="text-center">
                                                          {row.dataprevisaotarefa === "" || row.dataprevisaotarefa === null
                                                            ? "Tarefa não Finalizada"
                                                            : moment(row.dataprevisaotarefa).format('DD/MM/YYYY HH:mm')}
                                                        </td>
                                                        <td className="text-center">
                                                          {row.duracaodias === 0 || row.duracaodias <= 1
                                                            ? row.duracaodias + " Dia "
                                                            : row.duracaodias > 1
                                                              ? row.duracaodias + " Dias"
                                                              : ""}
                                                        </td>
                                                        <td className={`text-center ${row.ambienteenum === "EMANDAMENTO" ? "emAndamento" : row.ambienteenum === "NAOINICIADA" ? "naoIniciada" : row.ambienteenum === "FINALIZADA" ? "finalizada" : row.ambienteenum === "ENTREGUE" ? "entregue" : ""}`}>{row.ambienteenum === "EMANDAMENTO" ? "Em Andamento" : row.ambienteenum === "NAOINICIADA" ? "Não Iniciada" : row.ambienteenum === "FINALIZADA" ? "Finalizada" : row.ambienteenum === "ENTREGUE" ? "Entregue" : "Status Desconhecido Favor Entrar em contato com suporte"}
                                                        </td>
                                                        <td className="text-center">{row.usuarioresponsavel}</td>
                                                        <td className="text-center">
                                                          <img
                                                            src={VisualizarItemIMG} onClick={() => visualizardetalhamento(row.id)} width="25" height="25" style={{ cursor: "pointer" }} alt="Visualizar"
                                                          />
                                                        </td>
                                                        <td className="text-center">
                                                          <img
                                                            src={VisualizarIMG}
                                                            onClick={() => alteraritemgerenciamento(row.id)}
                                                            width="25"
                                                            height="25"
                                                            style={{ cursor: "pointer" }}
                                                            alt="Alterar"
                                                          />
                                                        </td>
                                                        <td className="text-center">
                                                          <img
                                                            src={LixeiraItemIMG}
                                                            onClick={() => deletaritemgerenciamento(row.id)}
                                                            width="25"
                                                            height="25"
                                                            style={{ cursor: "pointer" }}
                                                            alt="Deletar"
                                                          />
                                                        </td>
                                                      </tr>
                                                    )}
                                                  </Draggable>
                                                ))
                                              )}
                                            </tbody>
                                          </table></div>
                                      )}
                                    </Droppable>
                                  </DragDropContext>
                                </div>
                                <br></br>
                                <br></br>

                                {ModalItemDetalhado ? (
                                  <div >
                                    <Modal
                                      open={true}
                                      aria-labelledby="parent-modal-title"
                                      aria-describedby="parent-modal-description"

                                    >
                                      <Box sx={stylealertAuxuiliarModalItem} >
                                        <h4
                                          id="parent-modal-title"
                                          className="font"
                                        >
                                          Alterar Sub Item do Projeto
                                        </h4>

                                        <br></br>
                                        <div className="">

                                          <div className="form-group">
                                            <div className="row">
                                              <div className="col-sm-12 col-md-3 control-label">
                                                <label htmlFor="nomeprojetoForm:titulo">Nome do Projeto: *</label>
                                              </div>
                                              <div className="col-sm-12 col-md-9 control-label">
                                                <input
                                                  id="nomeprojetoForm:titulo"
                                                  type="text"
                                                  maxLength="100"
                                                  className="form-control"
                                                  value={nometarefadetalhadasubitem}
                                                  onChange={(e) => setnometarefadetalhadasubitem(e.target.value)}
                                                  style={{ marginBottom: '20px' }}
                                                />
                                              </div>
                                            </div>

                                            <div className="row">
                                              <div className="col-sm-12 col-md-3 control-label">
                                                <label htmlFor="dataInicial">Data Inicial: *</label>
                                              </div>
                                              <div className="col-sm-2 col-md-2 control-label">
                                                <input
                                                  type="datetime-local"
                                                  onChange={
                                                    handleInicioProjetoChangeSubItem ===
                                                      null
                                                      ? (e) =>
                                                        setdatainicialtarefasubitem(
                                                          e.target.value
                                                        )
                                                      : handleInicioProjetoChangeSubItem
                                                  }
                                                  min="2022-01-01"
                                                  defaultValue={formatToISODate(
                                                    datainicialtarefasubitem
                                                  )}
                                                  max="2030-01-01"
                                                />
                                              </div>

                                              <div className="col-sm-3 col-md-1 control-label">
                                                <label htmlFor="dataPrevista">Data Prev: *</label>
                                              </div>
                                              <div className="col-sm-2 col-md-2 control-label">
                                                <input
                                                  type="datetime-local"
                                                  onChange={
                                                    handlePrevProjetoChangeSubItem ===
                                                      null
                                                      ? (e) =>
                                                        setdataprevtarefasubitem(
                                                          e.target.value
                                                        )
                                                      : handlePrevProjetoChangeSubItem
                                                  }
                                                  min="2022-01-01"
                                                  defaultValue={formatToISODate(
                                                    dataprevtarefasubitem
                                                  )}
                                                  max="2030-01-01"
                                                />
                                              </div>

                                              <div className="col-sm-3 col-md-1 control-label">
                                                <label htmlFor="dataFinal">Data Final: *</label>
                                              </div>
                                              <div className="col-sm-2 col-md-3 control-label">
                                                <input
                                                  type="datetime-local"
                                                  id="dataFinal"
                                                  className="form-control"
                                                  disabled
                                                  min="2022-01-01"
                                                  max="2030-01-01"

                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <div className="row">
                                              <div className="col-sm-12 col-md-3 control-label">
                                                <label htmlFor="nomeprojetoForm:titulo">Status da Tarefa: *</label>
                                              </div>
                                              <div className="col-sm-12 col-md-9 control-label">
                                                <Select
                                                  options={opcoesStatus}

                                                  value={
                                                    opcoesStatus.find((option) =>
                                                      option.value ===
                                                      statustarefaitemprojeto
                                                    ) || null}
                                                  onChange={handleStatusChangeItemProjeto}
                                                  placeholder="Selecione um status"
                                                />
                                              </div>
                                            </div>
                                          </div>


                                          <div className="form-group">
                                            <div className="row">
                                              <div className="col-sm-12 col-md-3 control-label">
                                                <label htmlFor="nomeprojetoForm:titulo">Usuário Responsável: *</label>
                                              </div>
                                              <div className="col-sm-12 col-md-9 control-label">
                                                <Select
                                                  options={usuario.map((usuarios, index) => ({
                                                    label: usuarios.nomecomplemento,
                                                    value: usuarios.nomecomplemento,
                                                    key: index,
                                                  }))}
                                                  value={
                                                    (() => {
                                                      return { label: usuariotarefaitemprojeto };
                                                    })()
                                                  }
                                                  onChange={handleSelectChangeSubItem}
                                                  placeholder="Selecione um Usuário"
                                                />

                                              </div>
                                            </div>
                                          </div>

                                          <div className="row ">

                                            <div class="col-sm-2 col-md-1 control-label">
                                              <label for="descricao">Adicionar Anexo:</label>
                                            </div>


                                            <div class="col-sm-10 col-md-10">

                                              <div className="card ui-panel ui-widget ui-widget-content ui-corner-all box box-danger">
                                                <br></br>
                                                <div class="d-flex justify-content-between">

                                                  <div class="col-sm-5 col-md-5 control-label text-center">
                                                    <label htmlFor="fileInput" className="btn btn-block btn-danger btn-lg custom-button">
                                                      Selecionar Anexo
                                                    </label>
                                                    <input
                                                      type="file"
                                                      id="fileInput"
                                                      style={{ display: 'none' }}
                                                      onChange={handleFileInput}
                                                      multiple
                                                    />
                                                  </div>
                                                  <div class="col-sm-4 col-md-4 control-label text-center">
                                                    <label className="btn btn-block btn-primary btn-lg" onClick={() => salvarAnexoItemDetalhado()}> Salvar Anexo</label>
                                                  </div>

                                                </div>
                                                <br></br>
                                                <div
                                                  className={`drop-container ${draggingx ? 'draggingx' : ''}`}
                                                  onDragEnter={handleDragEnter}
                                                  onDragLeave={handleDragLeave}
                                                  onDragOver={handleDragOver}
                                                  onDrop={handleDrop}
                                                >
                                                  {droppedFiles.length > 0 ? (
                                                    <div>
                                                      <p>Arquivos no container:</p>
                                                      <ul>
                                                        {droppedFiles.map((file, index) => (
                                                          <li key={index}>
                                                            {file.name} ({formatBytes(file.size)}){' '}
                                                            <span className="close-icon" onClick={() => handleRemoveFile(index)}>
                                                              &#x2716;
                                                            </span>
                                                          </li>
                                                        ))}
                                                      </ul>
                                                      <p>Tamanho total: {formatBytes(totalSize)}</p>
                                                    </div>
                                                  ) : (
                                                    <p>ou arraste e solte os arquivos aqui (limite: 20 MB)</p>
                                                  )}
                                                </div>
                                              </div>
                                            </div>

                                          </div>


                                          <div class="col-sm-12 col-md-3 control-label">
                                            <label for="descricao">Anexos:</label>
                                          </div>
                                          <div class="col-sm-12 col-md-9 control-label">
                                            <div class="card ui-panel ui-widget ui-widget-content ui-corner-all box box-danger scroolvertical">
                                              <Table className="table table-striped table-bordered table-resizable " data-toggle="tooltip" stickyHeader aria-label="sticky table">

                                                <TableHead >


                                                  <TableRow>
                                                    <TableCell sx={{ fontWeight: 'bold' }} align="center">Nome do Arquivo</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold' }} align="center">Download</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold' }} align="center">Excluir</TableCell>


                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {anexolistitemdetalhado.length === 0 ? (
                                                    <TableRow>
                                                      <TableCell colSpan={3} align="center">
                                                        Nenhum Registro encontrado
                                                      </TableCell>
                                                    </TableRow>
                                                  ) : (

                                                    anexolistitemdetalhado.map((row) => (

                                                      <TableRow
                                                        key={row.id}
                                                        sx={{ border: 100, width: 100 }}
                                                      >

                                                        <TableCell size="small" component="th" scope="row" align="center">{row.nomearquivo}</TableCell>
                                                        <TableCell size="small" component="th" scope="row" align="center">
                                                          <a >
                                                            <img src={AnexoIcon} width="30" height="30"

                                                              onClick={() => downloadAnexoSubitem(row.id, row.tipoArquivo, row.nomearquivo)}
                                                            >
                                                            </img>
                                                          </a>

                                                        </TableCell>
                                                        <TableCell size="small" component="th" scope="row" align="center">
                                                          <a>
                                                            <img src={LixeiraItemIMG}
                                                              width="30"
                                                              height="30"
                                                              onClick={() => deletarAnexoSubItem(row.id)}
                                                            ></img>
                                                          </a>
                                                        </TableCell>
                                                      </TableRow>
                                                    ))
                                                  )}
                                                </TableBody>
                                              </Table>
                                            </div>
                                          </div>


                                          <div className="row">
                                            <div className="col-md-6 text-center">
                                              <button type="button" className="btn btn-danger btn-lg btn-block" onClick={() => onCloseModal()}>
                                                Sair
                                              </button>
                                            </div>
                                            <div className="col-md-6 text-center">
                                              <button type="button" className="btn btn-primary btn-lg btn-block" onClick={() => updatesubitemprojeto(idsubitemprojeto)}>
                                                Salvar
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </Box>
                                    </Modal>
                                  </div>
                                ) : null}

                                {
                                  <Modal open={ModalEditItemDetalhado}>
                                    <Box sx={stylealertEditProjetoAux}>
                                      <div class="box-body">
                                        <h3>
                                          <i class="fa fa-plus-square fa-1x cor-padrao"></i>{" "}
                                          Item Detalhado
                                          <small></small>
                                        </h3>
                                        <div className="content">
                                          <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger">
                                            <div
                                              id="edicaoCategoriaChamadoForm:painelSubcategoria_content"
                                              class="ui-panel-content ui-widget-content"
                                            >
                                              <div class="box-header with-border">
                                                <div className="form-group">
                                                  <div className="row">
                                                    <div className="col-md-2">
                                                      <label htmlFor="selectPrioridade">
                                                        Nome da Tarefa:*
                                                      </label>
                                                    </div>
                                                    <div className="col-md-8">
                                                      <input
                                                        id="nomeprojetoForm:titulo"
                                                        type="text"
                                                        maxLength="60"
                                                        className="form-control w-100"
                                                        onChange={(e) =>
                                                          setnometarefadetalhada(
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form-group">
                                                  <div className="row">
                                                    <div className="col-md-2">
                                                      <label htmlFor="selectPrioridade">
                                                        Data Inicial do
                                                        Projeto:*{" "}
                                                      </label>
                                                    </div>
                                                    <div className="col-md-8">
                                                      <Stack spacing={8}>
                                                        <input
                                                          type="datetime-local"
                                                          min="2022-01-01"
                                                          onChange={(e) =>
                                                            setdatainicialtarefa(
                                                              e.target.value
                                                            )
                                                          }
                                                          max="2030-01-01"
                                                        />
                                                        <label
                                                          htmlFor="selectPrioridade"
                                                          className="projeto-label"
                                                          style={{
                                                            marginLeft: "1%",
                                                            wordBreak:
                                                              "break-word",
                                                            wordWrap:
                                                              "break-word",
                                                          }}
                                                        >
                                                          Data Previsao do
                                                          Projeto:*{" "}
                                                        </label>
                                                        <input
                                                          type="datetime-local"
                                                          onChange={(e) =>
                                                            setdataprevtarefa(
                                                              e.target.value
                                                            )
                                                          }
                                                          min="2022-01-01"
                                                          max="2030-01-01"
                                                        />

                                                        <label
                                                          htmlFor="selectPrioridade"
                                                          className="projeto-label"
                                                          style={{
                                                            marginLeft: "2%",
                                                            wordBreak:
                                                              "break-word",
                                                            wordWrap:
                                                              "break-word",
                                                          }}
                                                        >
                                                          Data Final do Projeto:
                                                        </label>
                                                        <input
                                                          type="datetime-local"
                                                          style={{
                                                            marginLeft: "1%",
                                                          }}
                                                          min="2022-01-01"
                                                          disabled
                                                          max="2030-01-01"
                                                        />
                                                      </Stack>
                                                    </div>
                                                  </div>
                                                </div>
                                                <br></br>
                                                <div className="form-group">
                                                  <div className="row">
                                                    <div className="col-md-2">
                                                      <label htmlFor="selectPrioridade">
                                                        Status da Tarefa:*
                                                      </label>
                                                    </div>
                                                    <div className="col-md-8">
                                                      <Select
                                                        options={opcoesStatus}

                                                        onChange={
                                                          handleStatusItemDetalhado
                                                        }
                                                        placeholder="Selecione um status"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form-group">
                                                  <div className="row">
                                                    <div className="col-md-2">
                                                      <label htmlFor="selectPrioridadeX">
                                                        Usuário Responsável:*
                                                      </label>
                                                    </div>

                                                    <div className="col-md-8">
                                                      <Select
                                                        options={usuario.map(
                                                          (
                                                            usuarios,
                                                            index
                                                          ) => ({
                                                            label:
                                                              usuarios.nomecomplemento,
                                                            value:
                                                              usuarios.nomecomplemento,
                                                            key: index,
                                                          })
                                                        )}
                                                        value={
                                                          usuarioSelecionado
                                                        }
                                                        onChange={
                                                          handleSelectChange
                                                        }
                                                        placeholder={
                                                          "Selecione um Usuário"
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>



                                                <div className="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger">
                                                  <div className="card">
                                                    <br />
                                                    <div className="col-sm-6 col-md-6 control-label text-center">
                                                      <label htmlFor="fileInput" className="btn btn-block btn-danger btn-lg custom-button">
                                                        Selecionar arquivos para Anexar
                                                      </label>
                                                      <input
                                                        type="file"
                                                        id="fileInput"
                                                        style={{ display: 'none' }}
                                                        onChange={handleFileInput}
                                                        multiple
                                                      />
                                                      <br />
                                                    </div>

                                                    <div
                                                      className={`drop-container ${draggingx ? 'draggingx' : ''}`}
                                                      onDragEnter={handleDragEnter}
                                                      onDragLeave={handleDragLeave}
                                                      onDragOver={handleDragOver}
                                                      onDrop={handleDrop}
                                                    >
                                                      {droppedFiles.length > 0 ? (
                                                        <div>
                                                          <p>Arquivos no container:</p>
                                                          <ul>
                                                            {droppedFiles.map((file, index) => (
                                                              <li key={index}>
                                                                {file.name} ({formatBytes(file.size)}){' '}
                                                                <span className="close-icon" onClick={() => handleRemoveFile(index)}>
                                                                  &#x2716;
                                                                </span>
                                                              </li>
                                                            ))}
                                                          </ul>
                                                          <p>Tamanho total: {formatBytes(totalSize)}</p>
                                                        </div>
                                                      ) : (
                                                        <p>ou arraste e solte os arquivos aqui (limite: 20 MB) </p>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6 ">
                                                  <button
                                                    type="button"
                                                    className="btn btn-block btn-danger btn-lg"
                                                    onClick={() =>
                                                      voltarProjetoAux()
                                                    }
                                                  >
                                                    <i className="fa fa-close"></i>{" "}
                                                    SAIR
                                                  </button>
                                                </div>
                                                <div className="col-sm-12 col-md-6 ">
                                                  <button
                                                    type="button"
                                                    className="btn btn-block btn-primary btn-lg"
                                                    onClick={() =>
                                                      incluiritemdetalhadoProjeto()
                                                    }
                                                  >
                                                    <i className="fa fa-plus"></i>{" "}
                                                    INCLUIR SUB ITEM
                                                  </button>
                                                </div>
                                                <br></br> <br></br> <br></br>
                                                <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger">
                                                  <div
                                                    id="edicaoCategoriaChamadoForm:painelSubcategoria_content"
                                                    class="ui-panel-content ui-widget-content"
                                                  >
                                                    <div class="box-header with-border">
                                                      <div class="form-horizontal">
                                                        <div class="box-body">
                                                          <div>
                                                            {NenhumRegistro ? (
                                                              <div>
                                                                <p
                                                                  style={{
                                                                    marginLeft:
                                                                      "38%",
                                                                  }}
                                                                >
                                                                  Nenhum
                                                                  Registro foi
                                                                  Encontrado !
                                                                </p>
                                                              </div>
                                                            ) : (
                                                              <DragDropContext onDragEnd={handleDragEnd}>
                                                                <Droppable droppableId="droppable-2">
                                                                  {(provided) => (
                                                                    <div className="table-container" ref={provided.innerRef} {...provided.droppableProps}>
                                                                      <table className="table bordered">
                                                                        <thead>
                                                                          <tr>
                                                                            <th></th>
                                                                            <th>Ordem</th>
                                                                            <th>Nome da Tarefa</th>
                                                                            <th>Status da Tarefa</th>
                                                                            <th>Data Inicial</th>
                                                                            <th>Data Final</th>
                                                                            <th>Previsão da Entrega</th>
                                                                            <th>Usuário Responsável</th>
                                                                            <th>Editar</th>
                                                                            <th>Deletar</th>
                                                                          </tr>
                                                                        </thead>

                                                                        <tbody className="text-capitalize">
                                                                          {rows.map((user, index) => (
                                                                            <Draggable key={user.ordem.toString()} draggableId={user.ordem.toString()} index={index}>
                                                                              {(provided) => (
                                                                                <tr
                                                                                  ref={provided.innerRef}
                                                                                  {...provided.draggableProps}
                                                                                  {...provided.dragHandleProps}
                                                                                >
                                                                                  <td> = </td>
                                                                                  <td>{user.ordem}</td>
                                                                                  <td>{user.nometarefadetalhada}</td>
                                                                                  <td className={user.ambienteenum === "EMANDAMENTO" ? "emAndamento" : user.ambienteenum === "NAOINICIADA" ? "naoIniciada" : user.ambienteenum === "FINALIZADA" ? "finalizada" : user.ambienteenum === "ENTREGUE" ? "entregue" : ""}>
                                                                                    {user.ambienteenum === "EMANDAMENTO" ? "Em Andamento" : user.ambienteenum === "NAOINICIADA" ? "Não Iniciada" : user.ambienteenum === "FINALIZADA" ? "Finalizada" : user.ambienteenum === "ENTREGUE" ? "Entregue" : "Status Desconhecido. Favor entrar em contato com suporte"}
                                                                                  </td>
                                                                                  <td>{user.datainicialtarefa ? format(new Date(user.datainicialtarefa), "dd/MM/yyyy HH:mm") : " "}</td>
                                                                                  <td>{user.datafinaldtarefa ? format(new Date(user.datafinaldtarefa), "dd/MM/yyyy HH:mm") : " "}</td>
                                                                                  <td>{format(new Date(user.dataprevisaotarefa), "dd/MM/yyyy HH:mm")}</td>
                                                                                  <td>{user.usuarioresponsavel}</td>
                                                                                  <td><img width="25" height="25" onClick={() => editDetalhamento(user.itemprojeto.id, user.id)} src={VisualizarItemIMG} alt="Editar" /></td>
                                                                                  <td><img width="25" height="25" onClick={() => deletaritemdetalhadoProjeto(user.id)} src={LixeiraItemIMG} alt="Deletar" /></td>
                                                                                </tr>
                                                                              )}
                                                                            </Draggable>
                                                                          ))}
                                                                        </tbody>
                                                                      </table>
                                                                      {provided.placeholder}
                                                                    </div>
                                                                  )}
                                                                </Droppable>
                                                              </DragDropContext>

                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Box>
                                  </Modal>
                                }

                                <Modal
                                  open={mensagemerrordeleteitemgerenciamento}
                                  onClose={handleCloseMensagemDelete}
                                  aria-labelledby="parent-modal-title"
                                  aria-describedby="parent-modal-description"
                                >
                                  <Box sx={{ ...style, width: 500 }}>
                                    <h2>
                                      <img
                                        src={WarningImg}
                                        width="30"
                                        height="30"
                                      ></img>{" "}
                                      Existe Item do projeto em andamento, altere os status sub item do projeto , para não iniciada para poder deletar, ou delete subitem do projeto
                                    </h2>
                                  </Box>
                                </Modal>

                                <Modal
                                  open={mensagelfechamentoprojeto}
                                  onClose={handleCloseProjetoMensagem}
                                  aria-labelledby="parent-modal-title"
                                  aria-describedby="parent-modal-description"
                                >
                                  <Box sx={{ ...style, width: 500 }}>
                                    <h2>
                                      <img
                                        src={WarningImg}
                                        width="30"
                                        height="30"
                                      ></img>{" "}
                                      Este Projeto - {id} ainda está em andamento. Conclua todos os status "Em Andamento" para fechado ou resolvido, a fim de finalizar o projeto.
                                    </h2>
                                  </Box>
                                </Modal>

                                {ModalOpen ? (
                                  <Modal
                                    open={ModalOpen}
                                    aria-labelledby="parent-modal-title"
                                    aria-describedby="parent-modal-description"
                                  >
                                    <Box sx={stylealert}>
                                      <h4
                                        id="parent-modal-title"
                                        className="font"
                                      >
                                        Incluir um novo Item Do Projeto
                                      </h4>
                                      <div className="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger"></div>
                                      <div className="row">
                                        <div className="col-sm-12 col-md-3 control-label">
                                          <label htmlFor="selectPrioridade">
                                            Nova Tarefa: *
                                          </label>
                                        </div>
                                        <div className="col-sm-12 col-md-9 control-label">
                                          <input
                                            id="nomeprojetoForm:titulo"
                                            type="text"
                                            onChange={(e) =>
                                              setnovatarefa(e.target.value)
                                            }
                                            maxLength="60"
                                            className="form-control"
                                          />
                                        </div>
                                      </div>
                                      <br></br>
                                      <div className="row">
                                        <div className="col-sm-4 col-md-3 control-label">
                                          <label htmlFor="selectPrioridade">
                                            Inicio do Projeto: *
                                          </label>
                                        </div>
                                        <div className="col-3 col-md-3">
                                          <Stack spacing={8}>
                                            <input
                                              type="datetime-local"
                                              min="2022-01-01"
                                              max="2030-01-01"

                                              onChange={(e) =>
                                                setDatainicial(e.target.value)
                                              }
                                            />
                                          </Stack>
                                        </div>
                                        <div className="col-1 col-md-1">
                                          <p>Até</p>
                                        </div>
                                        <div className="col-1 col-md-1">
                                          <Stack spacing={8}>
                                            <input
                                              type="datetime-local"
                                              min="2022-01-01"
                                              max="2030-01-01"

                                              onChange={(e) =>
                                                setdatafinal(e.target.value)
                                              }
                                            />
                                          </Stack>
                                        </div>
                                      </div>
                                      <br></br>
                                      <div className="row">
                                        <div className="col-sm-12 col-md-3 control-label">
                                          <label htmlFor="selectPrioridade">
                                            Projeto: *
                                          </label>
                                        </div>
                                        <div className="col-sm-12 col-md-9 control-label">
                                          <input
                                            id="nomeprojetoForm:titulo"
                                            type="text"
                                            disabled
                                            maxLength="60"
                                            className="form-control"
                                            onChange={handleNomeChange}
                                            value={nomeprojeto}
                                          />
                                        </div>
                                      </div>

                                      <br></br>
                                      <div className="row">
                                        <div className="col-sm-12 col-md-3 control-label">
                                          <label htmlFor="selectPrioridade">
                                            Status da Tarefa:*
                                          </label>
                                        </div>
                                        <div className="col-sm-12 col-md-9 control-label">
                                          <Select
                                            options={opcoesStatus}

                                            onChange={handleStatusChange}
                                            placeholder="Selecione um status"
                                          />
                                        </div>
                                      </div>

                                      <br></br>

                                      <div className="row">
                                        <div className="col-sm-12 col-md-3 control-label">
                                          <label htmlFor="selectPrioridade">
                                            Usuário Responsável: *
                                          </label>
                                        </div>
                                        <div className="col-sm-12 col-md-9 control-label">
                                          <input
                                            id="nomeprojetoForm:titulo"
                                            type="text"
                                            onChange={(e) =>
                                              setUsuarioResponsavel(
                                                e.target.value
                                              )
                                            }
                                            maxLength="60"
                                            className="form-control"
                                          />

                                        </div>
                                        <br />
                                      </div>
                                      <br />
                                      <div className="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger">
                                        <div className="card">
                                          <br />
                                          <div className="col-sm-6 col-md-6 control-label text-center">
                                            <label htmlFor="fileInput" className="btn btn-block btn-danger btn-lg custom-button">
                                              Selecionar arquivos PDF
                                            </label>
                                            <input
                                              type="file"
                                              id="fileInput"
                                              style={{ display: 'none' }}
                                              onChange={handleFileInput}
                                              multiple
                                            />
                                            <br />
                                          </div>

                                          <div
                                            className={`drop-container ${draggingx ? 'draggingx' : ''}`}
                                            onDragEnter={handleDragEnter}
                                            onDragLeave={handleDragLeave}
                                            onDragOver={handleDragOver}
                                            onDrop={handleDrop}
                                          >
                                            {droppedFiles.length > 0 ? (
                                              <div>
                                                <p>Arquivos no container:</p>
                                                <ul>
                                                  {droppedFiles.map((file, index) => (
                                                    <li key={index}>
                                                      {file.name} ({formatBytes(file.size)}){' '}
                                                      <span className="close-icon" onClick={() => handleRemoveFile(index)}>
                                                        &#x2716;
                                                      </span>
                                                    </li>
                                                  ))}
                                                </ul>
                                                <p>Tamanho total: {formatBytes(totalSize)}</p>
                                              </div>
                                            ) : (
                                              <p>ou arraste e solte os PDFs aqui (limite: 20 MB)</p>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <br></br>
                                      <br></br>
                                      <br></br>
                                      <div className="row">
                                        <div className="col-sm-12 col-md-6">
                                          <button
                                            type="button"
                                            className="btn btn-block btn-danger btn-lg"
                                            onClick={() => closeModal()}
                                          >
                                            <i className="fa fa-chevron-left"></i>{" "}
                                            VOLTAR
                                          </button>
                                        </div>

                                        <div className="col-sm-12 col-md-6 ">
                                          <button
                                            type="button"
                                            className="btn btn-block btn-primary btn-lg"
                                            onClick={() => salvarItem()}
                                          >
                                            <i className="fa fa-save"></i>{" "}
                                            SALVAR
                                          </button>
                                        </div>
                                      </div>
                                    </Box>
                                  </Modal>
                                ) : null}

                                {ModalOpenAuxiliar && (
                                  <Modal
                                    open={ModalOpenAuxiliar}
                                    aria-labelledby="parent-modal-title"
                                    aria-describedby="parent-modal-description"
                                  >
                                    <Box sx={stylealert}>
                                      <h4
                                        id="parent-modal-title"
                                        className="font"
                                      >
                                        Alterar Item do Projeto
                                      </h4>
                                      <div className="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger"></div>
                                      <div className="row">
                                        <div className="col-sm-12 col-md-3 control-label">
                                          <label htmlFor="selectPrioridade">
                                            Tarefa: *
                                          </label>
                                        </div>
                                        <div className="col-sm-12 col-md-9 control-label">
                                          <input
                                            id="nomeprojetoForm:tituloX"
                                            type="text"
                                            onChange={(e) =>
                                              setnovatarefaauxiliar(
                                                e.target.value
                                              )
                                            }
                                            value={novatarefaauxiliar}
                                            maxLength="60"
                                            className="form-control"
                                          />
                                        </div>
                                      </div>
                                      <br></br>
                                      <div className="row">
                                        <div className="col-sm-4 col-md-3 control-label">
                                          <label htmlFor="selectPrioridadeX">
                                            Inicio do Projeto: *
                                          </label>
                                        </div>
                                        <div className="col-3 col-md-3">
                                          <Stack spacing={8}>
                                            <input
                                              type="datetime-local"
                                              min="2022-01-01"
                                              max="2030-01-01"
                                              onChange={(e) =>
                                                setDatainicialauxiliar(
                                                  e.target.value
                                                )
                                              }
                                              defaultValue={formatToISODate(Datainicialauxiliar)}
                                            />
                                          </Stack>
                                        </div>
                                        <div className="col-1 col-md-1">
                                          <p>Até</p>
                                        </div>
                                        <div className="col-1 col-md-1">
                                          <Stack spacing={8}>
                                            <input
                                              type="datetime-local"
                                              min="2022-01-01"
                                              max="2030-01-01"
                                              defaultValue={formatToISODate(Datainicialauxiliar)}
                                              onChange={(e) =>
                                                setDatafinalauxiliar(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </Stack>
                                        </div>
                                      </div>
                                      <br></br>
                                      <div className="row">
                                        <div className="col-sm-12 col-md-3 control-label">
                                          <label htmlFor="selectPrioridade">
                                            Projeto: *
                                          </label>
                                        </div>
                                        <div className="col-sm-12 col-md-9 control-label">
                                          <input
                                            id="nomeprojetoForm:tituloX"
                                            type="text"
                                            disabled
                                            maxLength="60"
                                            className="form-control"
                                            onChange={handleNomeChange}
                                            value={nomeprojeto}
                                          />
                                        </div>
                                      </div>

                                      <br></br>
                                      <div className="row">
                                        <div className="col-sm-12 col-md-3 control-label">
                                          <label htmlFor="selectPrioridade">
                                            Status da Tarefa:*
                                          </label>
                                        </div>
                                        <div className="col-sm-12 col-md-9 control-label">
                                          <Select
                                            options={opcoesStatus}
                                            value={
                                              opcoesStatus.find((option) =>
                                                option.value ===
                                                statusenumalteraritemprojeto
                                              ) || null}

                                            onChange={handleStatusChange}
                                            placeholder="Selecione um status"
                                          />
                                        </div>
                                      </div>

                                      <br></br>
                                      <br></br>
                                      <div className="row">
                                        <div className="col-sm-12 col-md-3 control-label">
                                          <label htmlFor="selectPrioridade">
                                            Usuário Responsável: *
                                          </label>
                                        </div>
                                        <div className="col-sm-12 col-md-9 control-label">
                                          <input
                                            id="nomeprojetoForm:tituloX"
                                            type="text"
                                            onChange={(e) =>
                                              setusuarioresponsavelauxiliar(
                                                e.target.value
                                              )
                                            }
                                            value={usuarioresponsavelauxiliar}
                                            maxLength="60"
                                            className="form-control"
                                          />
                                        </div>
                                      </div>
                                      <br></br>
                                      <br></br>
                                      <div className="row ">

                                        <div class="col-sm-2 col-md-2 control-label">
                                          <label for="descricao">Adicionar Anexo:</label>
                                        </div>


                                        <div class="col-sm-10 col-md-10">

                                          <div className="card ui-panel ui-widget ui-widget-content ui-corner-all box box-danger">
                                            <br></br>
                                            <div class="d-flex justify-content-between">

                                              <div class="col-sm-5 col-md-5 control-label text-center">
                                                <label htmlFor="fileInput" className="btn btn-block btn-danger btn-lg custom-button">
                                                  Selecionar Anexo
                                                </label>
                                                <input
                                                  type="file"
                                                  id="fileInput"
                                                  style={{ display: 'none' }}
                                                  onChange={handleFileInput}
                                                  multiple
                                                />
                                              </div>
                                              <div class="col-sm-4 col-md-4 control-label text-center">
                                                <label className="btn btn-block btn-primary btn-lg" onClick={() => salvarAnexoItem()}> Salvar Anexo</label>
                                              </div>

                                            </div>
                                            <br></br>
                                            <div
                                              className={`drop-container ${draggingx ? 'draggingx' : ''}`}
                                              onDragEnter={handleDragEnter}
                                              onDragLeave={handleDragLeave}
                                              onDragOver={handleDragOver}
                                              onDrop={handleDrop}
                                            >
                                              {droppedFiles.length > 0 ? (
                                                <div>
                                                  <p>Arquivos no container:</p>
                                                  <ul>
                                                    {droppedFiles.map((file, index) => (
                                                      <li key={index}>
                                                        {file.name} ({formatBytes(file.size)}){' '}
                                                        <span className="close-icon" onClick={() => handleRemoveFile(index)}>
                                                          &#x2716;
                                                        </span>
                                                      </li>
                                                    ))}
                                                  </ul>
                                                  <p>Tamanho total: {formatBytes(totalSize)}</p>
                                                </div>
                                              ) : (
                                                <p>ou arraste e solte os arquivos aqui (limite: 20 MB)</p>
                                              )}
                                            </div>
                                          </div>
                                        </div>

                                      </div>




                                      <div className="row ">


                                        <div class="col-sm-2 col-md-2 control-label">
                                          <label for="descricao">Anexos:</label>
                                        </div>
                                        <div class="col-sm-10 col-md-10">
                                          <div class="card ui-panel ui-widget ui-widget-content ui-corner-all box box-danger scroolvertical">
                                            <Table className="table table-striped table-bordered table-resizable " data-toggle="tooltip" stickyHeader aria-label="sticky table">

                                              <TableHead >


                                                <TableRow>
                                                  <TableCell sx={{ fontWeight: 'bold' }} align="center">Nome do Arquivo</TableCell>
                                                  <TableCell sx={{ fontWeight: 'bold' }} align="center">Download</TableCell>
                                                  <TableCell sx={{ fontWeight: 'bold' }} align="center">Excluir</TableCell>


                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {anexolistItem.length === 0 ? (
                                                  <TableRow>
                                                    <TableCell colSpan={3} align="center">
                                                      Nenhum Registro encontrado
                                                    </TableCell>
                                                  </TableRow>
                                                ) : (

                                                  anexolistItem.map((row) => (

                                                    <TableRow
                                                      key={row.id}
                                                      sx={{ border: 100, width: 100 }}
                                                    >

                                                      <TableCell size="small" component="th" scope="row" align="center">{row.nomearquivo}</TableCell>
                                                      <TableCell size="small" component="th" scope="row" align="center">
                                                        <a >
                                                          <img src={AnexoIcon} width="30" height="30"

                                                            onClick={() => downloadAnexoItemProjeto(row.id, row.tipoArquivo, row.nomearquivo)}
                                                          >
                                                          </img>
                                                        </a>

                                                      </TableCell>
                                                      <TableCell size="small" component="th" scope="row" align="center">
                                                        <a>
                                                          <img src={LixeiraItemIMG}
                                                            width="30"
                                                            height="30"
                                                            onClick={() => deletarAnexoDeletado(row.id)}
                                                          ></img>
                                                        </a>
                                                      </TableCell>
                                                    </TableRow>
                                                  ))
                                                )}
                                              </TableBody>
                                            </Table>
                                          </div>
                                        </div>

                                      </div>




                                      <br></br>
                                      <div className="row">
                                        <div className="col-sm-12 col-md-6">
                                          <button
                                            type="button"
                                            className="btn btn-block btn-danger btn-lg"
                                            onClick={() =>
                                              closeModalAlterarItemProjeto()
                                            }
                                          >
                                            <i className="fa fa-chevron-left"></i>{" "}
                                            VOLTAR
                                          </button>
                                        </div>

                                        <div className="col-sm-12 col-md-6 ">
                                          <button
                                            type="button"
                                            className="btn btn-block btn-primary btn-lg"
                                            onClick={() =>
                                              updateItem(idauxiliar)
                                            }
                                          >
                                            <i className="fa fa-save"></i>{" "}
                                            SALVAR
                                          </button>
                                        </div>
                                      </div>
                                    </Box>
                                  </Modal>
                                )}



                              </Tab>
                              <Tab
                                eventKey="detalhamentodoprojetoview"
                                id="detalhamentoprojetoview"
                                onSelect={(k) => setActiveKey(k)}
                                title={
                                  <>
                                    <i className="fa fa-running"></i> Processo
                                    em Execução
                                  </>
                                }
                              >
                                <FluxoProjeto id={id} />
                              </Tab>

                              <Tab
                                eventKey="relatorio"
                                title={
                                  <>
                                    <i className="fa fa-file-text"></i>{" "}
                                    Relatório
                                  </>
                                }
                              >
                                <h1>Relatório</h1>
                              </Tab>
                            </Tabs>
                          </div>
                        </div>
                        <Modal
                          open={crudSuccess}
                          onClose={handleClose}
                          aria-labelledby="parent-modal-title"
                          aria-describedby="parent-modal-description"
                        >
                          <Box sx={{ ...style, width: 500 }}>
                            <h2>
                              <img
                                src={SuccessImg}
                                width="30"
                                height="30"
                              ></img>{" "}
                              Projeto Cadastrado com Sucesso !
                            </h2>
                          </Box>
                        </Modal>
                        <Modal
                          open={crudDeletado}
                          onClose={handleDeleteClose}
                          aria-labelledby="parent-modal-title"
                          aria-describedby="parent-modal-description"
                        >
                          <Box sx={{ ...style, width: 500 }}>
                            <h2>
                              <img
                                src={LixeiraItemIMG}
                                width="30"
                                height="30"
                              ></img>{" "}
                              Sub Item Deletado com Sucesso !
                            </h2>
                          </Box>
                        </Modal>


                        <Modal
                          open={AlertaFormato}
                          onClose={handleCloseAlertaFormato}
                          aria-labelledby="parent-modal-title"
                          aria-describedby="parent-modal-description"
                        >
                          <Box sx={{ ...style, width: 500 }}>
                            <h2>
                              <img src={WarningImg} width="30" height="30" alt="Warning"></img>{" "}
                              Extensão de arquivo não permitida
                            </h2>
                            <br />
                            <h3>Formatos aceitos:</h3>
                            <h4 style={{ whiteSpace: 'pre-line' }}>{allowedExtensions.join(', ')}</h4>

                          </Box>
                        </Modal>


                        <Modal
                          open={permissao}
                          onClose={handleClosePermissao}
                          aria-labelledby="parent-modal-title"
                          aria-describedby="parent-modal-description"
                          style={{ backdropFilter: 'none', backgroundColor: 'rgba(255, 255, 255, 1)' }} // Adicione essa linha para remover o desfoque e definir a cor de fundo do modal
                        >
                          <Box sx={stylePermissao}>
                            <h2>Seu Departamento não está associnado a esse tipo de Projeto</h2>
                            <br />
                            <h2>o Projeto {id} foi deletado ou não existe</h2>


                          </Box>
                        </Modal>

                        <Modal
                          open={AlertWarning}
                          aria-labelledby="parent-modal-title"
                          aria-describedby="parent-modal-description"
                        >
                          <BoxModal sx={{ ...style, width: 800 }}>
                            <img
                              src={WarningImg}
                              style={{ cursor: "auto" }}
                              width="30"
                              height="30"
                            ></img>
                            <img
                              src={CloseButtonIcon}
                              width="30"
                              onClick={handleCloseItem}
                              height="30"
                              style={{ marginLeft: "90%" }}
                            />

                            <h2>
                              Por favor, preencha todos os campos obrigatórios.
                            </h2>
                          </BoxModal>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
                {validarcampos === true ? (
                  <div>
                    <Modal
                      open={true}
                      close={closemodalmensagem}
                      aria-labelledby="parent-modal-title"
                      aria-describedby="parent-modal-description"
                    >
                      <Box sx={{ ...style, width: 500 }}>
                        <h2>
                          <img
                            src={CloseButtonIcon}
                            onClick={closemodalmensagem}
                            width="30"
                            height="30"
                          ></img>{" "}
                          Favor Preencher os campos ! Detalhamento no Log:{" "}
                          {validacaocamposstring}
                        </h2>
                      </Box>
                    </Modal>
                  </div>

                ) : null}


              </div>
            </div>
          )
          }
        </div >
      </div >
      <Footer />
    </div >
  );
}