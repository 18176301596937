import { Divider, Modal, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Footer from '../../../../common/template/footer';
import Header from '../../../../common/template/header';
import SideBar from '../../../../common/template/sideBar';
import axiosInstance from '../../../../config/api';

import AlterarIMG from '../../../../common/template/img/lapis.png';
import LixeiraIcon from '../../../../common/template/img/lixeira.png';
import Box from '@mui/material/Box';
import Spinner from '../../../../common/template/Spinner';

const loadingcss = {
    position: 'absolute',
    top: '50%',
    left: '52%'
}

export default function CategoriaChamado() {

    var newIntGrupo = JSON.parse(localStorage.getItem('idsetor'));

    var setorGrupo = JSON.parse(localStorage.getItem('setor'));


    var grupoID = newIntGrupo.idsetor;

    var setorparecer = setorGrupo.setor;

    const [nomecategoriadto, setnomecategoriadto] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [open, setOpen] = React.useState(false);

    const [alertstate, setAlertstate] = useState([]);
    const [alertConcluido, setAlertConcluido] = useState([]);

    const [loading, setLoading] = React.useState(undefined);

    async function voltar() {
        window.location.href = "/categoriachamadodswid=1912311"
    }

    async function novocadastro() {

        setLoading(true);

        const crudinsertuser = {
            nomecategoria: nomecategoriadto,
            grupousuario: { id: grupoID }
        }
        handleClose();
        const response = await axiosInstance.post('/categoriachamado/', crudinsertuser)
            .then(resp => {
                handleClose();
                setTimeout(() => { setAlertConcluido(false); }, 1000);
                setTimeout(() => { setAlertConcluido(true); }, 7500);
                setTimeout(() => { window.location.href = '/categoriachamadodswid=1912311'; }, 2000);

            })
            .catch(error => {
                handleClose();
                setTimeout(() => { setAlertstate(false); }, 2000);
                setTimeout(() => { setAlertstate(true); }, 5000);
            });
    }

    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <div>
                    <div class="card-header">

                        <section class="content-header">
                            <h1><i class="fa fa-plus-square fa-1x cor-padrao"></i> Categoria Chamado
                                <small></small>
                            </h1>
                            <ol class="breadcrumb">
                                <li><i class="fa fa-home"></i> Portal JCR</li>
                                <li><i class="fa fa-wrench"></i> Sistema de Chamado</li>
                                <li class="active"><i class="fa fa-reorder"></i> Categoria Chamado</li>
                            </ol>
                        </section>



                    </div>
                    <div className='content'>
                        <div class="card">
                        </div>
                        <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger"><div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" class="ui-panel-content ui-widget-content">
                            <div class="box-header with-border">
                                <div class="form-horizontal">
                                    <div className='content'>

                                        {loading === true ? (
                                            <div className="">
                                                <Modal
                                                    open={loading}
                                                    aria-labelledby="parent-modal-title"
                                                    aria-describedby="parent-modal-description"
                                                >
                                                    <Box sx={{ ...loadingcss, width: 400 }}>
                                                        <Spinner />
                                                    </Box>
                                                </Modal>
                                            </div>
                                        ) : (
                                            <div>

                                            </div>
                                        )}

                                        <div class="box-body">
                                            <h4>Categoria Chamado</h4>
                                            <Divider />
                                            <br></br>
                                            <div class="form-group">
                                                <div class="col-sm-3 col-md-3 control-label">
                                                    <label for="login">Nome Categoria: *</label>
                                                </div>
                                                <div class="col-sm-3 col-md-8 control-label">
                                                    <input id="chamadoForm:titulo" type="text"
                                                        onChange={es => setnomecategoriadto(es.target.value)}
                                                        name="chamadoForm:titulo" class="form-control" maxlength="100">
                                                    </input>
                                                </div>

                                            </div>

                                            <div class="form-group">
                                                <div class="col-sm-3 col-md-3 control-label">
                                                    <label for="login">Setor: *</label>
                                                </div>
                                                <div class="col-sm-3 col-md-8 control-label">
                                                    <input id="chamadoForm:titulo" type="text"
                                                        value={setorparecer}
                                                        readonly="readonly"
                                                        name="chamadoForm:titulo" class="form-control" maxlength="100">
                                                    </input>
                                                </div>



                                            </div>
                                            <div class="col-sm-2 col-md-6">
                                                <button type="button" class="btn btn-block btn-danger btn-lg"
                                                    onClick={() => voltar()}
                                                >
                                                    <i class="fa fa-chevron-left"></i> VOLTAR</button>
                                            </div>
                                            <div class="col-sm-2 col-md-6">
                                                <button type="button" class="btn btn-block btn-success btn-lg"
                                                    onClick={() => novocadastro()}
                                                >
                                                    <i class="fa fa-floppy-o"></i> Salvar</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}