import React from 'react'
import { Modal } from '@mui/material';
import Header from '../../../common/template/header'
import SideBar from '../../../common/template/sideBar'
import Footer from '../../../common/template/footer'
import Box from '@mui/material/Box';
import Tab from 'react-bootstrap/Tab';
import axiosInstance from '../../../config/api';
import Tabs from 'react-bootstrap/Tabs';
import { useState, useEffect } from 'react';
import WarningIcon from '../../../common/template/img/Warning.png';
import CloseButtonIcon from '../../../common/template/img/closebutton.png';

export default function NfeSefaz(props) {
  
    const [chaveAcesso, setChaveAcesso] = useState('');
    const [resultado, setResultado] = useState('');
    const [camposPreenchidos, setCamposPreenchidos] = useState(false);
    const [Coligada, setColigada] = React.useState([]);
    const [ComboColigadasFornecedor, setComboColigadasFornecedor] = React.useState([]);



    useEffect(() => {

        async function nomefantasiacoligada() {
            const response = await axiosInstance.get('/coligadas');
            setComboColigadasFornecedor(response.data);
        }
    

        nomefantasiacoligada();
    }, [chaveAcesso], []);


    function handleChange(event) {
        const value = event.target.value;
        const chaveSemEspacos = value.replace(/\s/g, ''); // remove todos os espaços em branco
        setChaveAcesso(chaveSemEspacos);
    }





    async function BuscarNsu() {
        if (chaveAcesso !== '' && Coligada !== '') {

            setCamposPreenchidos(false);
           
            setResultado(`O NSU da nota fiscal ${chaveAcesso} é ${Coligada}.`);
        } else {
            setCamposPreenchidos(true);
        }
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '52%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: '#ffffff',
        border: '2px solid #ffffff',
        boxShadow: 10,
        pt: 2,
        px: 4,
        pb: 3,



    };

    const handleClose = () => {
        setCamposPreenchidos(false);
    };


    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <div>
                    <div class="card-header">
                        <section class="content-header">
                            <h1><i class="fa fa-plus-square fa-1x cor-padrao"></i> Nfe - Config
                                <small></small>
                            </h1>
                            <ol class="breadcrumb">
                                <li><i class="fa fa-home"></i> Portal JCR</li>
                                <li><i class="fa fa-book"></i> Documento de Entrada</li>
                                <li class="active"><i class="fa fa-file-archive-o"></i> Nfe</li>

                            </ol>
                        </section>
                    </div>
                </div>
                <br></br>
                <div className="content">
                    <div className="card">

                        <div class="col-md-12">
                            <Tabs
                                defaultActiveKey="resumo"
                                transition={false}
                                id="noanim-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="resumo" title="Nf-e Configurações">
                                    <div className='content'>
                                        <div class="card">
                                        </div>
                                        <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger"><div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" class="ui-panel-content ui-widget-content">
                                            <div class="box-header with-border">
                                                <div class="form-horizontal">
                                                    <div className='content'>
                                                        <div className="box-body">
                                                            <div className="form-group">
                                                                <div className="col-sm-12 col-md-2 control-label">
                                                                    <label htmlFor="selectPrioridade">Chave de Acesso: *</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-8 control-label">
                                                                    <input
                                                                        id="sefazCForm:titulo"
                                                                        type="text"
                                                                        maxLength="60"
                                                                        name="sefazCForm:titulo"
                                                                        className="form-control"
                                                                        value={chaveAcesso}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>


                                                            </div>

                                                            <div class="form-group">

                                                                <div className="col-sm-12 col-md-2 control-label">
                                                                    <label for="selectPrioridade">Coligada:* </label>
                                                                </div>
                                                                <div class="col-sm-2">

                                                                    <select selected="selected" class="form-control select2 select2-hidden-accessible" multiple=""
                                                                        style={{ height: 35 }} onChange={(ev) => setColigada(ev.target.value)}>
                                                                        <option value="">Selecione</option>
                                                                        {ComboColigadasFornecedor.map((row) => (

                                                                            <option value={row.cnpj}>{row.nomefantasia}</option>

                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className="col-sm-12 col-md-12 control-label">
                                                                <div className="col-sm-12 col-md-6">
                                                                    <button type="button" className="btn btn-block btn-danger btn-lg">
                                                                        <i className="fa fa-chevron-left" /> VOLTAR
                                                                    </button>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-block btn-primary btn-lg"
                                                                        id="buscar-nsu"
                                                                        onClick={() => BuscarNsu()}
                                                                    >
                                                                        <i className="fa fa-floppy-o" /> Buscar Nsu
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <h4 className="col-sm12 col-md-12">
                                                                ###################################################LOG DO RESULTADO SEFAZ################################################################
                                                            </h4>
                                                            <div className="col-sm-12 col-md-12 control-label">
                                                                <textarea
                                                                    disabled
                                                                    name="nfeSefazForm:descricao"
                                                                    className="form-control"
                                                                    rows="5"
                                                                    value={resultado}
                                                                    readOnly
                                                                />
                                                            </div>


                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                        <Modal
                            open={camposPreenchidos}
                            onClose={handleClose}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                        >
                            <Box sx={{ ...style, width: 350 }}>
                                <img
                                    src={CloseButtonIcon}
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        right: '0',
                                        padding: '5px',
                                        cursor: 'pointer',
                                        maxWidth: '90%',
                                    }}
                                    width="50"
                                    onClick={handleClose}
                                    height="50" >
                                </img>

                                <div>
                                    <img
                                        src={WarningIcon}
                                        width="50"
                                        height="50"
                                    />
                                    <h2>
                                        Favor Preencher os campos necessário.
                                    </h2>
                                </div>
                            </Box>
                        </Modal>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

