import { Modal, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, Toolbar, Tooltip, IconButton, Divider, Dialog } from '@mui/material';
import React, { useEffect } from 'react';
import Footer from '../../../../common/template/footer';
import Header from '../../../../common/template/header';
import SideBar from '../../../../common/template/sideBar';
import axiosInstance from '../../../../config/api';
import { useTheme } from '@mui/material/styles';
import AlterarIMG from '../../../../common/template/img/lapis.png';
import LixeiraIcon from '../../../../common/template/img/lixeira.png';
import InfoIcon from '../../../../common/template/img/info.png';
import { Box } from '@mui/system';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Spinner from '../../../../common/template/Spinner';

function h1title(props) {
    return <h3 class="card-title" {...props}>{props.children}</h3>;
}



export default function SlChamado(props) {
    var newInt2 = JSON.parse(localStorage.getItem('idsetor'));
    var setorid = newInt2.idsetor;

    const [slachamado, setloadingSlachamado] = React.useState([]);
    const [alerta, setAlert] = React.useState(false);
    const [sucess, setSucess] = React.useState(false);

    const [pagenumber, setpageNumber] = React.useState('');
    const [totalPaginas, setTotalpages] = React.useState('');
    const [situacaosla, setSituacaosla] = React.useState(false);
    const [loading, setloading] = React.useState(false);
    const [completed, setcompleted] = React.useState(false);

    const handleChangeRowsPerPage = (event) => {
        setpageNumber(pagenumber++);
    };

    useEffect(() => {

        setTimeout(() => {
            setloading(true);

            setTimeout(() => {
                setcompleted(true);
            }, 300);
        });

        async function loadTemposla() {


            
            const response = await axiosInstance.get(`/temposla/paginado/${setorid}`);



            if (response.data.numberOfElements > 0) {


                setloadingSlachamado(response.data.content);
                setpageNumber(response.data.number)
                setTotalpages(response.data.totalPages);

            } else {
              
                setSituacaosla(true);
            }
        }

        loadTemposla();
    }, []);

    async function novocadastro() {
        window.location.href = '/slachamadodswid=9747/novocadastro';
    }
    async function voltar() {
        window.location.href = '/home';
    }

    async function alterarslachamado(id) {
       
        window.location.href = '/slachamadodswid=9747/' + id;
    }

    async function deletelachamado(id) {
       
            setloading(true);
        
        const response = await axiosInstance.delete('/temposla/' + id)

            .then(resp => {
                //Deletado com Sucesso! 
                window.location.href = "/slachamadodswid=9747"

            }).catch(error => {
                if (error !== 500 || error == 500) {
                    setAlert(true);
                }
            });
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '52%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#ffffff',
        border: '2px solid #ffffff',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,


    };

    const [open, setOpen] = React.useState(false);


    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const handleCloseAlerta = () => {
        setAlert(false);
    }

    function TablePaginationActions() {
        const theme = useTheme();
        const { count, page, rowsPerPage } = props;


        async function handleBackButtonClick() {
            if (pagenumber === 0) {
                setpageNumber(pagenumber);
            } else {
                setpageNumber(pagenumber - 1);
            }
            //const response = await axiosInstance.get(`/api/v1/notaentradaprodutosefaz/paginado/?page=${pagenumber}`);
            //setNfe(response.data.content);
        };

        async function handleNextButtonClick() {
            if (pagenumber === totalPaginas) {
                setpageNumber(pagenumber);
            } else {
                setpageNumber(pagenumber + 1);
            }
            //const response = await axiosInstance.get(`/api/v1/notaentradaprodutosefaz/paginado/?page=${pagenumber}`);
            //setNfe(response.data.content);
        };



        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>

                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>

                <IconButton>
                    {pagenumber}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>

            </Box>
        );
    }

    const loadingcss = {
        position: 'absolute',
        top: '50%',
        left: '52%'
    }


    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <div>
                    <div class="card-header">
                        <section class="content-header">
                            <h1>Sla Chamado
                                <img src={InfoIcon}

                                    width="20" height="20" style={{ marginLeft: '1%', cursor: 'pointer', }}
                                    onClick={handleOpen}
                                ></img></h1>
                            <ol class="breadcrumb">
                                <li><i class="fa fa-home"></i> Portal JCR</li>
                                <li><i class="fa fa-wrench"></i> Sistema de Chamado</li>
                                <li class="active"><i class="fa fa-reorder"></i> Configuração Chamado</li>
                                <li><i class="fa fa-home"></i> (Sla) Chamado</li>
                            </ol>
                        </section>





                    </div>
                    <br></br>



                    <div className='content'>

                        <div class=" ui-panel ui-widget ui-widget-content ui-corner-all box box-danger ">

                            <div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" class="ui-panel-content ui-widget-content">

                                <div class="box-header with-border ">
                                    <div class="col-sm-12 col-md-6">
                                        <button type="button" class="btn btn-block btn-danger btn-lg"
                                            onClick={() => voltar()}
                                        >
                                            <i class="fa fa-chevron-left"></i> Voltar</button>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <button type="button" class="btn btn-block btn-success btn-lg"
                                            onClick={() => novocadastro()}
                                        >
                                            <i class="fa fa-plus"></i> Novo</button>
                                        <br></br>
                                    </div>

                                    <div class="form-horizontal card scrollBox2  ">
                                        <div className='ui-panel ui-widget ui-widget-content ui-corner-all box box-danger '>
                                            {!completed ? (
                                                <>
                                                    {!loading ? (
                                                        <Dialog
                                                            open={true}

                                                            aria-describedby="alert-dialog-slide-description"

                                                        >
                                                            <div className="pos-center">
                                                                <Spinner />
                                                            </div>
                                                        </Dialog>
                                                    ) : (
                                                        <Dialog
                                                            open={true}

                                                            aria-describedby="alert-dialog-slide-description"

                                                        >
                                                            <div className="pos-center">
                                                                <Spinner />
                                                            </div>
                                                        </Dialog>
                                                    )}
                                                </>
                                            ) : (
                                                <div>
                                                </div>
                                            )}
                                            {situacaosla == true ?
                                                <div>
                                                    <h4 style={{ paddingLeft: '35%' }} >Nenhum Documento Localizado!</h4>
                                                </div>
                                                :

                                                <Table className="table table-striped table-bordered table-resizable" data-toggle="tooltip" stickyHeader aria-label="sticky table">



                                                    <TableHead >


                                                        <TableRow>

                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">Identificação</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">Tempo</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">Categoria</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">SubCategoria</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">Prioridade</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">Setor</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">Alterar</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">Excluir</TableCell>

                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>

                                                        {
                                                            slachamado.map((row) => (
                                                                <TableRow
                                                                    key={row.numero}
                                                                    sx={{ border: 100, width: 100 }}
                                                                >
                                                                    <TableCell align="center">{row.id}</TableCell>
                                                                    <TableCell align="center">{row.temposla}</TableCell>
                                                                    <TableCell align="center">{row.categoria}</TableCell>
                                                                    <TableCell align="center">{row.subcategoria}</TableCell>
                                                                    <TableCell align="center">{row.prioridade}</TableCell>
                                                                    <TableCell align="center">{row.grupousuario.setor}</TableCell>
                                                                    <TableCell align="center">
                                                                        <img src={AlterarIMG}

                                                                            width="20" height="20" style={{ marginLeft: '1%' }}
                                                                            onClick={() => alterarslachamado(row.id)}>
                                                                        </img></TableCell>
                                                                    <TableCell align="center">
                                                                        <img src={LixeiraIcon}
                                                                            onClick={() => deletelachamado(row.id)}
                                                                            width="20" height="20" style={{ marginLeft: '1%' }}>
                                                                        </img></TableCell>
                                                                </TableRow>

                                                            ))
                                                        }
                                                    </TableBody>

                                                </Table>
                                            }

                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                                aria-labelledby="parent-modal-title"
                                                aria-describedby="parent-modal-description"
                                            >
                                                <Box sx={{ ...style, width: 400 }}>
                                                    <h2 id="parent-modal-title">Sla Chamado <img src={InfoIcon}

                                                        width="20" height="20" style={{ marginLeft: '1%' }}
                                                    ></img></h2>
                                                    <p>O SLA trata-se de um acordo em que fica definido o nível de qualidade do serviço que o departamento deve atender dentro do tempo de solução defino para cada tipo de demanda.</p>
                                                    <p></p>
                                                    <p id="parent-modal-description">
                                                        <button type="button" class="btn btn-block btn-danger btn-lg" onClick={handleClose}>Voltar</button>

                                                    </p>

                                                </Box>
                                            </Modal>
                                        </div>

                                    </div>
                                    <div class="cardmov">
                                        <TablePagination
                                            colSpan={3}
                                            component="div"
                                            rowsPerPageOptions={[20]}
                                            count={slachamado.length}
                                            rowsPerPage={slachamado.length}
                                            page={pagenumber}
                                            ActionsComponent={TablePaginationActions}

                                            onRowsPerPageChange={handleChangeRowsPerPage}

                                        />

                                    </div>

                                </div>

                                <Modal
                                    open={alerta}
                                    onClose={handleCloseAlerta}
                                    aria-labelledby="parent-modal-title"
                                    aria-describedby="parent-modal-description"
                                >
                                    <Box sx={{ ...style, width: 400 }}>
                                        <img
                                            style={{ maxWidth: '100%', marginLeft: '100%', cursor: 'pointer' }}

                                            onClick={handleCloseAlerta}
                                            width="30" height="30" >
                                        </img>
                                        <h2 id="parent-modal-title"><img
                                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/OOjs_UI_icon_alert-yellow.svg/1200px-OOjs_UI_icon_alert-yellow.svg.png"
                                            width="30"
                                            height="30" >
                                        </img> Sla Chamado </h2>
                                        <Divider />
                                        <br></br>
                                        <h4 style={{ textAlign: 'center' }}>------------- InicioLog Sla Exclusão--------</h4>
                                        <h4>* O SLA possui um chamado em andamento para deletar, feche o chamado que possui vinculo com Sla.</h4>
                                        <h4 style={{ textAlign: 'center' }}>------------- Fim Log Sla Exclusão--------</h4>

                                    </Box>
                                </Modal>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}