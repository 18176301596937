import React from 'react';
import Footer from '../../common/template/footer';
import Header from '../../common/template/header';
import SideBar from '../../common/template/sideBar';
import PowerBIicon from './../../common/template/img/powerbi.png'

import './relatorio.css';

var acessolinkrelatorio = "https://app.powerbi.com/reportEmbed?reportId=d27022be-5194-4bbd-bd7f-1118d22595fe&autoAuth=true&ctid=91977c73-4260-451f-958b-4b64c171fa0a&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D"

function acessarlink() {

    window.open(acessolinkrelatorio, "Orcamento");
}

export default function Relatorios() {

    var title = 'Orçamento ';



    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <div>
                    <div class="card-header">
                        <h3 class="card-title"> {title}
                            
                            <img src={PowerBIicon}
                                onClick={acessarlink}
                                width="40" height="40" style={{ marginLeft: '1%' }}
                            ></img>
                        </h3>
                    </div>
                    <div class="card-body">
                        <iframe title="Orçamento" width="100%" height="900rem"
                            src="https://app.powerbi.com/reportEmbed?reportId=d27022be-5194-4bbd-bd7f-1118d22595fe&autoAuth=true&ctid=91977c73-4260-451f-958b-4b64c171fa0a&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D"
                            frameborder="0" allowFullScreen="true"></iframe>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}