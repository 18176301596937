import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../../../common/template/footer'
import Header from '../../../common/template/header'
import SideBar from '../../../common/template/sideBar'
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import axiosInstance from '../../../config/api';
import { Dialog } from '@mui/material';
import Spinner from '../../../common/template/Spinner';

export default function Usuario() {
    const [loading, setloading] = useState(false);
    
    const [completed, setcompleted] = useState(false);
    const [usuario, setUsuario] = useState([]);
    const [Buscarlogin, setbuscarLogin] = useState('');
    const [Buscaremail, setbuscarEmail] = useState('');
    const [alertstate, setAlertstate] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [alertConcluido, setAlertConcluido] = useState([]);
    async function voltar() {
        window.location.href = '/home';
    }

    useEffect(() => {
        setTimeout(() => {
              
            setcompleted(true);
         }, 500);


        async function loadUsuario() {
            const response = await axiosInstance.get("/login/todos/");
            setUsuario(response.data);

        }

        loadUsuario();
    }, []);

    async function novocadastro() {
        window.location.href = '/cadastrouser/create-new';
    }

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const deletarUsuario = async (id) => {
        const response = await axiosInstance.delete('/login/' + id)
            .then(resp => {
                handleClose();
                setTimeout(() => { setAlertConcluido(false); }, 1000);
                setTimeout(() => { setAlertConcluido(true); }, 7500);
                setTimeout(() => { window.location.href = '/cadastrouser'; }, 2000);

            })
            .catch(error => {
                handleClose();
                setTimeout(() => { setAlertstate(false); }, 2000);
                setTimeout(() => { setAlertstate(true); }, 5000);
            });

    }

    async function alterar(id) {
        window.location.href = "/cadastrouser/" + id;
    }


    async function voltar() {
        window.location.href = "/home";
    }

    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <div className="card-header">

                    <section className="content-header">
                        <h1><i className="fa fa-plus-square fa-1x cor-padrao"></i> Usuário
                            <small></small>
                        </h1>
                        <ol className="breadcrumb">
                            <li><i className="fa fa-home"></i> Portal JCR</li>
                            <li><i className="fa fa-wrench"></i> Configuração</li>
                            <li className="active"><i className="fa fa-ticket"></i> Usuário</li>
                        </ol>
                    </section>



                </div>

                <div>

                    <br></br>
                    <div className='content'>
                        {!completed ? (
                            <>
                                {loading ? (
                                    <Dialog
                                        open={true}

                                        aria-describedby="alert-dialog-slide-description"

                                    >
                                        <div className="pos-center">
                                            <Spinner />
                                        </div>
                                    </Dialog>
                                ) : (
                                    <Dialog
                                        open={true}

                                        aria-describedby="alert-dialog-slide-description"

                                    >
                                        <div className="pos-center">
                                            <Spinner />
                                        </div>
                                    </Dialog>
                                )}
                            </>
                        ) : (
                            <>
                                <div className="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger "><div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" className="ui-panel-content ui-widget-content">
                                    <div className="box-header with-border ">
                                        <div className="form-horizontal">
                                            <br></br>
                                            <div>
                                                {!alertstate &&
                                                    <Stack
                                                        sx={{ width: '100%' }} spacing={2}>
                                                        <Alert severity="error">Não é possível deletar um Usuario !</Alert>
                                                    </Stack>
                                                }


                                                {!alertConcluido &&
                                                    <Stack
                                                        sx={{ width: '100%' }} spacing={2}>
                                                        <Alert severity="success">Cadastro Deletado com Sucesso !</Alert>
                                                    </Stack>
                                                }
                                                <br></br><br></br>
                                                <div class="col-sm-2 col-md-6">
                                                    <button type="button" class="btn btn-block btn-danger btn-lg"
                                                        onClick={() => voltar()}
                                                    >
                                                        <i class="fa fa-chevron-left"></i> VOLTAR</button>
                                                </div>
                                                <div class="col-sm-2 col-md-6">
                                                    <button type="button" class="btn btn-block btn-success btn-lg"
                                                        onClick={() => novocadastro()}
                                                    >
                                                        <i class="fa fa-plus"></i> NOVO</button>

                                                </div>

                                            </div>
                                            <br></br><br></br><br></br>

                                            <div class="card-body ">
                                                <div className="box-header with-border ui-panel ui-widget ui-widget-content ui-corner-all box box-danger ">
                                                    <Table className="table table-striped table-bordered table-resizable" data-toggle="tooltip" stickyHeader aria-label="sticky table">

                                                        <TableHead >


                                                            <TableRow>

                                                                <TableCell sx={{ fontWeight: 'bold' }}>Identificação</TableCell>
                                                                <TableCell sx={{ fontWeight: 'bold' }} align="center">E-Mail</TableCell>
                                                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Login</TableCell>
                                                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Setor</TableCell>
                                                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Alterar</TableCell>
                                                                <TableCell sx={{ fontWeight: 'bold' }} align="center">Excluir</TableCell>

                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {usuario.filter(row => row.email.includes(Buscaremail)).filter(row => row.login.includes(Buscarlogin))
                                                                .map((row) => (

                                                                    <TableRow
                                                                        key={row.id}
                                                                        sx={{ border: 100, width: 100 }}
                                                                    >

                                                                        <TableCell component="th" scope="row">{row.id}</TableCell>
                                                                        <TableCell align="center">{row.email}</TableCell>
                                                                        <TableCell align="center">{row.login}</TableCell>
                                                                        <TableCell align="center">{row.grupousuario.setor}</TableCell>
                                                                        <TableCell component="th" scope="row">
                                                                            <a>
                                                                                <img src="https://www.freeiconspng.com/uploads/edit-icon-orange-pencil-0.png"
                                                                                    width="20" height="20" style={{ marginLeft: '45%' }}
                                                                                    onClick={() => alterar(row.id)}
                                                                                >
                                                                                </img>
                                                                            </a>
                                                                        </TableCell>
                                                                        <TableCell component="th" scope="row">
                                                                            <a>
                                                                                <img src="https://cdn-icons-png.flaticon.com/512/216/216658.png"
                                                                                    onClick={() => deletarUsuario(row.id)}
                                                                                    width="20" height="20" style={{ marginLeft: '45%' }}>
                                                                                </img>
                                                                            </a></TableCell>
                                                                    </TableRow>

                                                                ))}

                                                        </TableBody>


                                                    </Table>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </div>


    )
}