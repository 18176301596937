import React, { useEffect, useState } from "react";
import {
  VictoryLabel,
  VictoryPie,
  VictoryLegend,
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  Box,
} from "victory";
import "./gracios.css";
import axiosInstance from "../../../config/api";
import { Tab, Tabs } from "react-bootstrap";
import { Modal } from "@mui/material";
import { Box as BoxModal } from "@mui/system";
import { Dialog } from "@mui/material";
import html2pdf from "html2pdf.js";
import { parseISO, format, isValid } from "date-fns";
import logo from "../../../common/template/img/LOGOJCR.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { Dropdown, DropdownButton } from "react-bootstrap";
import Warning from "../../../common/template/img/Warning.png";
import Spinner from "../../../common/template/Spinner";
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import CloseButton from "../../../common/template/img/closebutton.png";
import { format as FormatData } from 'date-fns-tz';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const DonutChart = () => {
  const [data, setData] = useState([]);
  const timeZone = 'America/Sao_Paulo';
  const [mensagemrelatorio, setmensagemrelatorio] = useState(undefined);
  const [mostrarMensagem, setMostrarMensagem] = useState(true);
  const [visualizarRelatorio, setvisualizarRelatorio] = useState(false);
  const [grafico, setvisualizarGrafico] = useState(false);


  const localStorageData = localStorage.getItem("idsetor");


    const parsedData = JSON.parse(localStorageData);


    const idSetor = parsedData.idsetor;

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startdaterelatorio, setStartdaterelatorio] = useState("");
  const [enddaterelatorio, setEnddaterelatorio] = useState("");
  const [loading, setLoading] = useState(undefined);

  const [mensagemfalha, setMensagemfalha]= useState(false);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleMensagemClose = () => {
    setMensagemfalha(false);
  }

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleStartDateChangePDF = (e) => {
    setStartdaterelatorio(e.target.value);
  };

  const handleEndDateChangePDF = (e) => {
    setEnddaterelatorio(e.target.value);
  };

  const handleSearch = () => {
    console.log("Data Inicial:", startDate);
    console.log("Data Final:", endDate);
  };

  const handleAction1Click = () => {
    setvisualizarRelatorio(false);
    setvisualizarGrafico(true);
    setMostrarMensagem(false);
  };

  const limparFiltro = () => {
    setvisualizarRelatorio(false);
    setvisualizarGrafico(false);
    setMostrarMensagem(true);
  }

  const handleAction2Click = () => {
    setMostrarMensagem(false);
    window.location.href = "/gerencimanetoprojetoview";
  };

  useEffect(
    () => {
      async function loadingProjeto() {
        try {
          const response = await axiosInstance.get(`/projeto/gerargrafico/${idSetor}`);

          if (response.data && Array.isArray(response.data)) {
            const groupedData = response.data.reduce((acc, item) => {
              const status = item.status;

              if (!acc[status]) {
                acc[status] = {
                  x: status,
                  y: 0,
                };
              }

              acc[status].y += item.contador;

              return acc;
            }, {});

            const adjustedData = Object.values(groupedData);

            setData(adjustedData);
          } else {
            console.error("Dados inválidos:", response.data);
          }
        } catch (error) {
          console.error("Erro ao buscar os dados:", error);
        }
      }
      loadingProjeto();
    },
    [startdaterelatorio],
    [enddaterelatorio],
    []
  );


  const exportToPDF = async () => {

    const container = document.getElementById("export-containerTeste");
    const buttonToExclude = document.getElementById("exportToPDF");

    if (buttonToExclude) {
        buttonToExclude.style.display = "none";
    }

    try {
        const scaleValue = 10.0;

        const canvas = await html2canvas(container, { scale: scaleValue });

        const pdf = new jsPDF({
            unit: "mm",
            format: "a4",
            orientation: "landscape",
        });

        var larguraDesejada = 355;
        var alturaDesejada = 180;

        var deslocamentoVertical = 40;
        pdf.addImage(canvas.toDataURL("image/jpeg", 10.0), "JPEG", 0, deslocamentoVertical, larguraDesejada, alturaDesejada);

        const logoWidth = 25;
        const logoHeight = 25;
        const logoX = 10;
        const logoY = 5;

        const tituloRelatorio = "Relatório Gerencial de Projeto";
        const fontSize = 14;
        const tituloX = logoX + logoWidth + 80; // Ajuste conforme necessário
        const tituloY = logoY + (logoHeight / 2) + (fontSize / 4); // Ajuste conforme necessário

        // Adiciona a imagem do logotipo apenas uma vez
        if (!pdf.hasAddedLogo) {
            pdf.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight);

            // Define uma propriedade no objeto pdf para indicar que o logotipo foi adicionado
            pdf.hasAddedLogo = true;
        }

        // Adiciona o título ao lado da logo
        pdf.setFontSize(fontSize);
        pdf.text(tituloRelatorio, tituloX, tituloY);

        const pdfBlob = pdf.output("blob");
        const pdfUrl = URL.createObjectURL(pdfBlob);

        window.open(pdfUrl, '_blank');

    } catch (error) {
      setMensagemfalha(true);
    }

    if (buttonToExclude) {
        buttonToExclude.style.display = "block";
    }
 
};





  async function exportResumoPDF() {
    try {
      if (startdaterelatorio !== null && enddaterelatorio !== null) {
        if (
          !isValid(parseISO(`${startdaterelatorio}`)) ||
          !isValid(parseISO(`${enddaterelatorio}`))
        ) {
          setmensagemrelatorio(true);
          return;
        }
      }

      let formattedStartDate, formattedEndDate;

      const desiredTime = "23:59:00";

      // Formatando datas
      formattedStartDate = format(
        parseISO(`${startdaterelatorio}`),
        "yyyy-MM-dd'T'HH:mm:ss"
      );
      formattedEndDate = format(
        parseISO(`${enddaterelatorio} ${desiredTime}`),
        "yyyy-MM-dd'T'HH:mm:ss"
      );

      const response = await axiosInstance.get(
        `projeto/gerarrelatorioprojeto/?dataInicial=${formattedStartDate}&dataFinal=${formattedEndDate}`,
        { responseType: "blob" }
      );

      if (response.status !== 200) {
        setmensagemrelatorio(true);
      } else {
        setLoading(true);
        if (response && response.data) {
          const file = new Blob([response.data], {
            type: "application/pdf;charset=UTF-8",
          });
          const fileURL = URL.createObjectURL(file);

          window.open(fileURL);

          setLoading(false);
        } else {
          console.error("A resposta não contém dados ou está vazia.");
        }
      }
    } catch (error) {
      console.error("Erro durante a exportação do PDF:", error);
    }
  }

  async function exportResumoPDFCompleto() {
    try {
      if (startdaterelatorio !== null && enddaterelatorio !== null) {
        if (
          !isValid(parseISO(`${startdaterelatorio}`)) ||
          !isValid(parseISO(`${enddaterelatorio}`))
        ) {
          setmensagemrelatorio(true);
          return;
        }
      }

      let formattedStartDate, formattedEndDate;

      const desiredTime = "23:59:00";

      // Formatando datas
      formattedStartDate = format(
        parseISO(`${startdaterelatorio}`),
        "yyyy-MM-dd'T'HH:mm:ss"
      );
      formattedEndDate = format(
        parseISO(`${enddaterelatorio} ${desiredTime}`),
        "yyyy-MM-dd'T'HH:mm:ss"
      );

      const response = await axiosInstance.get(
        `projeto/itemprojeto/gerarrelatorioprojeto/?datainicial=${formattedStartDate}&datafinal=${formattedEndDate}`,
        { responseType: "blob" }
      );

      if (response.status !== 200) {
        setmensagemrelatorio(true);
      } else {
        setLoading(true);
        if (response && response.data) {
          const file = new Blob([response.data], {
            type: "application/pdf;charset=UTF-8",
          });
          const fileURL = URL.createObjectURL(file);

          window.open(fileURL);

          setLoading(false);
        } else {
          console.error("A resposta não contém dados ou está vazia.");
        }
      }
    } catch (error) {
      console.error("Erro durante a exportação do PDF:", error);
    }
  }

  const fetchData = async (dataInicial, dataFinal) => {
    try {
      const formattedDataInicial = `${new Date(dataInicial).toISOString().split('T')[0]}T00:00:00`;
      const formattedDataFinal = `${new Date(dataFinal).toISOString().split('T')[0]}T23:59:59`;
  
      const response = await axiosInstance.get(`/projeto/gerargrafico/${idSetor}?dataInicial=${formattedDataInicial}&dataFinal=${formattedDataFinal}`);
  
      if (response.data && Array.isArray(response.data)) {
        const groupedData = response.data.reduce((acc, item) => {
          const status = item.status;
  
          if (!acc[status]) {
            acc[status] = {
              x: status,
              y: 0,
            };
          }
  
          acc[status].y += item.contador;
  
          return acc;
        }, {});
  
        const adjustedData = Object.values(groupedData);
  
        // Verificar se o array resultante é vazio
        if (adjustedData.length === 0) {
          // Se for vazio, você pode definir o estado como um array vazio ou outro valor padrão
          setData([]);
        } else {
          setData(adjustedData);
        }
      } else {
        console.error('Dados inválidos:', response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar os dados:', error);
    }
  };
  



  const handleClose = () => {
    setmensagemrelatorio(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "52%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#ffffff",
    border: "2px solid #ffffff",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const loadingcss = {
    position: "absolute",
    top: "50%",
    left: "52%",
  };

  return (
    <>
      <Tabs
        defaultActiveKey="Relatoriogerencial"
        transition={false}
        id="noanim-tab-example"
        className="mb-3"
      >
        <Tab
          eventKey="Relatoriogerencial"
          title={
            <>
              <i className="fa fa-gear"></i> Relatório Gerencial
            </>
          }
        >
          <div>
            <img
              style={{ marginLeft: "1%" }}
              className="logo"
              src={logo}
              height="120"
              width="120"
              id="logoteste"
              alt="JCR"
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>

              <DropdownButton id="dropdown-basic-button" title="Opções">
                <Dropdown.Item onClick={limparFiltro}>
                  Limpar Filtro
                </Dropdown.Item>
                <Dropdown.Item onClick={handleAction1Click} active={grafico}>
                  Visualizar Grafico
                </Dropdown.Item>
                <Dropdown.Item onClick={handleAction2Click}>
                  Visualizar Projetos
                </Dropdown.Item>
                <Dropdown.Item onClick={exportToPDF}>
                  Exportar Grafico
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12" style={{ marginLeft: "1%" }}></div>
          </div>
          <div id="export-container">
            <br></br>
            <br></br>
            {mostrarMensagem && (
              <p className="filter">Selecione a opção para visualizar o filtro</p>
            )}
            <div className="col-md-3"></div>


            {grafico ? (
              <div className="container-fluid">


                <h3 className="centralizado">Relatório Gerencial de Projeto</h3>
                <br></br>
                <div className="row">
                  <div className="col-md-3">
                    <input
                      type="date"
                      value={startDate}
                      onChange={handleStartDateChange}
                      className="form-control"
                      style={{ marginBottom: "1%" }}
                    />
                  </div>
                  <div className="col-md-4">
                    <input
                      type="date"
                      value={endDate}
                      onChange={handleEndDateChange}
                      className="form-control"
                      style={{ marginBottom: "1%" }}
                    />
                  </div>

                  <div className="col-md-4">
                    <button
                      className="btn btn-primary w-100"
                      onClick={async () => {
                        const formattedStartDate = new Date(startDate).toISOString(); // Removendo o split para manter o formato completo
                        const formattedEndDate = new Date(endDate).toISOString();
                        await fetchData(formattedStartDate, formattedEndDate);
                      }}
                    >
                      <FontAwesomeIcon icon={faFilter} style={{ marginRight: '5px' }} />
                      Filtrar
                    </button>


                  </div>
                </div>
                <div className="card">
                  <div style={{ overflowX: 'auto', overflowY: 'auto', height: '700px', width: '100%' }}>
                    <div className="row" id="export-containerTeste">
                      <div className="col-md-3 ml-12">
                        <div className="green-div">
                          {data && data.length > 0 ? (
                            <>
                              <br />
                              <br />
                              <div
                                id="chart-container"
                                className="donut-chart-container text-center mover"
                                style={{ width: "110%", height: "110%" }}
                              >
                                <VictoryPie
                                  data={data}
                                  labelRadius={91} innerRadius={75} outerRadius={75} colorScale={["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728",]}
                                  labels={({ datum }) =>
                                    `${datum.y}\n${datum.x === "NAOINICIADA" ? "Não Iniciada" : datum.x === "EMANDAMENTO" ? "Em Andamento" : datum.x === "FINALIZADA" ? "Finalizada" : datum.x === "ENTREGUE" ? "Entregue" : "FALHA NA APRESENTAÇÃO"}`}
                                  labelComponent={
                                    <VictoryLabel
                                      style={{ fill: "#fff", fontFamily: "Arial", fontSize: "11px", }}
                                    />
                                  }
                                />
                              </div>
                              <br />
                              <br />
                            </>
                          ) : (
                            <p style={{ marginLeft: "30%" }}>
                              <br />
                              <br />
                              Nenhum Registro Encontrado !!!
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-md-5 ml-">
                        <div className="barra-div">
                          {data && data.length > 0 ? (
                            <>
                              <br />
                              <br />
                              <div id="bar-chart-container" style={{ marginLeft: '8%', width: "100%", height: "100%" }}>
                                <VictoryChart
                                  theme={VictoryTheme.material}
                                  domainPadding={20}
                                  padding={{
                                    top: 20,
                                    bottom: 80,
                                    left: 50,
                                    right: 50,
                                  }}
                                >
                                  <VictoryAxis dependentAxis />
                                  <VictoryAxis
                                    tickValues={data.map((item) => item.x)}
                                    tickFormat={(t) =>
                                      t === "NAOINICIADA"
                                        ? "Não Iniciada"
                                        : t === "EMANDAMENTO"
                                          ? "Em Andamento"
                                          : t === "FINALIZADA"
                                            ? "Finalizada"
                                            : t === "FINALIZADA"
                                              ? "Finalizada"
                                              : t === "ENTREGUE"
                                                ? "Entregue"
                                                : "FALHA NA APRESENTAÇÃO"
                                    }
                                    style={{
                                      tickLabels: {
                                        angle: 25,
                                        padding: 10,
                                        fontFamily: "Arial",
                                        fontSize: "11px",
                                      },
                                    }}
                                  />
                                  <VictoryBar
                                    data={data}
                                    x="x"
                                    y="y"
                                    labels={({ datum }) => datum.y}
                                    style={{
                                      data: {
                                        fill: ({ datum, index }) =>
                                          datum.fill ||
                                          [
                                            "#1f77b4",
                                            "#ff7f0e",
                                            "#2ca02c",
                                            "#d62728",
                                          ][index % 4],
                                        width: 20,
                                      },
                                    }}
                                  />
                                </VictoryChart>
                                <br />
                                <br />
                                <br />
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="red-div">
                          {data && data.length > 0 ? (
                            <>
                              <br />
                              <br />
                              <div className="victory-legend-container" style={{ marginLeft: '15%', width: "150%", height: "150%", marginTop: "100%" }}>
                                <VictoryLegend
                                  style={{
                                    marginLeft: "2%",
                                    fontFamily: "Arial",
                                    fontSize: "11px",
                                  }}
                                  gutter={5}
                                  data={data.map((item, index) => ({
                                    name:
                                      item.x === "NAOINICIADA"
                                        ? "Não Iniciada"
                                        : item.x === "EMANDAMENTO"
                                          ? "Em Andamento"
                                          : item.x === "NAOINICIADA"
                                            ? "Não Iniciada"
                                            : item.x === "FINALIZADA"
                                              ? "Finalizada"
                                              : item.x === "FINALIZADA"
                                                ? "Finalizada"
                                                : item.x === "ENTREGUE"
                                                  ? "Entregue"
                                                  : "FALHA NA APRESENTAÇÃO",
                                    symbol: {
                                      fill: [
                                        "#1f77b4",
                                        "#ff7f0e",
                                        "#2ca02c",
                                        "#d62728",
                                      ][index],
                                    },
                                  }))}
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br></br>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </Tab>

        <Tab
          eventKey="gerarRelatorio"
          title={
            <>
              <i className="fa fa-line-chart"></i> Relatório
            </>
          }
        >
          {" "}
          <br></br>
          <h3 style={{ marginLeft: "40%" }}>Exportar Relatório</h3>
          <br></br>
          <div style={{ marginLeft: "15%", width: "80%" }}>
            <div className="col-md-3">
              <input
                type="date"
                value={startdaterelatorio}
                onChange={handleStartDateChangePDF}
                className="form-control"
                style={{ marginBottom: "1%" }}
              />
            </div>
            <div className="col-md-3">
              <input
                type="date"
                value={enddaterelatorio}
                onChange={handleEndDateChangePDF}
                className="form-control"
                style={{ marginBottom: "1%" }}
              />
            </div>
            <div className="row">
              <div className="col-md-7">
                <button
                  onClick={exportResumoPDF}
                  className="btn btn-primary btn-lg custom-margin"
                >
                  <FontAwesomeIcon icon={faSearch} className="mr-4" />
                  PDF Resumo
                </button>
              </div>
              <div className="col-md-4">
                <button
                  onClick={exportResumoPDFCompleto}
                  className="btn btn-success btn-lg custom-margin"
                >
                  <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
                  PDF Completo
                </button>
              </div>
            </div>
          </div>
        </Tab>
      </Tabs >
      <>
        {loading ? (
          <Dialog open={true} aria-describedby="alert-dialog-slide-description">
            <div className="pos-center">
              <Spinner />
            </div>
          </Dialog>
        ) : (
          <Dialog
            open={false}
            aria-describedby="alert-dialog-slide-description"
          >
            <div className="pos-center"></div>
          </Dialog>
        )}
      </>
      <Modal
        open={mensagemrelatorio}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <BoxModal sx={{ ...style, width: 800 }}>
          <img
            src={Warning}
            style={{ cursor: "auto" }}
            width="30"
            height="30"
          ></img>
          <img
            src={CloseButton}
            width="30"
            onClick={handleClose}
            height="30"
            style={{ marginLeft: "90%" }}
          />

          <h2 style={{ fontWeight: "bold" }}>
            Não há dados disponíveis para o período fornecido. Verifique se as
            datas foram preenchidas corretamente ou se o filtro inserido não
            contém nenhum registro.
          </h2>
        </BoxModal>
      </Modal>

      <Modal
        open={mensagemfalha}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <BoxModal sx={{ ...style, width: 800 }}>
          <img
            src={Warning}
            style={{ cursor: "auto" }}
            width="30"
            height="30"
          ></img>
          <img
            src={CloseButton}
            width="30"
            onClick={handleMensagemClose}
            height="30"
            style={{ marginLeft: "90%" }}
          />

          <h2 style={{ fontWeight: "bold" }}>
              Selecione o Botão Opções e Visualiza o Grafico para poder Exportar 
          </h2>
        </BoxModal>
      </Modal>
    </>
  );
};

export default DonutChart;