import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import './css/header.css';
import axiosInstance from '../../config/api';
import { useMediaQuery } from 'react-responsive';

export default function Header(props) {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [eventobutton, seteventobutton] = React.useState(false);
  const [totalemaberto, settotalemaberto] = React.useState([]);
  const [totalemaandamento, settotalemaandamento] = React.useState([]);

  const usuario = localStorage.getItem('nomecomplemento');
  const usuarioLogado = JSON.parse(usuario);

  var newInt2 = JSON.parse(localStorage.getItem('idsetor'));
  var idusuario = newInt2.idsetor;

  const [headerOpen, setHeaderOpen] = React.useState(undefined);

  const handleHeaderToggle = () => {
    setHeaderOpen(!headerOpen);
  };

  const handleClick = () => {
    seteventobutton((prev) => !prev);
  };

  const handleClickAway = () => {
    seteventobutton(false);
  };

  useEffect(() => {
    async function quantidadeNotasemaberto() {
      const response = await axiosInstance.get(`/chamado/totalemaberto/${idusuario}`);
      settotalemaberto(response.data);
    }
    quantidadeNotasemaberto();

    async function quantidadeNotasemandamento() {
      try {
        const response = await axiosInstance.get(`/chamado/totalandamento/${idusuario}`);
        settotalemaandamento(response.data);
      } catch (error) {
        alert('Falha de Conexão com Banco de dados');
        setTimeout(() => {
          window.location.href = '/';
        }, 1000);
      }
    }
    quantidadeNotasemandamento();
  }, []);

  useEffect(() => {
    const body = document.body;
    if (isMobile) {
      if (headerOpen) {
        body.classList.add('sidebar-open');
        body.classList.remove('sidebar-collapse');
      } else {
        body.classList.remove('sidebar-open');
        body.classList.add('sidebar-collapse');
      }
    } else {
      if (headerOpen) {
        body.classList.remove('sidebar-collapse');
      } else {
        body.classList.add('sidebar-collapse');
      }
    }
  }, [headerOpen, isMobile]);


  async function sair(){
    //implementacao futura
  }

  return (
    <div>
      <div
        className={`main-header ${isMobile ? '' : headerOpen ? '' : 'skin-blue fixed sidebar-mini sidebar-open'}`}
      >
        <header className="main-header">
          <a href="/home" className="logo">
            <span className="logo-lg">
              <i className="imagemlogo"></i>
              <img width="70" height="60" src="/jcrlogo.png" />
            </span>
          </a>
          <nav>
           
              <a
                style={{ color: 'white' }}
                href="#"
                className="sidebar-toggle"
                onClick={handleHeaderToggle}
              ></a>
         

            <li className="nav-item">
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box sx={{ position: 'relative' }}>
                  <a
                    onClick={handleClick}
                    style={{
                      fontSize: '20px',
                      left: '75%',
                      position: 'absolute',
                      transform: 'translatex(-49%)',
                      top: '5px',
                    }}
                  >
                    <span
                      href
                      style={{ color: 'white' }}
                      className="fa fa-bell fa-lg x-bigger"
                    >
                      <span
                        style={{ backgroundColor: '#fde910', color: 'black' }}
                        className="badge badge-warning navbar-badge"
                      >
                        {totalemaandamento + totalemaberto}
                      </span>
                    </span>
                  </a>
                  {eventobutton ? (
                    <div
                      className="dropdown-menu dropdown-menu-lg dropdown-menu-right show"
                      style={{
                        position: 'absolute',
                        boxSizing: 'border-box',
                        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.80), 0 3px 6px rgba(0, 0, 0, 0.50)',
                        right: '10%',
                        transform: 'translatex(-49%)',
                        top: '50px',
                      }}
                    >
                      <span>
                        <i></i>
                      </span>
                      <span
                        className="dropdown-item dropdown-header"
                        style={{
                          fontWeight: 'bold',
                          border: '10px',
                          fontSize: '14px',
                        }}
                      >
                        Sistema de Chamado
                      </span>
                      <div className="dropdown-divider"></div>
                      <a href="/paineldechamados" className="dropdown-item">
                        <i
                          className="fa fa-plus mr-2"
                          style={{ fontSize: '18px', color: 'green' }}
                        ></i>
                        {totalemaberto} Total ticket aberto
                        <span className="float-right text-muted text-sm"></span>
                      </a>
                      <div className="dropdown-divider"></div>
                      <a href="/paineldechamados" className="dropdown-item">
                        <i
                          className="fa fa-pencil mr-2"
                          style={{ fontSize: '18px', color: 'black' }}
                        ></i>
                        {totalemaandamento} Total de ticket em andamento
                        <span className="float-right text-muted text-sm"></span>
                      </a>
                    </div>
                  ) : null}
                </Box>
              </ClickAwayListener>

              <a
                className="nav-link"
                style={{ marginLeft: '80%' }}
               
                role="button"
                onClick={() => sair()}
              >
                <i
                  className="fa fa-user text-nav-icon x-bigger"
                  style={{ color: '#ffffff', fontSize: '25px' }}
                ></i>
                <span
                  className="hidden-xs"
                 
                  style={{ color: '#ffffff', paddingLeft: '3%', fontSize: '20px' }}
                >
                  {usuarioLogado.nomecomplemento}
                </span>
              </a>
            </li>
          </nav>
          <div>
            <div></div>
          </div>
        </header>
      </div>
    </div>
  );
}
