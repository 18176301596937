import React, { useState } from 'react';

import { Modal, Typography, Button } from '@mui/material';
import ContentHeader from '../../common/template/contentHeader';

import Header from '../../common/template/header';
import SideBar from '../../common/template/sideBar';
import Footer from '../../common/template/footer';
import './roaddefault.css';
import './roaddefault.scss';



import VisualizarNewsIMG from '../../common/template/img/news.png'
import { Box } from '@mui/system';

const style = {
    position: 'absolute',
    position: 'absolute',
    top: '52%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#ffffff',
    border: '2px solid #ffffff',
    boxShadow: 50,
    
    pt: 5,
    px: 5,
    pb: 5,

};

const closeButtonStyle = {
    marginTop: '-20px',
    marginLeft: '85%',
};



export default function RoadMap2023(props) {
    const [showPopup, setShowPopup] = useState(true);

    const handlePopupClose = () => {
        setShowPopup(false);
    };


    const usuario = localStorage.getItem('nomecomplemento');
    const usuarioLogado = JSON.parse(usuario);

    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <ContentHeader title={`Portal Cloud Seja bem-vindo ` + usuarioLogado.nomecomplemento} />
                <br></br>

                <div class="box-body">
                    <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger"><div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" class="ui-panel-content ui-widget-content">


                        <div class="box-header with-border">
                            <div class="form-horizontal">
                                <div class="card">
                                    <h2>ROADMAP - 2023</h2>
                                    <div class="timeline">
                                        <div class="timeline__event animated fadeInUp delay-2s timeline__event--type3">
                                            <div class="timeline__event__content">
                                                <div class="timeline__event__title">

                                                    Portal Cloud Path v1.04
                                                </div>
                                                <br></br>
                                                <div class="timeline__event__description">

                                                    <p>  - Recursos Humanos - Holerite </p>
                                                    <p>  -   Pacote de Atualização Melhorias PORTAL CLOUD GRUPO JCR - v1.04</p>
                                                </div>

                                            </div>
                                            <div class="timeline__event__date">

                                                Janeiro / Fev
                                            </div>
                                            <div class="timeline__event__icon">

                                                <i class="lni-cake">
                                                    <img src={VisualizarNewsIMG}

                                                        class="botaoPadrao" />
                                                </i>

                                            </div>
                                        </div>

                                        <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">




                                            <div class="timeline__event__content">
                                                <div class="timeline__event__title">

                                                    Portal Cloud Path v1.05
                                                </div>
                                                <br></br>
                                                <div class="timeline__event__description">
                                                    <p>  - Pacote de Atualização Melhorias PORTAL CLOUD GRUPO JCR - v1.05</p>
                                                    <p>  - Correçao de Filtros</p>
                                                    <p>  - Correçao de Captura de NFs-e Curitiba</p>
                                                    <p>  - Correçao de Captura de NFs-e Pontal do Paraná</p>
                                                </div>

                                            </div>
                                            <div class="timeline__event__date">

                                                Março / Abril
                                            </div>
                                            <div class="timeline__event__icon">

                                                <i class="lni-cake">
                                                    <img src={VisualizarNewsIMG}

                                                        class="botaoPadrao" />
                                                </i>

                                            </div>



                                        </div>

                                        <div class="timeline__event animated fadeInUp delay-3s timeline__event--type8">




                                            <div class="timeline__event__content">
                                                <div class="timeline__event__title">

                                                    Portal Cloud Path v1.06
                                                </div>
                                                <br></br>
                                                <div class="timeline__event__description">

                                                    <p>  - Criação Nfe Resumo</p>
                                                    <p>  - Correção e Melhorias Sefaz</p>
                                                    <p>  - Geração de Documento Danfe</p>
                                                    <p>  - Gerenciamento de Manual</p>
                                                    <p>  - Pacote de Atualização Melhorias PORTAL CLOUD GRUPO JCR - v1.06</p>
                                                    <p>  - Integração com TOTVS RM</p>
                                                </div>

                                            </div>
                                            <div class="timeline__event__date">

                                                Mai / Junho
                                            </div>
                                            <div class="timeline__event__icon">

                                                <i class="lni-cake">
                                                    <img src={VisualizarNewsIMG}

                                                        class="botaoPadrao" />
                                                </i>

                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    </div>
                </div>

            </div>
            <Footer />
        </div >
    )
}