import React from "react";
import Header from "../../../common/template/header";
import SideBar from "../../../common/template/sideBar";

import Footer from "../../../common/template/footer";
import Graficos from "../graficos/graficos";

import "./relatorioprojeto.css";

export default function RelatoriosProjeto() {
  return (
    <div className="wrapper">
      <Header />
      <SideBar />
      <div className="content-wrapper">
        <div>
          <div class="card-header">
            <section class="content-header">
              <h1>
                <i class="fa fa-plus-square fa-1x cor-padrao"></i> Gerenciamento
                de Projeto
                <small></small>
              </h1>
              <ol class="breadcrumb">
                <li>
                  <i class="fa fa-home"></i> Portal JCR
                </li>

                <li class="active">
                  <i class="fa fa-reorder"></i> Gerenciamento de Projeto
                </li>
              </ol>
            </section>
          </div>
        </div>

        <div class="box-body">
          <div className="content">
            <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger">
              <div
                id="edicaoCategoriaChamadoForm:painelSubcategoria_content"
                class="ui-panel-content ui-widget-content"
              >
                <div class="box-header with-border">
                  <div class="form-horizontal">
                    <div class="box-body">
                      <h3> Graficos de Projeto</h3>
                      <Graficos />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
