
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../../../common/template/footer';
import Header from '../../../common/template/header';
import SideBar from '../../../common/template/sideBar';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import axiosInstance from '../../../config/api';
import './certificado.css';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};




export default function CertificadoCadastroEdit(props) {
    const { id } = useParams();

    const [alertstate, setAlertstate] = useState([]);
    const [alertConcluido, setAlertConcluido] = useState([]);

    const [Idcertificado, setIdcertificado] = useState('');
    const [Cpfcnpjdados, setCpfcnpjdados] = useState('');
    const [Razaosocialdados, setRazaosocialdados] = useState('');
    const [Ufdados, setUfdados] = useState('');
    const [Ambienteenumdados, setAmbienteenumdados] = useState('');
    const [Certificadodados, setCertificadodados] = useState('');
    const [Nsudados, setNsudados] = useState('');
    const [Senhacertificadodados, setSenhacertificadodados] = useState('');

    const [open, setOpen] = React.useState(false);


    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    async function voltar() {
        window.location.href = '/certificado'
    }

    useEffect(() => {
        async function listaCertificado() {
            const response = await axiosInstance.get("/api/v1/empresa/" + id)
            setIdcertificado(response.data.id);
            setCpfcnpjdados(response.data.cpfcnpj);
            setRazaosocialdados(response.data.razaosocial);
            setAmbienteenumdados(response.data.ambienteenum);
            setUfdados(response.data.uf);
            setNsudados(response.data.nsu);
            setCertificadodados(response.data.certificado);
            setSenhacertificadodados(response.data.senhacertificado);
            
        }
        listaCertificado();


    }, []);


    async function inserirCertificado() {


        const datacrud = {
            cpfcnpj: Cpfcnpjdados,
            razaosocial: Razaosocialdados,
            uf: Ufdados,
            ambienteenum: Ambienteenumdados,
            certificado: Certificadodados,
            senhacertificado: Senhacertificadodados,
            nsu: Nsudados,
        }
        const response = await axiosInstance.put('/api/v1/empresa/'+id, datacrud)
            .then(resp => {
                handleClose();
                setTimeout(() => { setAlertConcluido(false); }, 1000);
                setTimeout(() => { setAlertConcluido(true); }, 7500);
                setTimeout(() => { window.location.href = '/certificado'; }, 2000);

            })
            .catch(error => {
                handleClose();
                setTimeout(() => { setAlertstate(false); }, 2000);
                setTimeout(() => { setAlertstate(true); }, 5000);
            });




    }

    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <div>

                    <br></br>
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Cadastro Certificados</h3>
                        </div>
                        <div>

                        </div>
                        <br></br>
                        <div class="card">
                            <div class="card-body ">
                                <div>
                                    {!alertstate &&
                                        <Stack
                                            sx={{ width: '100%' }} spacing={2}>
                                            <Alert severity="warning">Favor Preencher os campos !</Alert>
                                        </Stack>
                                    }

                                    {!alertConcluido &&
                                        <Stack
                                            sx={{ width: '100%' }} spacing={2}>
                                            <Alert severity="success">Cadastro Realizado com Sucesso !</Alert>
                                        </Stack>
                                    }


                                </div>
                                <label>ID*: </label>
                                <input type="text"
                                    disabled="disabled"
                                    value={Idcertificado}
                                    class="form-control"
                                    id="id"
                                ></input>

                                <label>CNPJ*: </label>
                                <input type="text"
                                    value={Cpfcnpjdados}
                                    onChange={e => setCpfcnpjdados(e.target.value)}
                                    class="form-control"
                                    id="cpfcnpj"
                                    placeholder="Digite o Cnpj"></input>

                                <label>RAZAO SOCIAL*: </label>
                                <input 
                                    value={Razaosocialdados}
                                    type="text"
                                    onChange={e => setRazaosocialdados(e.target.value.toUpperCase())}
                                    class="form-control"
                                    id="razaosocial"
                                    placeholder="Digite a Razão Social"></input>

                                <label>UF*: </label>
                                <input type="text"
                                    value={Ufdados}
                                    onChange={e => setUfdados(e.target.value.toUpperCase())}
                                    class="form-control"
                                    id="uf"
                                    placeholder="UF"></input>

                                <label>AMBIENTE DA COLETA*: </label>
                                <select style={{ height: '35px' }}
                                    id="ambienteenum"
                                    value={Ambienteenumdados}
                                    class="form-control"
                                    onChange={e => setAmbienteenumdados(e.target.value)}>
                                    <option>Selecione*</option>
                                    <option value="PRODUCAO">PRODUCAO</option>
                                    <option value="HOMOLOGACAO">HOMOLOGACAO</option>
                                </select>

                                <label >CAMINHO DO CERTIFICADO*: </label>

                                <input type="text"
                                    onChange={e => setCertificadodados(e.target.value)}
                                    class="form-control"
                                    value={Certificadodados}
                                    id="certificado"
                                    placeholder="Caminho do Certificado "></input>

                                <label>NSU*: </label>
                                <input type="text"
                                    onChange={e => setNsudados(e.target.value)}
                                    class="form-control"
                                    id="nsu"
                                    value={Nsudados}
                                    placeholder="Digite o Nsu "></input>

                                <br></br>
                                <label>Senha do Certificado*: </label>
                                <input type="password"
                                    onChange={e => setSenhacertificadodados(e.target.value)}
                                    class="form-control"
                                    id="senhacertificado"
                                    value={Senhacertificadodados}
                                    placeholder="Digite a senha do certificado !"></input>

                                <br></br>
                                <div>
                                    <div class="col-sm-2 col-md-6 cantodireito">
                                        <button type="button" class="btn btn-block btn-danger btn-lg"
                                            onClick={voltar}>
                                            <i class="fa fa-chevron-left"></i> VOLTAR</button>
                                    </div>
                                    <div class="col-sm-2 col-md-6 cantodireito2">
                                        <button type="button" class="btn btn-block btn-primary btn-lg"
                                            onClick={handleOpen}>
                                            <i class="fa fa-save"></i> SALVAR</button>
                                    </div>
                                    <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="parent-modal-title"
                                        aria-describedby="parent-modal-description"
                                    >
                                        <Box sx={{ ...style, width: 400 }}>
                                            <h2 id="parent-modal-title">Você Realmente Deseja Salvar esses Certificado?</h2>
                                            <p id="parent-modal-description">
                                                <button type="button" class="btn btn-block btn-danger btn-lg" onClick={handleClose}>Não</button>
                                                <button type="button" class="btn btn-block btn-primary btn-lg" onClick={() => inserirCertificado()}>Sim</button>
                                            </p>

                                        </Box>
                                    </Modal>


                                </div>
                            </div>
                        </div>
                        <br></br>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}