import React, { Component } from 'react'

import ContentHeader from '../../common/template/contentHeader';

import Header from '../../common/template/header';
import SideBar from '../../common/template/sideBar';
import Footer from '../../common/template/footer';
import './roaddefault.css';
import './roaddefault.scss';
import { Divider } from '@mui/material';


import VisualizarCuritibaIMG from '../../common/template/img/curitibana.png'
import VisualizarSefazIMG from '../../common/template/img/sefaz.png'
import VisualizarChamadoIMG from '../../common/template/img/sistemadechamado.png'
import VisualizarPowerBIIMG from '../../common/template/img/powerbi.png'
import VisualizarNewsIMG from '../../common/template/img/news.png'
import VisualizarListsMG from '../../common/template/img/lists.png'

export default function RoadMap2022(props) {

    const usuario = localStorage.getItem('nomecomplemento');
    const usuarioLogado = JSON.parse(usuario);

    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <ContentHeader title={`Portal Cloud Seja bem-vindo ` + usuarioLogado.nomecomplemento} />
                <br></br>

                <div class="box-body">
                    <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger"><div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" class="ui-panel-content ui-widget-content">
                        <div class="box-header with-border">
                            <div class="form-horizontal">
                                <div class="card">
                                    <h2>ROADMAP - 2022</h2>
                                    <div class="timeline">
                                        <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                                            <div class="timeline__event__icon ">
                                                <i class="lni-cake">
                                                    <img src={VisualizarCuritibaIMG}

                                                        class="botaoPadrao" />
                                                </i>

                                            </div>
                                            <div class="timeline__event__date">
                                                Abril
                                            </div>
                                            <div class="timeline__event__content ">
                                                <div class="timeline__event__title">
                                                    Data Entrega 14/04/2022
                                                </div>
                                                <div class="timeline__event__description">
                                                    <p>Curitiba (NFs-e)!</p>
                                                </div>

                                            </div>




                                        </div>
                                        <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                                            <div class="timeline__event__icon">
                                                <i class="lni-cake">
                                                    <img src={VisualizarSefazIMG}

                                                        class="botaoPadrao" />
                                                </i>

                                            </div>
                                            <div class="timeline__event__date">
                                                Agosto
                                            </div>

                                            <div class="timeline__event__content">
                                                <div class="timeline__event__title">
                                                    Data Prevista 05/09/2022
                                                </div>
                                                <div class="timeline__event__description">
                                                    <p>Sefaz</p>
                                                </div>

                                            </div>

                                        </div>
                                        <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                                            <div class="timeline__event__icon">
                                                <i class="lni-cake">
                                                    <img src={VisualizarChamadoIMG}

                                                        class="botaoPadrao" />
                                                </i>

                                            </div>


                                            <div class="timeline__event__content">
                                                <div class="timeline__event__title">
                                                    Data Prevista 05/09/2022
                                                </div>
                                                <div class="timeline__event__description">
                                                    <p>Sistema de Chamado</p>
                                                </div>

                                            </div>

                                        </div>
                                        <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                                            <div class="timeline__event__icon">
                                                <i class="lni-cake">
                                                    <img src={VisualizarPowerBIIMG}

                                                        class="botaoPadrao" />
                                                </i>
                                            </div>


                                            <div class="timeline__event__content">
                                                <div class="timeline__event__title">
                                                    Data Prevista 05/09/2022
                                                </div>
                                                <div class="timeline__event__description">
                                                    <p>PowerBi View Portal Cloud</p>
                                                </div>

                                            </div>
                                        </div>




                                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                                            <div class="timeline__event__icon">

                                                <i class="lni-cake">
                                                    <img src={VisualizarNewsIMG}

                                                        class="botaoPadrao" />
                                                </i>

                                            </div>
                                            <div class="timeline__event__date">
                                                Setembro/Outubro
                                            </div>
                                            <div class="timeline__event__content">
                                                <div class="timeline__event__title">
                                                    Pacote de Atualização Melhorias PORTAL CLOUD GRUPO JCR - v1.01
                                                </div>
                                                <div class="timeline__event__description">

                                                </div>
                                                <br></br>
                                                <div class="timeline__event__title">
                                                    Pacote de Atualização Melhorias PORTAL CLOUD GRUPO JCR - v1.02
                                                </div>
                                                <div class="timeline__event__description">
                                                    Pacote de Atualização Melhorias PORTAL CLOUD GRUPO JCR - v1.03
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                    <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">




                                        <div class="timeline__event__content">
                                            <div class="timeline__event__title">

                                                Nfs e Nfe
                                            </div>
                                            <br></br>
                                            <div class="timeline__event__description">
                                                <p>  - Correção do Layout Prefeitura de Curitiba (Atualização)</p>
                                                <p>  - Criação do Layout da Prefeitura de Pontal do Paraná</p>

                                                <p> -  API Externa (Simples Nacional, Sintegra)</p>
                                            </div>

                                        </div>
                                        <div class="timeline__event__date">

                                            Novembro / Dezembro
                                        </div>
                                        <div class="timeline__event__icon">

                                            <i class="lni-cake">
                                                <img src={VisualizarNewsIMG}

                                                    class="botaoPadrao" />
                                            </i>

                                        </div>

                                    </div>



                                </div>
                            </div>
                        </div>
                       
                    </div>
                    </div>
                </div>

            </div>
            <Footer />
        </div >
    )
}