import React from 'react';

export default props => (
    <section className='content-header' style={{ paddingLeft: '50px'}}>
      <h1>
        {props.title}
        <br />
        <small>{props.small}</small>
      </h1>
    </section>
  );
  