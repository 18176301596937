
import React from 'react';
import Footer from '../../common/template/footer';
import Header from '../../common/template/header';
import SideBar from '../../common/template/sideBar';


import { useEffect } from 'react';
import axiosInstance from '../../config/api';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import './nfselayoutscroll.css';


import { NfeDocumentoInterface } from './nfedocumentoentradainterface.ts';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import { Divider } from '@mui/material';




export default function NfeDocumentoEntradaCadastri(props) {


    const { chave } = useParams();
    const [value, setValue] = React.useState(0);
    const locale = 'pt-br'
    //Pametros de Entrada

    const [cnf, setCnf] = useState([]);
    const [numero, setNumero] = useState([]);
    const [modelo, setModelo] = useState([]);
    const [serie, setSerie] = useState([]);
    const [dtaemissao, setDtaemissao] = useState([]);
    const [datasaidaemissao, setDatasaidaemissao] = useState([]);
    const [valortotalnf, setvalortotalnf] = useState([]);
    const [natoperacao, setNatoperacao] = useState([]);
    const [nomefantasiaemitente, setnomefantasiaemitente] = useState([]);
    const [nomeemitente, setNomeemitente] = useState([]);
    const [cnpjemitente, setCnpjemitente] = useState([]);
    const [enderecoemitente, setEnderecoemitente] = useState([]);
    const [numeroemitente, setNumeroemitente] = useState([]);
    const [complementoemitente, setComplementoemitente] = useState([]);
    const [bairroemitente, setBairroemitente] = useState([]);
    const [codigomucipioemitente, setCodigomucipioemitente] = useState([]);
    const [nomedomucipioemitente, setNomedomucipioemitente] = useState([]);
    const [ufemitente, setUfemitente] = useState([]);
    const [cepemitente, setCepemitente] = useState([]);
    const [paisemitente, setPaisemitente] = useState([]);
    const [codigopaisemitente, setcodigopaisemitente] = useState([]);
    const [nomepaisemitente, setnomepaisemitente] = useState([]);
    const [telefoneemitente, settelefoneemitente] = useState([]);
    const [ieemitente, setieemitente] = useState([]);
    const [iestemitente, setiestemitente] = useState([]);
    const [imemitente, setimemitente] = useState([]);
    const [cnaeemitente, setcnaeemitente] = useState([]);
    const [crtemitente, setcrtemitente] = useState([]);
    const [cnpjdestinatario, setcnpjdestinatario] = useState([]);
    const [nomefantasiadestinatario, setnomefantasiadestinatario] = useState([]);
    const [enderecodestinatario, setenderecodestinatario] = useState([]);
    const [numerodestinatario, setnumerodestinatario] = useState([]);
    const [bairrodestinatario, setbairrodestinatario] = useState([]);
    const [codigomucipiodestinatario, setcodigomucipiodestinatario] = useState([]);
    const [nomemunicipiodestinatario, setnomemunicipiodestinatario] = useState([]);
    const [ufdestinatario, setufdestinatario] = useState([]);
    const [cepdestinatario, setcepdestinatario] = useState([]);
    const [codigopaisdestinatario, setcodigopaisdestinatario] = useState([]);
    const [paisdestinatario, setpaisdestinatario] = useState([]);
    const [fonedestinatario, setfonedestinatario] = useState([]);
    const [emaildestinatario, setemaildestinatario] = useState([]);
    const [basevaloricms, setbasevaloricms] = useState([]);
    const [valoricms, setvaloricms] = useState([]);
    const [valoricmsdesconto, setvaloricmsdesconto] = useState([]);
    const [valortotalfcp, setvalortotalfcp] = useState([]);
    const [valorbcst, setvalorbcst] = useState([]);
    const [basecalicmsst, setbasecalicmsst] = useState([]);
    const [valortotalicmssubtrib, setvalortotalicmssubtrib] = useState([]);
    const [valorfcpst, setvalorfcpst] = useState([]);
    const [valorfcpstretido, setvalorfcpstretido] = useState([]);
    const [valorimssubt, setvalorimssubt] = useState([]);
    const [vimpimportacao, setvimpimportacao] = useState([]);
    const [vicmsufremet, setvicmsufremet] = useState([]);
    const [vfcpufdest, setvfcpufdest] = useState([]);
    const [valorpis, setvalorpis] = useState([]);
    const [valortotalprodutos, setvalortotalprodutos] = useState([]);
    const [valorfrete, setvalorfrete] = useState([]);
    const [valorseguro, setvalorseguro] = useState([]);
    const [desconto, setdesconto] = useState([]);
    const [valorii, setvalorii] = useState([]);
    const [outrasdespesas, setoutrasdespesas] = useState([]);
    const [valortotalipi, setvalortotalipi] = useState([]);
    const [valortotalipidevol, setvalortotalipidevol] = useState([]);
    const [vicmsufdest, setvicmsufdest] = useState([]);
    const [vtotaltrib, setvtotaltrib] = useState([]);
    const [valorcofins, setvalorcofins] = useState([]);
    const [valortotaldanota, setvalortotaldanota] = useState([]);
    const [cnpjtransportadora, setcnpjtransportadora] = useState([]);
    const [nomefantasiatransportadora, setnomefantasiatransportadora] = useState([]);
    const [inscricaoestadualtransportadora, setinscricaoestadualtransportadora] = useState([]);
    const [enderecotransportadora, setenderecotransportadora] = useState([]);
    const [municipiotransportadora, setmunicipiotransportadora] = useState([]);
    const [uftransportadora, setuftransportadora] = useState([]);
    const [fatnum, setfatnum] = useState([]);
    const [fatvalororiginal, setfatvalororiginal] = useState([]);
    const [fatvalorliquido, setfatvalorliquido] = useState([]);
    const [duplicadanum, setduplicadanum] = useState([]);
    const [faturaduplicadavencimento, setfaturaduplicadavencimento] = useState([]);
    const [faturaduplicadavalor, setfaturaduplicadavalor] = useState([]);
    const [informacoescomplementares, setinformacoescomplementares] = useState([]);


    useEffect(() => {
        async function loadingnfe() {
            const response = await axiosInstance.get('/api/v1/notaentradaprodutosefaz/' + chave)
                .then(resp => {

                    setNumero(resp.data.numeronfe);
                    setModelo(resp.data.modcod);
                    setSerie(resp.data.serie);
                    setDtaemissao(resp.data.dataemissao);
                    setDatasaidaemissao(resp.data.datasaient);
                    setvalortotalnf(resp.data.valor);
                    setPaisemitente(resp.data.paisemitente);
                    setNomeemitente(resp.data.nomeemitente);
                    setCnpjemitente(resp.data.cnpjemitente);
                    setNumeroemitente(resp.data.numeroemitente + ',');
                    setBairroemitente(resp.data.bairroemitente);
                    setCepemitente(resp.data.cepemitente);
                    setNomedomucipioemitente(resp.data.nomedomucipioemitente);
                    setEnderecoemitente(resp.data.enderecoemitente);
                    setComplementoemitente(resp.data.complementoemitente);
                    setUfemitente(resp.data.ufemitente);
                    setcodigopaisemitente(resp.data.codigopaisemitente + " - ");
                    setCodigomucipioemitente(resp.data.codigomucipioemitente + " - ");
                    setnomepaisemitente(resp.data.nomepaisemitente);
                    setnomefantasiaemitente(resp.data.nomefantasiaemitente);
                    settelefoneemitente(resp.data.telefoneemitente);
                    setieemitente(resp.data.ieemitente);
                    setiestemitente(resp.data.iestemitente);
                    setimemitente(resp.data.imemitente);
                    setcnaeemitente(resp.data.cnaeemitente);
                    setcrtemitente(resp.data.crtemitente);
                    setcnpjdestinatario(resp.data.cnpjdestinatario);
                    setnomefantasiadestinatario(resp.data.nomefantasiadestinatario);
                    setenderecodestinatario(resp.data.enderecodestinatario);
                    setnumerodestinatario(resp.data.numerodestinatario);
                    setbairrodestinatario(resp.data.bairrodestinatario);
                    setcodigomucipiodestinatario(resp.data.codigomucipiodestinatario);
                    setnomemunicipiodestinatario(resp.data.nomemunicipiodestinatario);
                    setufdestinatario(resp.data.ufdestinatario);
                    setcepdestinatario(resp.data.cepdestinatario + " - ");
                    setcodigopaisdestinatario(resp.data.codigopaisdestinatario);
                    setpaisdestinatario(resp.data.paisdestinatario);
                    setfonedestinatario(resp.data.fonedestinatari);
                    setemaildestinatario(resp.data.emaildestinatario);
                    setbasevaloricms(resp.data.basevaloricms);
                    setvaloricms(resp.data.valoricms);
                    setvaloricmsdesconto(resp.data.valoricmsdesconto);
                    setvalortotalfcp(resp.data.valortotalfcp);
                    setvalorbcst(resp.data.valorbcst);
                    setbasecalicmsst(resp.data.basecalicmsst);
                    setvalortotalicmssubtrib(resp.data.valortotalicmssubtrib);
                    setvalorfcpst(resp.data.valorfcpst);
                    setvalorfcpstretido(resp.data.valorfcpstretido);
                    setvalorimssubt(resp.data.valorimssubt);
                    setvimpimportacao(resp.data.vimpimportacao);
                    setvicmsufremet(resp.data.vicmsufremet);
                    setvfcpufdest(resp.data.vfcpufdest);
                    setvalorpis(resp.data.valorpis);
                    setvalortotalprodutos(resp.data.valortotalprodutos);
                    setvalorfrete(resp.data.valorfrete);
                    setvalorseguro(resp.data.valorseguro);
                    setdesconto(resp.data.desconto);
                    setvalorii(resp.data.valorii);
                    setoutrasdespesas(resp.data.outrasdespesas);
                    setvalortotalipi(resp.data.valortotalipi);
                    setvalortotalipidevol(resp.data.valortotalipidevol);
                    setvicmsufdest(resp.data.vicmsufdest);
                    setvtotaltrib(resp.data.vtotaltrib);
                    setvalorcofins(resp.data.valorcofins);
                    setvalortotaldanota(resp.data.valortotaldanota);
                    setcnpjtransportadora(resp.data.cnpjtransportadora);
                    setnomefantasiatransportadora(resp.data.nomefantasiatransportadora);
                    setinscricaoestadualtransportadora(resp.data.inscricaoestadualtransportadora);
                    setenderecotransportadora(resp.data.enderecotransportadora);
                    setmunicipiotransportadora(resp.data.municipiotransportadora);
                    setuftransportadora(resp.data.uftransportadora);
                    setfatnum(resp.data.fatnum);
                    setfatvalororiginal(resp.data.fatvalororigina);
                    setfatvalorliquido(resp.data.fatvalorliquido);
                    setduplicadanum(resp.data.duplicadanum);
                    setfaturaduplicadavencimento(resp.data.faturaduplicadavencimento);
                    setfaturaduplicadavalor(resp.data.faturaduplicadavalor);
                    setinformacoescomplementares(resp.data.informacoescomplementares);

                }).catch(error => {
                    //setTimeout(() => { window.location.href = '/'; }, 2000);
                });
        }
        loadingnfe();
    }, []);
    async function voltar() {
        window.location.href = '/documentoentradanfe';
    }

    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <div>
                    <div class="card-header">

                        <section class="content-header">
                            <h1><i class="fa fa-file-archive-o fa-1x cor-padrao"></i> Documento de Entrada Nfe
                                <small></small>
                            </h1>
                            <ol class="breadcrumb">
                                <li><i class="fa fa-home"></i> Portal JCR</li>
                                <li><i class="fa fa-book"></i> Documento de Entrada</li>
                                <li class="active"><i class="fa fa-file-archive-o"></i> Nfe</li>
                                <li class="active"><i class="fa fa-file-archive"></i> Documento de Entrada Nfe</li>
                            </ol>
                        </section>
                        <br></br>
                        <div class="container-fluid ">
                            <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger "><div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" class="ui-panel-content ui-widget-content">

                                <div class="box-header with-border ">
                                    <div class="form-horizontal">


                                        <div class="centralizadomeio">
                                            <div class="box-footer">
                                                <button type="button" class="btn btn-danger col-xs-12 col-sm-6 "
                                                    onClick={() => voltar()}
                                                >
                                                    <i class="fa fa-chevron-left"></i> VOLTAR</button>

                                            </div>


                                            <br></br>
                                        </div >
                                    </div >
                                </div >
                                <div class="box-header with-border ">
                                    <div class="form-horizontal scrollNfeProd">
                                        <Tabs
                                            defaultActiveKey="home"
                                            transition={false}
                                            id="noanim-tab-example"
                                            className="mb-3"
                                        >
                                            <Tab eventKey="home" title="Nfe">
                                                <h4 style={{ marginLeft: '45%', fontWeight: 'bold' }}>Dados da NF-e</h4>


                                                <Table className=" fundocardform table table-striped table-bordered table-resizable" data-toggle="tooltip" stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: 'bold' }}>Chave de Acesso</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }}>Número</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }}>Modelo do XML</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell>{chave}</TableCell>
                                                        <TableCell>{numero}</TableCell>
                                                        <TableCell>4.00</TableCell>
                                                    </TableBody>
                                                </Table>

                                                <Table className=" fundocardform table table-striped table-bordered table-resizable" data-toggle="tooltip" stickyHeader aria-label="sticky table">

                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Modelo</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Série</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Número</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Data Emissão</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Data Saída/Entrada</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Valor Total da Nota Fiscal</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell>{modelo}</TableCell>
                                                        <TableCell>{serie}</TableCell>
                                                        <TableCell>{numero}</TableCell>
                                                        <TableCell>{dtaemissao.toLocaleString('pt-BR')}</TableCell>
                                                        <TableCell>{datasaidaemissao}</TableCell>
                                                        <TableCell>{valortotalnf.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                                    </TableBody>

                                                </Table>
                                                <br></br>
                                                <Divider />
                                                <br></br>
                                                <h5 style={{ fontWeight: 'bold' }} >Emitente</h5>
                                                <Table className="fundocardform  table table-striped table-bordered table-resizable" data-toggle="tooltip" stickyHeader aria-label="sticky table">

                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>CNPJ</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Nome / Razão Social</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Inscrição Estadual</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>UF</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell>{cnpjemitente}</TableCell>
                                                        <TableCell>{nomeemitente}</TableCell>
                                                        <TableCell>{imemitente}</TableCell>
                                                        <TableCell>{ufemitente}</TableCell>

                                                    </TableBody>
                                                </Table>
                                                <br></br>
                                                <Divider />
                                                <br></br>
                                                <h5 style={{ fontWeight: 'bold' }} >Destinatário</h5>
                                                <Table className="table fundocardform  table-striped table-bordered table-resizable" data-toggle="tooltip" stickyHeader aria-label="sticky table">

                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>CNPJ</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Nome / Razão Social</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Inscrição Estadual</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>UF</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody className="fundocardform">
                                                        <TableCell>{cnpjdestinatario}</TableCell>
                                                        <TableCell>{nomefantasiadestinatario}</TableCell>
                                                        <TableCell>4.00</TableCell>
                                                        <TableCell>{ufdestinatario}</TableCell>
                                                    </TableBody>

                                                </Table>
                                                <br></br>
                                                <Divider />
                                                <br></br>
                                                <h5 style={{ fontWeight: 'bold' }} >Emissão</h5>
                                                <Table className="table table-striped table-bordered table-resizable" data-toggle="tooltip" stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Processo</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Versão do Processo</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Tipo de Emissão</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Finalidade</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody className="fundocardform">
                                                        <TableCell>0 - com aplicativo do Contribuinte</TableCell>
                                                        <TableCell>12.1.027 | 3.0</TableCell>
                                                        <TableCell>1 - Normal</TableCell>
                                                        <TableCell>1 - Normal</TableCell>
                                                    </TableBody>
                                                    <br></br>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Natureza da Operação</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Indicador de Intermediador/Marketplace</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Tipo da Operação</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Digest Value da NF-e</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody className="fundocardform">
                                                        <TableCell>VENDA DE MERCADORIA</TableCell>
                                                        <TableCell>1 - Saída</TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableBody>
                                                </Table>
                                            </Tab>
                                            <Tab eventKey="profile" title="Emitente">
                                                <h4 style={{ marginLeft: '45%', fontWeight: 'bold' }}>Dados da NF-e</h4>

                                                <h5 style={{ fontWeight: 'bold', marginLeft: '1%' }} >Emitente</h5>
                                                <Table className="fundocardform  table table-striped table-bordered table-resizable" data-toggle="tooltip" stickyHeader aria-label="sticky table">

                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Nome / Razão Social</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Nome Fantasia</TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell>{nomeemitente}</TableCell>
                                                        <TableCell>{nomefantasiaemitente}</TableCell>
                                                    </TableBody>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>CNPJ</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Endereço</TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell>{cnpjemitente}</TableCell>
                                                        <TableCell>{enderecoemitente === null ? " " : enderecoemitente},
                                                            {numeroemitente === null ? " " : numeroemitente}
                                                            {complementoemitente === null ? " " : complementoemitente}
                                                        </TableCell>
                                                    </TableBody>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Bairro / Distrito</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>CEP</TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell>{bairroemitente}</TableCell>
                                                        <TableCell>{cepemitente}</TableCell>
                                                    </TableBody>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Município</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Telefone</TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell>{codigomucipioemitente}{nomedomucipioemitente}</TableCell>
                                                        <TableCell>{telefoneemitente}</TableCell>
                                                    </TableBody>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>UF</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>País</TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell>{ufemitente}</TableCell>
                                                        <TableCell>{cepdestinatario}{paisemitente}</TableCell>
                                                    </TableBody>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Inscrição Estadual</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Inscrição Estadual do Substituto Tributário</TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell>{ieemitente}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableBody>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Inscrição Municipal</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Município da Ocorrência do Fato Gerador do ICMS</TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell>{imemitente}</TableCell>
                                                        <TableCell>{codigomucipioemitente}</TableCell>
                                                    </TableBody>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>CNAE Fiscal</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Código de Regime Tributário</TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell>{cnaeemitente}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableBody>
                                                </Table>

                                            </Tab>
                                            <Tab eventKey="destinatario" title="Destinatário">
                                                <h4 style={{ marginLeft: '45%', fontWeight: 'bold' }}>Dados do Destinatário</h4>
                                                <label>Nome / Razão Social</label>
                                                <input type="text"
                                                    class="form-control "
                                                    value={nomefantasiadestinatario}
                                                    id="destino"
                                                    disabled="true"
                                                ></input>
                                                <br></br>
                                                <Table className="fundocardform colorfundocard2 table table-striped table-bordered table-resizable" data-toggle="tooltip" stickyHeader aria-label="sticky table">

                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>CNPJ</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Endereço</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell>{cnpjdestinatario}</TableCell>
                                                        <TableCell>{enderecodestinatario}</TableCell>
                                                    </TableBody>

                                                </Table>
                                                <Table className="fundocardform colorfundocard2 table table-striped table-bordered table-resizable" data-toggle="tooltip" stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Bairro / Distrito </TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>CEP </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell>{bairrodestinatario}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableBody>
                                                </Table>

                                                <Table className="fundocardform colorfundocard2 table table-striped table-bordered table-resizable" data-toggle="tooltip" stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Município </TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Telefone </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell>{codigomucipiodestinatario} {nomemunicipiodestinatario}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableBody>
                                                </Table>

                                                <Table className="fundocardform colorfundocard2 table table-striped table-bordered table-resizable" data-toggle="tooltip" stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>UF </TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>País </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell>{ufdestinatario}</TableCell>
                                                        <TableCell>{codigopaisemitente}{paisemitente} </TableCell>
                                                    </TableBody>
                                                </Table>

                                                <Table className="fundocardform colorfundocard2 table table-striped table-bordered table-resizable" data-toggle="tooltip" stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Indicador IE </TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Inscrição Estadual </TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Inscrição SUFRAMA </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableBody>
                                                </Table>
                                                <Table className="fundocardform colorfundocard2 table table-striped table-bordered table-resizable" data-toggle="tooltip" stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>IM</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>E-mail</TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell></TableCell>
                                                        <TableCell>{emaildestinatario}</TableCell>
                                                    </TableBody>
                                                </Table>
                                            </Tab>
                                            <Tab eventKey="totais" title="Totais">
                                                <h4 style={{ marginLeft: '45%' }}>Totais</h4>
                                                <Table className="fundocardform colorfundocard2 table table-striped table-bordered table-resizable" data-toggle="tooltip" stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Base de Cálculo ICMS</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Valor do ICMS</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Valor do ICMS Desonerado</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Valor Total do FCP</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell>{basevaloricms}</TableCell>
                                                        <TableCell>{valoricms}</TableCell>
                                                        <TableCell>{valoricmsdesconto}</TableCell>
                                                        <TableCell>{valortotalfcp}</TableCell>
                                                    </TableBody>
                                                    <br></br>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Valor Total ICMS FCP</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Valor Total ICMS Interestadual UF Destino</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Valor Total ICMS Interestadual UF Rem.</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Base de Cálculo ICMS ST</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell>{valorbcst}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableBody>
                                                    <br></br>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Valor ICMS Substituição</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Valor Total do FCP retido por ST</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Valor Total do FCP retido anteriormente por ST</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Valor Total dos Produtos</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell></TableCell>
                                                        <TableCell>{valorfcpst}</TableCell>
                                                        <TableCell>{valorfcpstretido}</TableCell>
                                                        <TableCell>{valortotalprodutos}</TableCell>
                                                    </TableBody>
                                                    <br></br>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Valor do Frete</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Valor do Seguro</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Valor Total dos Descontos</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Valor Total do II</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell>{valorfrete}</TableCell>
                                                        <TableCell>{valorseguro}</TableCell>
                                                        <TableCell>{desconto}</TableCell>
                                                        <TableCell>{valorii}</TableCell>
                                                    </TableBody>
                                                    <br></br>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Valor Total do IPI</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Valor Total do IPI Devolvido</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Valor do PIS</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Valor da COFINS</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell>{valortotalipi}</TableCell>
                                                        <TableCell>{valortotalipidevol}</TableCell>
                                                        <TableCell>{valorpis}</TableCell>
                                                        <TableCell>{valorcofins}</TableCell>
                                                    </TableBody>
                                                    <br></br>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Outras Despesas Acessórias</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Valor Total da NFe</TableCell>
                                                            <TableCell sx={{ fontSize: '11px', fontWeight: 'bold' }}>Valor Aproximado dos Tributos</TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell>{outrasdespesas}</TableCell>
                                                        <TableCell>{valortotalnf}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableBody>
                                                </Table>
                                            </Tab>
                                        </Tabs>

                                    </div >
                                </div >
                            </div >
                            </div >
                        </div >

                    </div>
                </div >
            </div >
            <Footer />
        </div >
    )
}