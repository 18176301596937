import React, { useEffect, useState } from "react";
import Header from "../../../common/template/header";
import SideBar from "../../../common/template/sideBar";
import Footer from "../../../common/template/footer";
import Spinner from "../../../common/template/Spinner.js";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  TablePagination,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import VisualizarIMG from "../../../common/template/img/visualizar.png";
import LixeiraIMG from "../../../common/template/img/lixeira.png";
import WarningIcon from "../../../common/template/img/Warning.png";
import axiosInstance from "../../../config/api";
import { Box } from "@mui/system";
import "./gerenciamento.css";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { Dialog, Modal } from "@mui/material";

import Success from "../../../common/template/img/success.png";
import moment from "moment/moment.js";

export default function GerenciaProjeto(props) {
  const [loading, setloading] = useState(false);
  const [gerenciamento, setGerenciamento] = useState([]);
  const [SituacaoProjeto, setSituacaoProjeto] = useState(false);
  const [quantidadecount, setquantidadecount] = useState([]);
  const [crudSuccess, setCrudSuccess] = useState(false);
  const [pagenumber, setpageNumber] = useState("");
  const [DisabledNextButton, setDisabledNextButton] = useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const [quantidadeEmAndamento, setquantidadeEmAndamento] = useState("");
  const [SmsDelete, setSmsDelete] = useState(false);
  const [quantidadeNaoIniciada, setquantidadeNaoIniciada] = useState("");

  const [status, setStatus] = useState({});

  var netInt3 = JSON.parse(localStorage.getItem("idsetor"));
  var idusuariosetor = netInt3.idsetor;

  useEffect(() => {
    setloading(true);

    async function loadingGerenciamento() {
      try {

        const response = await axiosInstance.get(
          `/projeto/paginado/${idusuariosetor}`
        );

        console.log(response)

        if (response.data.totalElements === 0) {
          setSituacaoProjeto(true);
        } else {
          setGerenciamento(response.data.content);
          setquantidadecount(response.data.pageable.offset);
          setTotalElements(response.data.totalElements);
        }
        if (response.data.content.length < 20) {
          setDisabledNextButton(true);
        } else {
          setDisabledNextButton(false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setloading(false);
      }
    }

    loadingGerenciamento();

    async function loadingProjeto() {
      try {
        const response = await axiosInstance.get(`/projeto/gerargrafico/${idusuariosetor}`);
        const data = response.data;

        const groupedByStatus = data.reduce((acc, obj) => {
          const status = obj.status;

          if (!acc[status]) {
            acc[status] = [];
          }

          acc[status].push(obj);

          return acc;
        }, {});

        setStatus(groupedByStatus);

        const quantidadeEmAndamento =
          groupedByStatus["EMANDAMENTO"]?.length || 0;
        setquantidadeEmAndamento(quantidadeEmAndamento);

        const quantidadeNaoIniciada =
          groupedByStatus["NAOINICIADA"]?.length || 0;
        setquantidadeNaoIniciada(quantidadeNaoIniciada);
      } catch (error) {
        console.error("Erro ao carregar projeto:", error);
      }
    }

    loadingProjeto();
  },
    [quantidadeNaoIniciada], [quantidadeEmAndamento],[totalElements],
    []);

    async function loadingProjetoAuxiliar() {
      try {
        const response = await axiosInstance.get(`/projeto/gerargrafico`);
        const data = response.data;

        const groupedByStatus = data.reduce((acc, obj) => {
          const status = obj.status;

          if (!acc[status]) {
            acc[status] = [];
          }

          acc[status].push(obj);

          return acc;
        }, {});

        setStatus(groupedByStatus);

        const quantidadeEmAndamento =
          groupedByStatus["EMANDAMENTO"]?.length || 0;
        setquantidadeEmAndamento(quantidadeEmAndamento);

        const quantidadeNaoIniciada =
          groupedByStatus["NAOINICIADA"]?.length || 0;
        setquantidadeNaoIniciada(quantidadeNaoIniciada);
      } catch (error) {
        console.error("Erro ao carregar projeto:", error);
      }
    }

  async function loadingProjetoDelete() {

    try {
      loadingProjetoAuxiliar();

      const response = await axiosInstance.get(
        `/projeto/paginado/${idusuariosetor}`
      );
      if (response.data.totalElements === 0) {
        setSituacaoProjeto(true);
        setGerenciamento(response.data.content);
        setquantidadecount(response.data.pageable.offset);
        setTotalElements(response.data.totalElements);
      } else {
        setGerenciamento(response.data.content);
        setquantidadecount(response.data.pageable.offset);
        setTotalElements(response.data.totalElements);
      }
      if (response.data.content.length < 20) {
        setDisabledNextButton(true);
      } else {
        setDisabledNextButton(false);
      }
    } catch (error) {
      
    } finally {
      setloading(false);
    }

  }

  function TablePaginationActions() {
    const theme = useTheme();
    const { count, rowsPerPage } = props;

    // Adicione esta linha para calcular o número de página exibido para o usuário
    const displayPageNumber = pagenumber + 1;

    async function handleBackButtonClick() {
      try {
        const newPage = pagenumber - 1;
        const response = await axiosInstance.get(
          `/projeto/paginado/${idusuariosetor}?page=${newPage}`
        );
        setGerenciamento(response.data.content);
        setquantidadecount(response.data.pageable.offset);

        if (response.data.content.length < 20) {
          setDisabledNextButton(true);
        } else {
          setDisabledNextButton(false);
        }
      } catch (error) {
        // Tratamento de erro
      }
    }
    async function handleNextButtonClick() {
      try {
        const newPage = pagenumber + 1;

        const response = await axiosInstance.get(
          `/projeto/paginado/${idusuariosetor}?page=${newPage}`
        );
        setGerenciamento(response.data.content);
        setquantidadecount(response.data.pageable.offset);

        if (response.data.content.length < 20) {
          setDisabledNextButton(true);
        } else {
          setDisabledNextButton(false);
        }
      } catch (error) {
        // Tratamento de erro
      }
    }

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={pagenumber === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton>
          <IconButton>{displayPageNumber}</IconButton>
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={
            pagenumber >= Math.ceil(count / rowsPerPage) ||
            DisabledNextButton === true
          }
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
      </Box>
    );
  }

  async function visualizargerenciamentoprojeto(id) {
    window.location.href = "/gerencimanetoprojeto/" + id;
  }

  async function deletaritemgerenciamento(id) {
    setloading(true);
    setCrudSuccess(false);
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const response2 = await axiosInstance.delete("projeto/" + id);

      setloading(false);



      loadingProjetoDelete();


    } catch (error) {
      setloading(false);

      console.log(error)

      if (error.response && (error.response.status === 500 || error.response.status === '500')) {


        setSmsDelete(true);
      }
    }
  }

  const handleClose = () => {
    setCrudSuccess(false);

  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "52%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#ffffff",
    border: "2px solid #ffffff",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleCloseAlerta = () => {
    setSmsDelete(false)
  }

  return (
    <div className="wrapper">
      <Header />
      <SideBar />
      <div className="content-wrapper">
        <div>
          <div class="card-header">
            <section class="content-header">
              <h1>
                <i class="fa fa-plus-square fa-1x cor-padrao"></i> Gerenciamento
                de Projeto
                <small></small>
              </h1>
              <ol class="breadcrumb">
                <li>
                  <i class="fa fa-home"></i> Portal JCR
                </li>

                <li class="active">
                  <i class="fa fa-reorder"></i> Gerenciamento de Projeto
                </li>
              </ol>
            </section>
          </div>
        </div>

        <div class="box-body">
          <div>
            <Modal
              open={crudSuccess}
              onClose={handleClose}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box sx={{ ...style, width: 500 }}>
                <h2>
                  <img src={Success} width="30" height="30"></img> Projeto
                  Cadastrado com Sucesso !
                </h2>
              </Box>
            </Modal>
          </div>
          <div>
            {loading ? (
              <Dialog
                open={true}
                aria-describedby="alert-dialog-slide-description"
              >
                <div className="pos-center">
                  <Spinner />
                </div>
              </Dialog>
            ) : (
              <div className="content">
                <div class="row">
                  <div class="col-lg-6 col-xs-6">
                    <div class="small-box bg-red">
                      <div class="inner">
                        <div class="inner">
                          <div>
                            <h3>{quantidadeNaoIniciada}</h3>
                            <p class="h3default">NAO INICIADA</p>
                          </div>
                        </div>
                      </div>
                      <div class="icon">
                        <i class="fa fa-hand"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xs-6">
                    <div class="small-box bg-yellow">
                      <div class="inner">
                        <div class="inner">
                          <div>
                            <h3>{quantidadeEmAndamento}</h3>
                            <p class="h3default">EM ANDAMENTO</p>
                          </div>
                        </div>
                      </div>
                      <div class="icon">
                        <i class="fa fa-hand-o-up"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <br></br>
                <div className="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger" style={{ textAlign: "center", padding: "30px" }}>
                  {SituacaoProjeto === true ? (
                    <div>
                      <h4>
                        Nenhum Projeto Registrado!
                      </h4>
                    </div>
                  ) : (
                    <div className="">
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{ fontWeight: "bold" }}
                              align="center"
                            >
                              Deletar
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "bold" }}
                              align="center"
                            >
                              Editar
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "bold" }}
                              align="center"
                            >
                              Protocolo
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "bold" }}
                              align="center"
                            >
                              Nome do Projeto
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "bold" }}
                              align="center"
                            >
                              Data Inicial
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "bold" }}
                              align="center"
                            >
                              Data Previsão do Projeto
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "bold" }}
                              align="center"
                            >
                              Data Final
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {gerenciamento.map((row) => (
                            <TableRow key={0} sx={{ border: 100, width: 100 }}>
                              <TableCell align="center">
                                <img
                                  src={LixeiraIMG}
                                  onClick={() =>
                                    deletaritemgerenciamento(row.id)
                                  }
                                  width="25"
                                  height="25"
                                  style={{ cursor: "pointer" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <img
                                  src={VisualizarIMG}
                                  onClick={() =>
                                    visualizargerenciamentoprojeto(row.id)
                                  }
                                  width="20"
                                  height="20"
                                  style={{ cursor: "pointer" }}
                                />
                              </TableCell>
                              <TableCell align="center">{row.id}</TableCell>
                              <TableCell align="center">
                                {row.nomeprojeto}
                              </TableCell>
                              <TableCell align="center">
                                {moment(row.inicioprojeto).format('DD/MM/YYYY HH:mm')}
                              </TableCell>
                              <TableCell align="center">
                                {moment(row.previsaodoprojeto).format('DD/MM/YYYY HH:mm')}
                              </TableCell>
                              <TableCell align="center">
                                <div
                                  className={`circle-red ${row.fimdoprojeto == null
                                    ? "not-finished"
                                    : ""
                                    }`}
                                >
                                  {row.fimdoprojeto == null
                                    ? "Ainda não finalizada"
                                    : moment(row.fimdoprojeto).format('DD/MM/YYYY HH:mm')}
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                  <br></br>
                  <div className="">
                    <div className="cardmov">
                      <TablePagination
                        colSpan={3}
                        component="div"
                        rowsPerPageOptions={[20]}
                        count={quantidadecount}
                        rowsPerPage={gerenciamento.length}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={({ from, to, count }) => (
                          <>
                            <p>
                              <strong>
                                Total de Gerenciamento: {totalElements}
                              </strong>
                            </p>
                            <div class="custom-label">
                              <p>
                                <strong>
                                  Total de registros por página: 20
                                </strong>
                              </p>
                            </div>
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>

              </div>
            )}
          </div>
        </div>
        <Modal
          open={SmsDelete}
          onClose={handleCloseAlerta}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, width: 500 }}>
            <h2>
              <img
                src={WarningIcon}
                width="30"
                height="30"
              ></img>{" "}
              Existe projeto em andamento, altere os status para não iniciada para poder deletar, ou exclua item do projeto e subitem do projeto
            </h2>
          </Box>
        </Modal>
      </div>
      <Footer />
    </div>
  );
}
